/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: string;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: string;
  Upload: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: string;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
};

/** Encapsulates all queries of gsy-web */
export type Queries = {
  __typename?: 'Queries';
  /** Query for results that represent accumulated values. */
  scmAccumulatedResults?: Maybe<AccumulatedSimulationResultsData>;
  /** Query for reporting SCM KPI results for a specific time period. */
  scmAccumulatedKpiDifferences?: Maybe<Scalars['JSONString']>;
  /** Query for reporting SCM bills results for a specific time period. */
  scmAccumulatedBillsDifferences?: Maybe<Scalars['JSONString']>;
  /** Query for reporting SCM bills results for a specific time period and for all the community members. */
  scmAccumulatedBillsDifferencesCommunity?: Maybe<Scalars['JSONString']>;
  /** Query for reporting kpi and other stats as profile over the provided time period. */
  scmProfileResults?: Maybe<ScmProfileResultsData>;
  /** Query for returning recently reported measurements for all assets in a configuration. */
  assetReportedData?: Maybe<Scalars['JSONString']>;
  /** Query that will fetch asset measurements falling in a time range. A max time range of 1 month is obligatory. */
  listAssetMeasurements?: Maybe<Scalars['JSONString']>;
  /** List all clients of a B2B configuration. */
  listClientsB2b?: Maybe<Array<Maybe<ClientOutput>>>;
  /** Simulation Results for the B2B admin user. */
  adminSimulationResultsB2b?: Maybe<AdminSimulationResultsB2B>;
  /** Simulation Results for a B2B client. */
  clientSimulationResultsB2b?: Maybe<ClientSimulationResultsB2B>;
  /** Simulation Results for an asset of a B2B simulation. */
  assetSimulationResultsB2b?: Maybe<AssetSimulationResultsB2B>;
  /** List orders from all assets. */
  adminOrdersB2b?: Maybe<Array<Maybe<OrderResultsB2B>>>;
  /** List orders from all assets of a client. */
  clientOrdersB2b?: Maybe<Array<Maybe<OrderResultsB2B>>>;
  /** List orders from one asset. */
  assetOrdersB2b?: Maybe<Array<Maybe<OrderResultsB2B>>>;
  /** Get the price profiles for all products for an asset. */
  priceProfilesB2b?: Maybe<Array<Maybe<PriceProfilesResultsB2B>>>;
  /** Get the trading volume profiles for all products for an asset. */
  volumeProfilesB2b?: Maybe<Array<Maybe<VolumeProfilesResultsB2B>>>;
  /** List open orders from all assets anonymously. */
  allOrdersB2b?: Maybe<Array<Maybe<AnonymousOrderResultsB2B>>>;
  /** List trades from all assets anonymously. */
  allTradesB2b?: Maybe<Array<Maybe<AnonymousTradeResultsB2B>>>;
  listAggregatorRegistryUsers?: Maybe<Array<Maybe<RegistryPageSingleUserOutput>>>;
  listGsyUserRegistryAssets?: Maybe<GsyUserRegistryOutput>;
  listGridOperatorRegistryMarkets?: Maybe<Array<Maybe<MarketInformation>>>;
  listCommunityInfo?: Maybe<CommunityOutput>;
  listPersonalCommunityInfo?: Maybe<CommunityOutput>;
  /** List SCM Communities according to the configuration type. */
  listScmCommunities?: Maybe<ListConfigurationOutput>;
  /** Return all the measurement verification requests for one or all configurations. */
  listMeasurementVerificationRequests?: Maybe<Array<Maybe<MeasurementVerificationRequest>>>;
  /** Return community notifications for the logged in user. */
  listCommunityNotifications?: Maybe<ListCommunityNotificationOutput>;
  /** Get the operational community UUID for the logged in user. */
  getScmMemberOperationalUuid?: Maybe<Scalars['String']>;
  /** Get the operational community UUID for the logged in user. */
  getScmCommunityApplicableFees?: Maybe<CommunityApplicableFees>;
  /** List all managers of a community. */
  listCommunityManagers?: Maybe<Array<Maybe<Scalars['String']>>>;
  aggregatorsList?: Maybe<Array<Maybe<AggregatorOutput>>>;
  listCanaryNetworks?: Maybe<ListConfigurationOutput>;
  readTotalGlobalSimulationLocation?: Maybe<LocationTotalCount>;
  listGlobalSimulationLocation?: Maybe<Array<Maybe<LocationBoundaryOutput>>>;
  readGlobalSimulationLocation?: Maybe<SimulationLocationOutput>;
  listUserGlobalSimulationVisibility?: Maybe<Array<Maybe<SimulationLocationVisibilityOutput>>>;
  getUserGlobalLocationVisibility?: Maybe<Scalars['Boolean']>;
  listLiveEvents?: Maybe<Array<Maybe<LiveEventListOutput>>>;
  liveEventsRepresentation?: Maybe<LiveEventRepresentation>;
  areaSummary?: Maybe<Scalars['String']>;
  listCollaborations?: Maybe<ListConfigurationOutput>;
  listAvailableExternalApplication?: Maybe<Array<Maybe<ExternalConnectionApplicantOutput>>>;
  /** Return a list of all available user roles. */
  availableUserRoles?: Maybe<AvailableUserRolesOutput>;
  /** Read the role of the user with the specified email. */
  readUserRole?: Maybe<UserRoleOutput>;
  /** List all available external connections for a configuration. */
  listAvailableExternalConnection?: Maybe<Array<Maybe<ExternalConnectionConfigurationsOutput>>>;
  /** List the status of the user's external connection applications. */
  listApplicationStatus?: Maybe<Array<Maybe<ExternalConnectionApplicantOutput>>>;
  /** List all the requests (e.g. configuration to Canary Network requests) from a GSY User to the admins. */
  listUserToAdminRequests?: Maybe<Array<Maybe<UserToAdminRequestsOutput>>>;
  listAdminStats?: Maybe<AdminStats>;
  listAdminUserDetails?: Maybe<RegistryPageUserListOutput>;
  listAdminRegistryUsers?: Maybe<Array<Maybe<RegistryPageSingleUserOutput>>>;
  /** Read information from a single project. */
  listProjects?: Maybe<Array<Maybe<ProjectOutput>>>;
  /** List available projects. */
  readProject?: Maybe<ProjectOutput>;
  /** Returns library setting of the library for provided uuid */
  readLibrary?: Maybe<LibraryOutput>;
  /** Returns a filtered, ordered and paginated list of libraries */
  listLibraries?: Maybe<ListLibraryOutput>;
  cloudCoverageProfiles?: Maybe<Scalars['String']>;
  readConfiguration?: Maybe<ConfigurationOutput>;
  listConfigurations?: Maybe<ListConfigurationOutput>;
  availableAreaTypes?: Maybe<AvailableAreaTypesOutput>;
  getAreaProfileUuidMapping?: Maybe<Scalars['String']>;
  /** Query for the status of the simulation run */
  simulationStatus?: Maybe<Scalars['JSONString']>;
  /**
   * Combined query for all types of results
   * @deprecated This query will be deprecated soon in favour of individual result queries.
   */
  simulationResults?: Maybe<SimulationResultsData>;
  /** Query for results that apply to the whole simulation and are not connected to a specific area */
  simulationGlobalResults?: Maybe<GlobalSimulationResultsData>;
  /** Query for results that represent accumulated values at one time stamp */
  simulationAccumulatedResults?: Maybe<AccumulatedSimulationResultsData>;
  /** Query for results that are represented as profile as a function of time */
  simulationProfileResults?: Maybe<ProfileSimulationResultsData>;
  /** Query for results that are represented as differences between two timestamps */
  simulationDifferenceResults?: Maybe<DifferenceSimulationResultsData>;
  /** Query for results connected profiles of savings KPI */
  simulationSavingsKpiProfileResults?: Maybe<SavingsKpiProfileResultsData>;
  /** Query results related to scoreboard */
  scoreboardResults?: Maybe<Scalars['JSONString']>;
  /** Query for imported and exported energy aggregated for all homes of a community */
  importedExportedEnergyCommunity?: Maybe<Scalars['JSONString']>;
  passwordResetEmail?: Maybe<Scalars['String']>;
  registeredUsers?: Maybe<Array<Maybe<UserOutput>>>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesScmAccumulatedResultsArgs = {
  jobId: Scalars['String'];
  areaUuid: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesScmAccumulatedKpiDifferencesArgs = {
  jobId: Scalars['String'];
  areaUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesScmAccumulatedBillsDifferencesArgs = {
  jobId: Scalars['String'];
  areaUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesScmAccumulatedBillsDifferencesCommunityArgs = {
  jobId: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesScmProfileResultsArgs = {
  jobId: Scalars['String'];
  areaUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesAssetReportedDataArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListAssetMeasurementsArgs = {
  configUuid: Scalars['String'];
  assetUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListClientsB2bArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesAdminSimulationResultsB2bArgs = {
  configUuid: Scalars['String'];
  duration?: Maybe<AvailableDurationTypes>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesClientSimulationResultsB2bArgs = {
  configUuid: Scalars['String'];
  clientEmail: Scalars['String'];
  duration?: Maybe<AvailableDurationTypes>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesAssetSimulationResultsB2bArgs = {
  configUuid: Scalars['String'];
  assetUuid: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<AvailableDurationTypes>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesAdminOrdersB2bArgs = {
  configUuid: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  productType?: Maybe<AvailableMarketTypes>;
  orderType?: Maybe<AvailableOrderTypes>;
  limit?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesClientOrdersB2bArgs = {
  configUuid: Scalars['String'];
  clientEmail: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  productType?: Maybe<AvailableMarketTypes>;
  orderType?: Maybe<AvailableOrderTypes>;
  limit?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesAssetOrdersB2bArgs = {
  configUuid: Scalars['String'];
  assetUuid: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  productType?: Maybe<AvailableMarketTypes>;
  orderType?: Maybe<AvailableOrderTypes>;
  limit?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesPriceProfilesB2bArgs = {
  configUuid: Scalars['String'];
  assetUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  duration?: Maybe<AvailableDurationTypes>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesVolumeProfilesB2bArgs = {
  configUuid: Scalars['String'];
  assetUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  duration?: Maybe<AvailableDurationTypes>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesAllOrdersB2bArgs = {
  configUuid: Scalars['String'];
  productType?: Maybe<AvailableMarketTypes>;
  limit?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesAllTradesB2bArgs = {
  configUuid: Scalars['String'];
  productType: AvailableMarketTypes;
  limit?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListAggregatorRegistryUsersArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListGsyUserRegistryAssetsArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListGridOperatorRegistryMarketsArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListScmCommunitiesArgs = {
  configurationType?: Maybe<ConfigType>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListMeasurementVerificationRequestsArgs = {
  configUuid?: Maybe<Scalars['String']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListCommunityNotificationsArgs = {
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  configUuid?: Maybe<Scalars['String']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesGetScmCommunityApplicableFeesArgs = {
  configUuid?: Maybe<Scalars['String']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListCommunityManagersArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListCanaryNetworksArgs = {
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListGlobalSimulationLocationArgs = {
  minLat: Scalars['Float'];
  maxLat: Scalars['Float'];
  minLong: Scalars['Float'];
  maxLong: Scalars['Float'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesReadGlobalSimulationLocationArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListLiveEventsArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesLiveEventsRepresentationArgs = {
  configUuid: Scalars['String'];
  time: Scalars['DateTime'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesAreaSummaryArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListCollaborationsArgs = {
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListAvailableExternalApplicationArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesReadUserRoleArgs = {
  email?: Maybe<Scalars['String']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListAvailableExternalConnectionArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListApplicationStatusArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListUserToAdminRequestsArgs = {
  username?: Maybe<Scalars['String']>;
  status?: Maybe<UserRequestsStatus>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListAdminStatsArgs = {
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailSearch?: Maybe<Scalars['String']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListAdminUserDetailsArgs = {
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filterRole?: Maybe<UserRoleFilterField>;
  orderBy?: Maybe<UserStatsOrderField>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesListAdminRegistryUsersArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListProjectsArgs = {
  search?: Maybe<Scalars['String']>;
  nrConfigs?: Maybe<Scalars['Int']>;
  private?: Maybe<Scalars['Boolean']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesReadProjectArgs = {
  uuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesReadLibraryArgs = {
  uuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListLibrariesArgs = {
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  libraryTypes?: Maybe<Array<Maybe<LibraryTypesGraphql>>>;
  homeOrAssetOnly?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<LibraryOrderAlgorithmEnum>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesReadConfigurationArgs = {
  uuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesListConfigurationsArgs = {
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  projectUUID?: Maybe<Scalars['String']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesGetAreaProfileUuidMappingArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesSimulationStatusArgs = {
  jobId: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesSimulationResultsArgs = {
  jobId: Scalars['String'];
  uuid: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  timeResolutionHrs?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesSimulationGlobalResultsArgs = {
  jobId: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesSimulationAccumulatedResultsArgs = {
  jobId: Scalars['String'];
  areaUuid: Scalars['String'];
  timestamp?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesSimulationProfileResultsArgs = {
  jobId: Scalars['String'];
  areaUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
  timeResolutionHrs?: Maybe<Scalars['Int']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesSimulationDifferenceResultsArgs = {
  jobId: Scalars['String'];
  areaUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesSimulationSavingsKpiProfileResultsArgs = {
  jobId: Scalars['String'];
  areaUuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesScoreboardResultsArgs = {
  jobId: Scalars['String'];
};

/** Encapsulates all queries of gsy-web */
export type QueriesImportedExportedEnergyCommunityArgs = {
  jobId: Scalars['String'];
  areaUuid?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
};

/** Encapsulates all queries of gsy-web */
export type QueriesPasswordResetEmailArgs = {
  email: Scalars['String'];
};

/** Graphene object type for accumulated simulation results. */
export type AccumulatedSimulationResultsData = {
  __typename?: 'AccumulatedSimulationResultsData';
  cumulativeGridTrades?: Maybe<Scalars['JSONString']>;
  cumulativeNetEnergyFlow?: Maybe<Scalars['JSONString']>;
  bills?: Maybe<Scalars['JSONString']>;
  cumulativeBills?: Maybe<Scalars['JSONString']>;
  kpi?: Maybe<Scalars['JSONString']>;
};

/** Graphene object type for SCM profile results. */
export type ScmProfileResultsData = {
  __typename?: 'ScmProfileResultsData';
  kpi?: Maybe<Scalars['JSONString']>;
  billsEnergy?: Maybe<Scalars['JSONString']>;
};

/** Output for the client mutations for creating/updating a client. */
export type ClientOutput = {
  __typename?: 'ClientOutput';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  assetUuids?: Maybe<Scalars['JSONString']>;
};

/** GraphQL output structure for the admin simulation results for the B2B product. */
export type AdminSimulationResultsB2B = {
  __typename?: 'AdminSimulationResultsB2B';
  /** Id of the configuration. */
  configUuid?: Maybe<Scalars['String']>;
  /** Reports the overall aggregated results for all assets according to the duration input field. */
  overall?: Maybe<OverallResults>;
  /** Reports the overall delta results in percent for all assets according to the duration input field. */
  overallPercent?: Maybe<OverallPercentResults>;
  /** Reports general information for the status / progress of the simulation. */
  general?: Maybe<GlobalSimulationResultsData>;
  /** Reports the aggregated simulation results for one client. */
  clients?: Maybe<Array<Maybe<ClientResultsGraphQl>>>;
};

/**
 * Reports the overall aggregated results of the simulation for the last 15 mins, 1 hour, 1 week
 * etc. according to the duration input field.
 * Used in the admin, client and asset view, in order to report the aggregated results for
 * all clients for the case of admin view, for all assets of a client for the case of client view
 * and for one asset in the case of asset view.
 */
export type OverallResults = {
  __typename?: 'OverallResults';
  totalEnergySold?: Maybe<Scalars['Float']>;
  averageSellingPrice?: Maybe<Scalars['Float']>;
  totalEnergyBought?: Maybe<Scalars['Float']>;
  averageBuyingPrice?: Maybe<Scalars['Float']>;
  totalBalanceEur?: Maybe<Scalars['Float']>;
};

/**
 * Similar to the OverallResults, reports the overall aggregated result delta in percentage of
 * the simulation for the last 15 mins, 1 hour, 1 week etc. according to the duration input field.
 */
export type OverallPercentResults = {
  __typename?: 'OverallPercentResults';
  totalEnergySold?: Maybe<Scalars['Float']>;
  averageSellingPrice?: Maybe<Scalars['Float']>;
  totalEnergyBought?: Maybe<Scalars['Float']>;
  averageBuyingPrice?: Maybe<Scalars['Float']>;
  totalBalanceEur?: Maybe<Scalars['Float']>;
};

/** Graphene object type for global simulation results. */
export type GlobalSimulationResultsData = {
  __typename?: 'GlobalSimulationResultsData';
  currentMarket?: Maybe<Scalars['String']>;
  completedMarkets?: Maybe<Scalars['JSONString']>;
  status?: Maybe<Scalars['String']>;
  statusesEnum?: Maybe<SimulationStatusType>;
  progressInfo?: Maybe<ProgressInfo>;
  randomSeed?: Maybe<Scalars['String']>;
  assetsInfo?: Maybe<Scalars['JSONString']>;
  launchDatetime?: Maybe<Scalars['DateTime']>;
};

/** Possible simulation status. */
export enum SimulationStatusType {
  Queued = 'QUEUED',
  Initializing = 'INITIALIZING',
  Running = 'RUNNING',
  Paused = 'PAUSED',
  Stopped = 'STOPPED',
  Stopping = 'STOPPING',
  Finished = 'FINISHED',
  Failed = 'FAILED',
}

/** Graphene object type for simulation progress. */
export type ProgressInfo = {
  __typename?: 'ProgressInfo';
  etaSeconds?: Maybe<Scalars['Int']>;
  elapsedTimeSeconds?: Maybe<Scalars['Int']>;
  percentageCompleted?: Maybe<Scalars['Int']>;
};

/** Reports the aggregated simulation results for one client since the start of the simulation. */
export type ClientResultsGraphQl = {
  __typename?: 'ClientResultsGraphQL';
  totalInstalledCapacityMwh?: Maybe<Scalars['Float']>;
  totalConsumptionMwh?: Maybe<Scalars['Float']>;
  totalProductionMwh?: Maybe<Scalars['Float']>;
  totalEnergyProducedMwh?: Maybe<Scalars['Float']>;
  totalEnergyConsumedMwh?: Maybe<Scalars['Float']>;
  totalBalanceEur?: Maybe<Scalars['Float']>;
  clientFullName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
};

/** Available durations that are able to be used as input / output for the B2B queries. */
export enum AvailableDurationTypes {
  Minutes_15 = 'MINUTES_15',
  Hour_1 = 'HOUR_1',
  Day_1 = 'DAY_1',
  Week_1 = 'WEEK_1',
  Month_1 = 'MONTH_1',
  Year_1 = 'YEAR_1',
}

/** GraphQL output structure for the simulation results of one client for the B2B product. */
export type ClientSimulationResultsB2B = {
  __typename?: 'ClientSimulationResultsB2B';
  /** Id of the configuration. */
  configUuid?: Maybe<Scalars['String']>;
  clientFullName?: Maybe<Scalars['String']>;
  clientEmail?: Maybe<Scalars['String']>;
  /** Reports the overall aggregated results for all assets of this client according to the duration input field. */
  overall?: Maybe<OverallResults>;
  /** Reports the overall delta results in percent for all assets of this client according to the duration input field. */
  overallPercent?: Maybe<OverallPercentResults>;
  /** Reports general information for the status / progress of the simulation. */
  general?: Maybe<GlobalSimulationResultsData>;
  /** Reports the simulation results for the assets that the client controls. */
  assets?: Maybe<Array<Maybe<AssetResultsGraphQl>>>;
};

/** Reports the aggregated simulation results for one asset since the start of the simulation. */
export type AssetResultsGraphQl = {
  __typename?: 'AssetResultsGraphQL';
  totalInstalledCapacityMwh?: Maybe<Scalars['Float']>;
  totalConsumptionMwh?: Maybe<Scalars['Float']>;
  totalProductionMwh?: Maybe<Scalars['Float']>;
  totalEnergyProducedMwh?: Maybe<Scalars['Float']>;
  totalEnergyConsumedMwh?: Maybe<Scalars['Float']>;
  totalBalanceEur?: Maybe<Scalars['Float']>;
  assetName?: Maybe<Scalars['String']>;
  assetUuid?: Maybe<Scalars['String']>;
  assetAddress?: Maybe<Scalars['String']>;
};

/** GraphQL output structure for the simulation results of one asset. */
export type AssetSimulationResultsB2B = {
  __typename?: 'AssetSimulationResultsB2B';
  /** Id of the configuration. */
  configUuid?: Maybe<Scalars['String']>;
  assetName?: Maybe<Scalars['String']>;
  assetUuid?: Maybe<Scalars['String']>;
  /** Reports the overall aggregated results for the asset according to the duration input field. */
  overall?: Maybe<OverallResults>;
  /** Reports the overall delta results in percent for the asset according to the duration input field. */
  overallPercent?: Maybe<OverallPercentResults>;
  /** Reports general information for the status / progress of the simulation. */
  general?: Maybe<GlobalSimulationResultsData>;
  /** Reports the traded energy for the asset for all the open markets. */
  tradedEnergy?: Maybe<Array<Maybe<TimeSeriesTradeResults>>>;
};

/** Energy trade profile for one asset on a specific market. */
export type TimeSeriesTradeResults = {
  __typename?: 'TimeSeriesTradeResults';
  marketType?: Maybe<AvailableMarketTypes>;
  profile?: Maybe<Scalars['JSONString']>;
};

/** Collection of available market types. */
export enum AvailableMarketTypes {
  Spot = 'SPOT',
  Balancing = 'BALANCING',
  Settlement = 'SETTLEMENT',
  Future = 'FUTURE',
  DayForward = 'DAY_FORWARD',
  WeekForward = 'WEEK_FORWARD',
  MonthForward = 'MONTH_FORWARD',
  YearForward = 'YEAR_FORWARD',
  Intraday = 'INTRADAY',
}

/** GraphQL output structure for the trade information. */
export type OrderResultsB2B = {
  __typename?: 'OrderResultsB2B';
  /** Id of the order. */
  orderId?: Maybe<Scalars['String']>;
  /** State of the order, can be open/matched/closed. */
  state?: Maybe<Scalars['String']>;
  /** Time that the order was posted on the market. */
  time?: Maybe<Scalars['DateTime']>;
  /** Time that the energy of the order will be delivered. */
  executionTime?: Maybe<Scalars['DateTime']>;
  /** Type of the order, can be bid/offer. */
  transactionType?: Maybe<Scalars['String']>;
  /** Type of the market that the order was posted, can be continuous/auction. */
  marketType?: Maybe<Scalars['String']>;
  /** Type of the product of the order. */
  productType?: Maybe<AvailableMarketTypes>;
  /** Energy of the order. */
  energyMwh?: Maybe<Scalars['Float']>;
  /** Energy rate of the order, in euro/MWh. */
  energyRate?: Maybe<Scalars['Float']>;
  /** Optional field only for the matched / executed orders. Detailed information about the orders that generated the matching. */
  tradeDetails?: Maybe<TradeDetailsB2B>;
};

/**
 * GraphQL output structure for the trade details, consisting of information about the offer and
 * bid that resulted to the trade.
 */
export type TradeDetailsB2B = {
  __typename?: 'TradeDetailsB2B';
  /** Id of the offer that generated the trade. */
  offerId?: Maybe<Scalars['String']>;
  /** Energy volume of the offer. */
  offerEnergyMwh?: Maybe<Scalars['Float']>;
  /** Time that the offer was created. */
  offerCreationTime?: Maybe<Scalars['DateTime']>;
  /** Id of the bid that generated the trade. */
  bidId?: Maybe<Scalars['String']>;
  /** Energy volume of the bid. */
  bidEnergyMwh?: Maybe<Scalars['Float']>;
  /** Time that the bid was created. */
  bidCreationTime?: Maybe<Scalars['DateTime']>;
};

/** Defines the type of order that is available for selectors. */
export enum AvailableOrderTypes {
  Open = 'OPEN',
  Matched = 'MATCHED',
  Executed = 'EXECUTED',
}

/** GraphQL output structure for the price profile of a market type. */
export type PriceProfilesResultsB2B = {
  __typename?: 'PriceProfilesResultsB2B';
  timeslot?: Maybe<Scalars['DateTime']>;
  priceProfile?: Maybe<Scalars['JSONString']>;
};

/** GraphQL output structure for the volume profile of a market type. */
export type VolumeProfilesResultsB2B = {
  __typename?: 'VolumeProfilesResultsB2B';
  timeslot?: Maybe<Scalars['DateTime']>;
  volumeProfile?: Maybe<Scalars['JSONString']>;
};

/** GraphQL output structure for the open bids/offers. */
export type AnonymousOrderResultsB2B = {
  __typename?: 'AnonymousOrderResultsB2B';
  /** Type of the order, can be bid/offer. */
  transactionType?: Maybe<Scalars['String']>;
  /** Energy of the order. */
  energyMwh?: Maybe<Scalars['Float']>;
  /** Energy rate of the order, in euro/MWh. */
  energyRate?: Maybe<Scalars['Float']>;
};

/** GraphQL output structure for trades. */
export type AnonymousTradeResultsB2B = {
  __typename?: 'AnonymousTradeResultsB2B';
  /** Creation time of trade */
  time?: Maybe<Scalars['DateTime']>;
  /** Energy of the order in MWh. */
  energyMwh?: Maybe<Scalars['Float']>;
  /** Energy rate of the order, in euro/MWh. */
  energyRate?: Maybe<Scalars['Float']>;
};

/** Graphene output object for individual user registry details. */
export type RegistryPageSingleUserOutput = {
  __typename?: 'RegistryPageSingleUserOutput';
  userDetails?: Maybe<UserSimpleOutput>;
  assetsConfigured?: Maybe<Array<Maybe<AssetRegistrationOutput>>>;
};

/** Simple user details GraphQL output, not including time statistics. */
export type UserSimpleOutput = {
  __typename?: 'UserSimpleOutput';
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  userRole?: Maybe<Scalars['String']>;
  joinedDate?: Maybe<Scalars['DateTime']>;
  numberOfProjects?: Maybe<Scalars['Int']>;
  chosenRole?: Maybe<Scalars['String']>;
  numberOfSimulations?: Maybe<Scalars['Int']>;
  numberOfCanaryNetworks?: Maybe<Scalars['Int']>;
  numberOfCollaborations?: Maybe<Scalars['Int']>;
  numberOfConfiguredAgents?: Maybe<Scalars['Int']>;
  numberOfLibraries?: Maybe<Scalars['Int']>;
  requestStatus?: Maybe<UserRequestsStatusOutput>;
  canaryInvitations?: Maybe<Array<Maybe<CanaryInvitationsOutput>>>;
};

/** Output status of user requests to the admin. */
export enum UserRequestsStatusOutput {
  Received = 'RECEIVED',
  Executed = 'EXECUTED',
  Declined = 'DECLINED',
  NotRequested = 'NOT_REQUESTED',
}

/** GraphQL output for a Canary Network invitation. */
export type CanaryInvitationsOutput = {
  __typename?: 'CanaryInvitationsOutput';
  configUuid?: Maybe<Scalars['String']>;
  status?: Maybe<InviteStatusOutputEnum>;
};

/** Graphene Enum for the status of the User to Canary Network invitation. */
export enum InviteStatusOutputEnum {
  Invited = 'INVITED',
  Blocked = 'BLOCKED',
}

/** Graphene output object for asset registry. */
export type AssetRegistrationOutput = {
  __typename?: 'AssetRegistrationOutput';
  assetUuid?: Maybe<Scalars['String']>;
  registrationStatus?: Maybe<Scalars['String']>;
};

/** GsyUserRegistryAssets query output type */
export type GsyUserRegistryOutput = {
  __typename?: 'GsyUserRegistryOutput';
  assetInformation?: Maybe<Array<Maybe<AssetInformation>>>;
  aggregatorInformation?: Maybe<GsyUserAggregatorOutput>;
};

/** GraphQL output for asset information */
export type AssetInformation = {
  __typename?: 'AssetInformation';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** GraphQL output type for aggregator information */
export type GsyUserAggregatorOutput = {
  __typename?: 'GsyUserAggregatorOutput';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** GraphQL output for market information */
export type MarketInformation = {
  __typename?: 'MarketInformation';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** Class representing the GraphQL output of Communities. */
export type CommunityOutput = {
  __typename?: 'CommunityOutput';
  projects?: Maybe<Array<Maybe<CommunityProjectOutput>>>;
};

/** Class representing the GraphQL output of Community Projects. */
export type CommunityProjectOutput = {
  __typename?: 'CommunityProjectOutput';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  private?: Maybe<Scalars['Boolean']>;
  locationVisible?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  configurations?: Maybe<Array<Maybe<CommunityConfigurationOutput>>>;
};

/** Class representing the GraphQL output of community configs. */
export type CommunityConfigurationOutput = {
  __typename?: 'CommunityConfigurationOutput';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigType>;
  simulationId?: Maybe<Scalars['String']>;
  globalCumulativeNetEnergyFlow?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

/** Available configuration types */
export enum ConfigType {
  Simulation = 'SIMULATION',
  Collaboration = 'COLLABORATION',
  CanaryNetwork = 'CANARY_NETWORK',
  B2B = 'B2B',
}

/** Class representing the GraphQL output of list of configurations. */
export type ListConfigurationOutput = {
  __typename?: 'ListConfigurationOutput';
  totalCount?: Maybe<Scalars['Int']>;
  configurations?: Maybe<Array<Maybe<ConfigurationOutput>>>;
};

/** Class representing the GraphQL output of configurations. */
export type ConfigurationOutput = {
  __typename?: 'ConfigurationOutput';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigType>;
  cloudCoverageOptions?: Maybe<Scalars['JSONString']>;
  scenarioData?: Maybe<ScenarioOutput>;
  settingsData?: Maybe<SettingsOutput>;
  availableAreaTypes?: Maybe<AvailableAreaTypesOutput>;
  simulationResults?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  events?: Maybe<ConfigurationEvents>;
  timestamp?: Maybe<Scalars['DateTime']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Project>;
  warnings?: Maybe<Scalars['String']>;
  areaSummary?: Maybe<ConfigurationDetails>;
  resultsStatus?: Maybe<Scalars['String']>;
  resultsProgressInfo?: Maybe<ProgressInfo>;
  private?: Maybe<Scalars['Boolean']>;
  deviceAggregatorMapping?: Maybe<Scalars['JSONString']>;
  coordinates?: Maybe<CoordinatesOutput>;
};

/** Class representing the GraphQL output for the scenario of a configuration. */
export type ScenarioOutput = {
  __typename?: 'ScenarioOutput';
  representation?: Maybe<AreaOutput>;
  latest?: Maybe<AreaOutput>;
  initialDeviceCount?: Maybe<Scalars['JSONString']>;
  deviceCount?: Maybe<Scalars['JSONString']>;
  deviceCountNumber?: Maybe<Scalars['Int']>;
  forecastStreamAreaMapping?: Maybe<Scalars['JSONString']>;
  homeInfo?: Maybe<HomeInformationOutput>;
  areaUuidTypeLists?: Maybe<AreaUuidTypesOutput>;
};

/** Class representing the GraphQL output for market area. */
export type AreaOutput = {
  __typename?: 'AreaOutput';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<DeviceTypes>>>;
  gridFeePercentage?: Maybe<Scalars['Float']>;
  gridFeeConstant?: Maybe<Scalars['Float']>;
  importCapacityKva?: Maybe<Scalars['Float']>;
  exportCapacityKva?: Maybe<Scalars['Float']>;
  baselinePeakEnergyImportKwh?: Maybe<Scalars['Float']>;
  baselinePeakEnergyExportKwh?: Maybe<Scalars['Float']>;
  fitAreaBoundary?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Class representing the device type used for typing. */
export type DeviceTypes =
  | Pv
  | Storage
  | Load
  | SmartMeter
  | FiniteDieselGenerator
  | MarketMaker
  | InfiniteBus
  | WindTurbine
  | HeatPump
  | ScmStorage
  | ScmHeatPump
  | AreaOutput;

/** Class representing the GraphQL output for a PV. */
export type Pv = {
  __typename?: 'PV';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  panelCount?: Maybe<Scalars['Int']>;
  initialSellingRate?: Maybe<Scalars['Float']>;
  finalSellingRate?: Maybe<Scalars['Float']>;
  fitToLimit?: Maybe<Scalars['Boolean']>;
  updateInterval?: Maybe<Scalars['Int']>;
  energyRateDecreasePerUpdate?: Maybe<Scalars['Float']>;
  /** @deprecated This parameter will be deprecated with d3a.io */
  maxPanelPowerW?: Maybe<Scalars['Float']>;
  capacityKw?: Maybe<Scalars['Float']>;
  cloudCoverage?: Maybe<Scalars['Int']>;
  powerProfile?: Maybe<Scalars['String']>;
  powerProfileUuid?: Maybe<Scalars['String']>;
  powerMeasurementUuid?: Maybe<Scalars['String']>;
  useMarketMakerRate?: Maybe<Scalars['Boolean']>;
  forecastStreamEnabled?: Maybe<Scalars['Boolean']>;
  forecastStreamId?: Maybe<Scalars['String']>;
  azimuth?: Maybe<Scalars['Float']>;
  tilt?: Maybe<Scalars['Float']>;
};

/** Class representing the GraphQL output for a Storage. */
export type Storage = {
  __typename?: 'Storage';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  initialSoc?: Maybe<Scalars['Float']>;
  minAllowedSoc?: Maybe<Scalars['Float']>;
  batteryCapacityKwh?: Maybe<Scalars['Float']>;
  maxAbsBatteryPowerKw?: Maybe<Scalars['Float']>;
  capPriceStrategy?: Maybe<Scalars['Boolean']>;
  initialSellingRate?: Maybe<Scalars['Float']>;
  finalSellingRate?: Maybe<Scalars['Float']>;
  initialBuyingRate?: Maybe<Scalars['Float']>;
  finalBuyingRate?: Maybe<Scalars['Float']>;
  fitToLimit?: Maybe<Scalars['Boolean']>;
  energyRateIncreasePerUpdate?: Maybe<Scalars['Float']>;
  energyRateDecreasePerUpdate?: Maybe<Scalars['Float']>;
  updateInterval?: Maybe<Scalars['Int']>;
};

/** Class representing the GraphQL output for a Load. */
export type Load = {
  __typename?: 'Load';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  avgPowerW?: Maybe<Scalars['Float']>;
  initialBuyingRate?: Maybe<Scalars['Int']>;
  finalBuyingRate?: Maybe<Scalars['Int']>;
  fitToLimit?: Maybe<Scalars['Boolean']>;
  updateInterval?: Maybe<Scalars['Int']>;
  energyRateIncreasePerUpdate?: Maybe<Scalars['Float']>;
  dailyLoadProfile?: Maybe<Scalars['String']>;
  dailyLoadProfileUuid?: Maybe<Scalars['String']>;
  dailyLoadMeasurementUuid?: Maybe<Scalars['String']>;
  useMarketMakerRate?: Maybe<Scalars['Boolean']>;
  forecastStreamEnabled?: Maybe<Scalars['Boolean']>;
  forecastStreamId?: Maybe<Scalars['String']>;
};

/** Class representing the GraphQL output for a Smart Meter device. */
export type SmartMeter = {
  __typename?: 'SmartMeter';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  smartMeterProfile?: Maybe<Scalars['String']>;
  smartMeterProfileUuid?: Maybe<Scalars['String']>;
  smartMeterMeasurementUuid?: Maybe<Scalars['String']>;
  initialSellingRate?: Maybe<Scalars['Float']>;
  finalSellingRate?: Maybe<Scalars['Float']>;
  energyRateDecreasePerUpdate?: Maybe<Scalars['Float']>;
  initialBuyingRate?: Maybe<Scalars['Float']>;
  finalBuyingRate?: Maybe<Scalars['Float']>;
  energyRateIncreasePerUpdate?: Maybe<Scalars['Float']>;
  fitToLimit?: Maybe<Scalars['Boolean']>;
  updateInterval?: Maybe<Scalars['Int']>;
  useMarketMakerRate?: Maybe<Scalars['Boolean']>;
};

/** Class representing the GraphQL output for a FiniteDieselGenerator. */
export type FiniteDieselGenerator = {
  __typename?: 'FiniteDieselGenerator';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  energyRate?: Maybe<Scalars['Float']>;
  maxAvailablePowerKw?: Maybe<Scalars['Float']>;
};

/** Class representing the GraphQL output for a MarketMaker. */
export type MarketMaker = {
  __typename?: 'MarketMaker';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  energyRate?: Maybe<Scalars['Float']>;
  energyRateProfile?: Maybe<Scalars['String']>;
  energyRateProfileUuid?: Maybe<Scalars['String']>;
  gridConnected?: Maybe<Scalars['Boolean']>;
};

/** Class representing the GraphQL output for a InfiniteBus. */
export type InfiniteBus = {
  __typename?: 'InfiniteBus';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  energyRate?: Maybe<Scalars['Float']>;
  energyRateProfile?: Maybe<Scalars['String']>;
  energyRateProfileUuid?: Maybe<Scalars['String']>;
  energyBuyRate?: Maybe<Scalars['Float']>;
  buyingRateProfile?: Maybe<Scalars['String']>;
  buyingRateProfileUuid?: Maybe<Scalars['String']>;
};

/** Class representing the GraphQL output for a Wind turbine / power plant. */
export type WindTurbine = {
  __typename?: 'WindTurbine';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  initialSellingRate?: Maybe<Scalars['Float']>;
  finalSellingRate?: Maybe<Scalars['Float']>;
  fitToLimit?: Maybe<Scalars['Boolean']>;
  updateInterval?: Maybe<Scalars['Int']>;
  energyRateDecreasePerUpdate?: Maybe<Scalars['Float']>;
  powerProfile?: Maybe<Scalars['String']>;
  powerProfileUuid?: Maybe<Scalars['String']>;
  capacityKw?: Maybe<Scalars['Float']>;
};

/** Class representing the GraphQL output for a HeatPump. */
export type HeatPump = {
  __typename?: 'HeatPump';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  maximumPowerRatingKw?: Maybe<Scalars['Float']>;
  minTempC?: Maybe<Scalars['Float']>;
  maxTempC?: Maybe<Scalars['Float']>;
  initialTempC?: Maybe<Scalars['Float']>;
  sourceTempCProfile?: Maybe<Scalars['String']>;
  sourceTempCProfileUuid?: Maybe<Scalars['String']>;
  sourceTempCMeasurementUuid?: Maybe<Scalars['String']>;
  tankVolumeL?: Maybe<Scalars['Float']>;
  consumptionKwhProfile?: Maybe<Scalars['String']>;
  consumptionKwhProfileUuid?: Maybe<Scalars['String']>;
  consumptionKwhMeasurementUuid?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['Int']>;
  initialBuyingRate?: Maybe<Scalars['Int']>;
  finalBuyingRate?: Maybe<Scalars['Int']>;
  preferredBuyingRate?: Maybe<Scalars['Float']>;
  useMarketMakerRate?: Maybe<Scalars['Boolean']>;
  updateInterval?: Maybe<Scalars['Int']>;
};

/** Class representing the GraphQL output for SCM Storage. */
export type ScmStorage = {
  __typename?: 'ScmStorage';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  prosumptionKwhProfile?: Maybe<Scalars['String']>;
  prosumptionKwhProfileUuid?: Maybe<Scalars['String']>;
  prosumptionKwhMeasurementUuid?: Maybe<Scalars['String']>;
  forecastStreamId?: Maybe<Scalars['String']>;
};

/** Class representing the GraphQL output for Heat Pump Storage. */
export type ScmHeatPump = {
  __typename?: 'ScmHeatPump';
  name: Scalars['String'];
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  serialized: Scalars['String'];
  uuid: Scalars['String'];
  libraryUUID?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  address?: Maybe<Scalars['String']>;
  consumptionKwhProfile?: Maybe<Scalars['String']>;
  consumptionKwhProfileUuid?: Maybe<Scalars['String']>;
  consumptionKwhMeasurementUuid?: Maybe<Scalars['String']>;
  forecastStreamId?: Maybe<Scalars['String']>;
};

/** Class representing the GraphQL output for the home information. */
export type HomeInformationOutput = {
  __typename?: 'HomeInformationOutput';
  homeUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  energyBills?: Maybe<Array<Maybe<HomeBillOutput>>>;
  scmHomeDetails?: Maybe<Array<Maybe<ScmCommunityMember>>>;
};

/** Class representing the GraphQL output for the home bill. */
export type HomeBillOutput = {
  __typename?: 'HomeBillOutput';
  uuid: Scalars['String'];
  bill: Scalars['JSONString'];
};

/** GraphQL output of an SCM Community member. */
export type ScmCommunityMember = {
  __typename?: 'SCMCommunityMember';
  /** Community member home address. */
  address?: Maybe<Scalars['String']>;
  /** Percentage of the energy produced by the community that will be shared with this home. */
  coefficientPercentage?: Maybe<Scalars['Float']>;
  /** Community member email address. */
  email?: Maybe<Scalars['String']>;
  /** Feed in tariff (energy rate of the home PV), in Euros/kWh. */
  feedInTariff?: Maybe<Scalars['Float']>;
  /** Fixed monthly fee, in Euros. */
  fixedMonthlyFee?: Maybe<Scalars['Float']>;
  /** Grid import fee, in Euros/kWh. */
  gridImportFeeConst?: Maybe<Scalars['Float']>;
  /** Grid export fee, in Euros/kWh. */
  gridExportFeeConst?: Maybe<Scalars['Float']>;
  /** Utility rate (energy rate of the home load), in Euros/kWh. */
  marketMakerRate?: Maybe<Scalars['Float']>;
  /** Marketplace monthly fee, in Euros. */
  marketplaceMonthlyFee?: Maybe<Scalars['Float']>;
  /** Assistance monthly fee, in Euros. */
  assistanceMonthlyFee?: Maybe<Scalars['Float']>;
  /** Community member name. */
  name?: Maybe<Scalars['String']>;
  /** Community member uuid. */
  uuid?: Maybe<Scalars['String']>;
  /** Taxes and surcharges, in Euros/kWh. */
  taxesSurcharges?: Maybe<Scalars['Float']>;
  /** Community member zip code. */
  zipCode?: Maybe<Scalars['String']>;
  /** Number of assets that the community member owns. */
  assetCount?: Maybe<Scalars['Int']>;
};

/** Class representing the GraphQL output for the area uuids divided into types. */
export type AreaUuidTypesOutput = {
  __typename?: 'AreaUuidTypesOutput';
  communityAreaUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  homeAreaUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  marketAreaUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  assetUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Class representing the GraphQL output for the settings. */
export type SettingsOutput = {
  __typename?: 'SettingsOutput';
  durationDays?: Maybe<Scalars['Float']>;
  slotLengthMinutes?: Maybe<Scalars['Int']>;
  tickLengthSeconds?: Maybe<Scalars['Int']>;
  marketCount?: Maybe<Scalars['Int']>;
  slotLengthRealtimeSeconds?: Maybe<Scalars['Int']>;
  /** @deprecated This parameter will be deprecated with d3a.io */
  maxPanelPowerW?: Maybe<Scalars['Float']>;
  capacityKw?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  spotMarketType?: Maybe<SpotMarketType>;
  bidOfferMatchAlgo?: Maybe<BidOfferMatchAlgo>;
  advancedSettings?: Maybe<Scalars['String']>;
  randomSeed?: Maybe<Scalars['Int']>;
  externalConnectionEnabled?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currencies>;
  settlementMarketEnabled?: Maybe<Scalars['Boolean']>;
  relativeStdFromForecastPercent?: Maybe<Scalars['Float']>;
  scmCoefficientAlgorithm?: Maybe<ScmCoefficientAlgorithm>;
  scmGroupSettingsName?: Maybe<Scalars['String']>;
  scmCnHoursOfDelay?: Maybe<Scalars['Int']>;
  endDateTime?: Maybe<Scalars['DateTime']>;
};

/** Available sot market types. */
export enum SpotMarketType {
  OneSided = 'ONE_SIDED',
  TwoSided = 'TWO_SIDED',
  Coefficients = 'COEFFICIENTS',
}

/** Available bid matching options. */
export enum BidOfferMatchAlgo {
  PayAsBid = 'PAY_AS_BID',
  PayAsClear = 'PAY_AS_CLEAR',
  External = 'EXTERNAL',
  Dof = 'DOF',
}

/** Supported currencies. */
export enum Currencies {
  Eur = 'EUR',
  Usd = 'USD',
  Jpy = 'JPY',
  Gbp = 'GBP',
  Aud = 'AUD',
  Cad = 'CAD',
  Chf = 'CHF',
  Cny = 'CNY',
  Cop = 'COP',
}

/** Available SCM coefficient algorithm types. */
export enum ScmCoefficientAlgorithm {
  Static = 'STATIC',
  Dynamic = 'DYNAMIC',
  NoCommunitySelfConsumption = 'NO_COMMUNITY_SELF_CONSUMPTION',
}

/** Class representing (nobody reads this) the GraphQL output of available area types. */
export type AvailableAreaTypesOutput = {
  __typename?: 'AvailableAreaTypesOutput';
  leafTypes?: Maybe<Array<Maybe<AreaDescription>>>;
  nodeTypes?: Maybe<Array<Maybe<AreaDescription>>>;
};

/** Class representing the GraphQL output area description. */
export type AreaDescription = {
  __typename?: 'AreaDescription';
  name: Scalars['String'];
  type: Scalars['String'];
  parameters?: Maybe<Array<Maybe<AreaParametersType>>>;
};

/** Class representing the GraphQL output area parameters. */
export type AreaParametersType = {
  __typename?: 'AreaParametersType';
  name?: Maybe<Scalars['String']>;
  verbose?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  minValue?: Maybe<Scalars['String']>;
  maxValue?: Maybe<Scalars['String']>;
};

export type ConfigurationEvents = {
  __typename?: 'ConfigurationEvents';
  configurationUuid?: Maybe<Scalars['String']>;
  areaEvents?: Maybe<Array<Maybe<AreaEvents>>>;
  settingsEvents?: Maybe<Array<Maybe<SettingsEventOutput>>>;
};

export type AreaEvents = {
  __typename?: 'AreaEvents';
  areaUuid?: Maybe<Scalars['String']>;
  triggerEvents?: Maybe<Array<Maybe<AreaTriggerEvent>>>;
};

export type AreaTriggerEvent = {
  __typename?: 'AreaTriggerEvent';
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EventType>;
};

export enum EventType {
  Disconnect = 'DISCONNECT',
  Connect = 'CONNECT',
  Disable = 'DISABLE',
  Enable = 'ENABLE',
}

export type SettingsEventOutput = {
  __typename?: 'SettingsEventOutput';
  time?: Maybe<Scalars['DateTime']>;
  marketMakerRate?: Maybe<Scalars['Int']>;
  marketMakerRateFile?: Maybe<Scalars['Upload']>;
  spotMarketType?: Maybe<SpotMarketType>;
  bidOfferMatchAlgo?: Maybe<BidOfferMatchAlgo>;
};

/** Class representing the GraphQL output of Projects. */
export type Project = {
  __typename?: 'Project';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
};

/** Class representing the GraphQL output of configuration details. */
export type ConfigurationDetails = {
  __typename?: 'ConfigurationDetails';
  configuredDevices?: Maybe<Scalars['Int']>;
  configuredAreas?: Maybe<Scalars['Int']>;
  maxLimit?: Maybe<Scalars['Int']>;
};

/** Class combining the coordinates of an asset or configuration. */
export type CoordinatesOutput = {
  __typename?: 'CoordinatesOutput';
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
};

/** GraphQL Output argument of a measurement verification request. */
export type MeasurementVerificationRequest = {
  __typename?: 'MeasurementVerificationRequest';
  configUuid?: Maybe<Scalars['String']>;
  areaUuid?: Maybe<Scalars['String']>;
  status?: Maybe<UserRequestsStatus>;
  userName?: Maybe<Scalars['String']>;
};

/** Status of user requests to the admin. */
export enum UserRequestsStatus {
  Received = 'RECEIVED',
  Executed = 'EXECUTED',
  Declined = 'DECLINED',
}

/** GraphQL output argument of a community notifications list. */
export type ListCommunityNotificationOutput = {
  __typename?: 'ListCommunityNotificationOutput';
  totalCount?: Maybe<Scalars['Int']>;
  unreadCount?: Maybe<Scalars['Int']>;
  notifications?: Maybe<Array<Maybe<CommunityNotificationOutput>>>;
};

/** GraphQL output argument of a community notification. */
export type CommunityNotificationOutput = {
  __typename?: 'CommunityNotificationOutput';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  uploadedFiles?: Maybe<Array<Maybe<NotificationFileUploadOutput>>>;
  receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** GraphQL output of a single notification uploaded file. */
export type NotificationFileUploadOutput = {
  __typename?: 'NotificationFileUploadOutput';
  /** Name of the uploaded file. */
  name: Scalars['String'];
  /** URL of the uploaded file. */
  url: Scalars['String'];
  /** UUID of the uploaded file. */
  uuid: Scalars['UUID'];
};

/** GraphQL output argument for the enabled grid fees. */
export type CommunityApplicableFees = {
  __typename?: 'CommunityApplicableFees';
  gridImportFeeConst?: Maybe<Scalars['Boolean']>;
  gridExportFeeConst?: Maybe<Scalars['Boolean']>;
  taxesSurcharges?: Maybe<Scalars['Boolean']>;
  fixedMonthlyFee?: Maybe<Scalars['Boolean']>;
  marketplaceMonthlyFee?: Maybe<Scalars['Boolean']>;
  assistanceMonthlyFee?: Maybe<Scalars['Boolean']>;
};

export type AggregatorOutput = {
  __typename?: 'AggregatorOutput';
  name: Scalars['String'];
  aggregatorUuid: Scalars['UUID'];
  configUuid: Scalars['UUID'];
  devicesList?: Maybe<Array<Maybe<DeviceOutput>>>;
};

export type DeviceOutput = {
  __typename?: 'DeviceOutput';
  deviceUuid: Scalars['UUID'];
  aggregatorUuid: Scalars['UUID'];
  configUuid: Scalars['UUID'];
  addedViaLiveEvent: Scalars['Boolean'];
};

export type LocationTotalCount = {
  __typename?: 'LocationTotalCount';
  totalMarkets?: Maybe<Scalars['Int']>;
  totalDevices?: Maybe<Scalars['Int']>;
};

export type LocationBoundaryOutput = {
  __typename?: 'LocationBoundaryOutput';
  totalMarkets?: Maybe<Scalars['Int']>;
  totalDevices?: Maybe<Scalars['Int']>;
  boundaryId?: Maybe<Scalars['String']>;
  simulationsInProgress?: Maybe<Scalars['Int']>;
};

/** Output class for coordinates. */
export type SimulationLocationOutput = {
  __typename?: 'SimulationLocationOutput';
  boundaryId?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

export type SimulationLocationVisibilityOutput = {
  __typename?: 'SimulationLocationVisibilityOutput';
  totalMarkets?: Maybe<Scalars['Int']>;
  totalDevices?: Maybe<Scalars['Int']>;
  boundaryId?: Maybe<Scalars['String']>;
  simulationsInProgress?: Maybe<Scalars['Int']>;
  configUuid?: Maybe<Scalars['String']>;
  projectUuid?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ConfigType>;
};

/** Response of a list of live events. */
export type LiveEventListOutput = {
  __typename?: 'LiveEventListOutput';
  eventType?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['JSONString']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  diff?: Maybe<Scalars['JSONString']>;
};

/** Representation of a live event, response for live events queries. */
export type LiveEventRepresentation = {
  __typename?: 'LiveEventRepresentation';
  timeStamp?: Maybe<Scalars['DateTime']>;
  representation?: Maybe<AreaOutput>;
};

export type ExternalConnectionApplicantOutput = {
  __typename?: 'ExternalConnectionApplicantOutput';
  username?: Maybe<Scalars['String']>;
  configUuid?: Maybe<Scalars['String']>;
  areaUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** Graphql output for list of available user roles */
export type AvailableUserRolesOutput = {
  __typename?: 'AvailableUserRolesOutput';
  userRoles?: Maybe<Array<Maybe<UserRoleOutput>>>;
};

/** Graphql output for the info of a user and its role */
export type UserRoleOutput = {
  __typename?: 'UserRoleOutput';
  roleName?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type ExternalConnectionConfigurationsOutput = {
  __typename?: 'ExternalConnectionConfigurationsOutput';
  configuration?: Maybe<Array<Maybe<ExternalConnectionAreaOutput>>>;
};

export type ExternalConnectionAreaOutput = {
  __typename?: 'ExternalConnectionAreaOutput';
  configUuid?: Maybe<Scalars['String']>;
  availableAreaUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Output for UserToAdminRequests query. */
export type UserToAdminRequestsOutput = {
  __typename?: 'UserToAdminRequestsOutput';
  id?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  profileThumbnail?: Maybe<Scalars['String']>;
  requestType?: Maybe<UserRequestsTypes>;
  status?: Maybe<UserRequestsStatus>;
  configUuid?: Maybe<Scalars['String']>;
};

/** Types to distinguish between different user requests to the admin. */
export enum UserRequestsTypes {
  ConvertSimToCn = 'CONVERT_SIM_TO_CN',
}

/** Graphql output object containing admin stats */
export type AdminStats = {
  __typename?: 'AdminStats';
  userDetails?: Maybe<Array<Maybe<UserDetailOutput>>>;
  userActivity?: Maybe<ActivityDetailOutput>;
};

/** Graphql output object containing user details */
export type UserDetailOutput = {
  __typename?: 'UserDetailOutput';
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  userRole?: Maybe<Scalars['String']>;
  joinedDate?: Maybe<Scalars['DateTime']>;
  numberOfProjects?: Maybe<Scalars['Int']>;
  chosenRole?: Maybe<Scalars['String']>;
  numberOfSimulations?: Maybe<Scalars['Int']>;
  numberOfCanaryNetworks?: Maybe<Scalars['Int']>;
  numberOfCollaborations?: Maybe<Scalars['Int']>;
  numberOfConfiguredAgents?: Maybe<Scalars['Int']>;
  numberOfLibraries?: Maybe<Scalars['Int']>;
  requestStatus?: Maybe<UserRequestsStatusOutput>;
  canaryInvitations?: Maybe<Array<Maybe<CanaryInvitationsOutput>>>;
  activeTimeInSeconds?: Maybe<Scalars['Float']>;
  numberOfLogins?: Maybe<Scalars['Int']>;
};

/** Graphql output object containing user activity details */
export type ActivityDetailOutput = {
  __typename?: 'ActivityDetailOutput';
  newRegistration?: Maybe<Array<Maybe<RegistrationOutput>>>;
  loginDetail?: Maybe<Array<Maybe<ActivityOutput>>>;
};

/** Graphql output object containing registration details */
export type RegistrationOutput = {
  __typename?: 'RegistrationOutput';
  timestamp?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
};

/** Graphql output object containing activity details */
export type ActivityOutput = {
  __typename?: 'ActivityOutput';
  timestamp?: Maybe<Scalars['DateTime']>;
  username?: Maybe<Scalars['String']>;
  durationMs?: Maybe<Scalars['Float']>;
};

/** Graphene output object for individual users' association to an asset. */
export type RegistryPageUserListOutput = {
  __typename?: 'RegistryPageUserListOutput';
  userList?: Maybe<Array<Maybe<UserSimpleOutput>>>;
  totalNumberOfUsers?: Maybe<Scalars['Int']>;
};

/** Filter field used for displaying the user stats. */
export enum UserRoleFilterField {
  None = 'NONE',
  Aggregator = 'AGGREGATOR',
  GridOperator = 'GRID_OPERATOR',
  GsyUser = 'GSY_USER',
  Admin = 'ADMIN',
  ExchangeOperator = 'EXCHANGE_OPERATOR',
}

/** Ordering field used for displaying the user stats. */
export enum UserStatsOrderField {
  Username = 'USERNAME',
  RequestStatus = 'REQUEST_STATUS',
  DateJoined = 'DATE_JOINED',
  UserRole = 'USER_ROLE',
}

/** Class representing the GraphQL output of Projects. */
export type ProjectOutput = {
  __typename?: 'ProjectOutput';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  private?: Maybe<Scalars['Boolean']>;
  locationVisible?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  configurations?: Maybe<Array<Maybe<ConfigurationOutput>>>;
};

/** Class representing the GraphQL output of Libraries. */
export type LibraryOutput = {
  __typename?: 'LibraryOutput';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  scenarioData?: Maybe<ScenarioLibraryOutput>;
  user?: Maybe<Scalars['String']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  libraryType?: Maybe<LibraryTypesGraphql>;
  numberOfUsages?: Maybe<Scalars['Int']>;
  numberOfUsers?: Maybe<Scalars['Int']>;
  numberOfUsagesUser?: Maybe<Scalars['Int']>;
  lastUsedByUser?: Maybe<Scalars['DateTime']>;
};

/** Class representing the GraphQL output for the scenario of a Library. */
export type ScenarioLibraryOutput = {
  __typename?: 'ScenarioLibraryOutput';
  representation?: Maybe<DeviceTypes>;
  deviceCount?: Maybe<Scalars['JSONString']>;
  deviceCountNumber?: Maybe<Scalars['Int']>;
  areaCountNumber?: Maybe<Scalars['Int']>;
};

/** Types of Libraries that are exposed in the graphql api */
export enum LibraryTypesGraphql {
  MyLibrary = 'MY_LIBRARY',
  GsyLibrary = 'GSY_LIBRARY',
  PublicLibrary = 'PUBLIC_LIBRARY',
}

/** Class representing the GraphQL output of list of Libraries. */
export type ListLibraryOutput = {
  __typename?: 'ListLibraryOutput';
  totalCount?: Maybe<Scalars['Int']>;
  libraries?: Maybe<Array<Maybe<LibraryOutput>>>;
};

/** Types of ordering algorithms for the listLibrary query */
export enum LibraryOrderAlgorithmEnum {
  CreationTime = 'CREATION_TIME',
  NumberOfUsagesAllUsers = 'NUMBER_OF_USAGES_ALL_USERS',
  NumberOfUsagesUser = 'NUMBER_OF_USAGES_USER',
  LastUsedByUser = 'LAST_USED_BY_USER',
}

/** Graphene object type for all simulation results types. */
export type SimulationResultsData = {
  __typename?: 'SimulationResultsData';
  savingsKpiProfile?: Maybe<Scalars['JSONString']>;
  aggregatedBills?: Maybe<Scalars['JSONString']>;
  latestSavingsKpi?: Maybe<Scalars['JSONString']>;
  cumulativeGridTrades?: Maybe<Scalars['JSONString']>;
  cumulativeNetEnergyFlow?: Maybe<Scalars['JSONString']>;
  bills?: Maybe<Scalars['JSONString']>;
  cumulativeBills?: Maybe<Scalars['JSONString']>;
  kpi?: Maybe<Scalars['JSONString']>;
  priceEnergyDay?: Maybe<Scalars['JSONString']>;
  deviceStatistics?: Maybe<Scalars['JSONString']>;
  tradeProfile?: Maybe<Scalars['JSONString']>;
  areaThroughput?: Maybe<Scalars['JSONString']>;
  tradeProfileAreaThroughput?: Maybe<Scalars['JSONString']>;
  marketSummary?: Maybe<Array<Maybe<MarketSummary>>>;
  bidsOffersTrades?: Maybe<Scalars['JSONString']>;
  currentMarket?: Maybe<Scalars['String']>;
  completedMarkets?: Maybe<Scalars['JSONString']>;
  status?: Maybe<Scalars['String']>;
  statusesEnum?: Maybe<SimulationStatusType>;
  progressInfo?: Maybe<ProgressInfo>;
  randomSeed?: Maybe<Scalars['String']>;
  assetsInfo?: Maybe<Scalars['JSONString']>;
  launchDatetime?: Maybe<Scalars['DateTime']>;
  simulationId: Scalars['String'];
  areaUuid?: Maybe<Scalars['String']>;
  unmatchedLoads?: Maybe<Scalars['JSONString']>;
};

/** Graphene object type for all simulation results types. */
export type SimulationResultsDataSavingsKpiProfileArgs = {
  resolution?: Maybe<DataAggregationResolution>;
};

/** Data aggregation resolution option. */
export enum DataAggregationResolution {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
}

/** Graphene object type for market summary. */
export type MarketSummary = {
  __typename?: 'MarketSummary';
  tradedVolume: Scalars['Float'];
  externalTradedVolume: Scalars['Float'];
  averageEnergyRate?: Maybe<Scalars['Float']>;
  timestamp: Scalars['DateTime'];
};

/** Graphene object type for profile based simulation results. */
export type ProfileSimulationResultsData = {
  __typename?: 'ProfileSimulationResultsData';
  priceEnergyDay?: Maybe<Scalars['JSONString']>;
  deviceStatistics?: Maybe<Scalars['JSONString']>;
  tradeProfile?: Maybe<Scalars['JSONString']>;
  areaThroughput?: Maybe<Scalars['JSONString']>;
  tradeProfileAreaThroughput?: Maybe<Scalars['JSONString']>;
  marketSummary?: Maybe<Array<Maybe<MarketSummary>>>;
  bidsOffersTrades?: Maybe<Scalars['JSONString']>;
};

/** Graphene object type for difference of accumulated results between two time stamps. */
export type DifferenceSimulationResultsData = {
  __typename?: 'DifferenceSimulationResultsData';
  aggregatedBills?: Maybe<Scalars['JSONString']>;
  latestSavingsKpi?: Maybe<Scalars['JSONString']>;
};

/** Graphene object type for savings KPI profile. */
export type SavingsKpiProfileResultsData = {
  __typename?: 'SavingsKpiProfileResultsData';
  savingsKpiProfile?: Maybe<Scalars['JSONString']>;
};

/** Graphene object type for savings KPI profile. */
export type SavingsKpiProfileResultsDataSavingsKpiProfileArgs = {
  resolution?: Maybe<DataAggregationResolution>;
};

export type UserOutput = {
  __typename?: 'UserOutput';
  username?: Maybe<Scalars['String']>;
  superuser?: Maybe<Scalars['Boolean']>;
  globalVisibility?: Maybe<Scalars['Boolean']>;
};

/** Encapsulates all mutations of gsy-web */
export type Mutations = {
  __typename?: 'Mutations';
  /** Mutation class for creating CommunityManagers */
  createCommunityManagers?: Maybe<Scalars['String']>;
  /** Mutation class for deleting CommunityManagers */
  deleteCommunityManagers?: Maybe<Scalars['String']>;
  /** Mutation to add a trade order for a B2B product. */
  addOrderB2b?: Maybe<Scalars['String']>;
  /** Send notification to community members. */
  sendCommunityNotification?: Maybe<Scalars['String']>;
  /** Set status of unread notifications to read. */
  setReadCommunityNotifications?: Maybe<Scalars['String']>;
  /** Mutation to create a new client for the B2B product. */
  createClientB2b?: Maybe<ClientOutput>;
  /** Mutation to update an existing client for the B2B product. */
  updateClientB2b?: Maybe<ClientOutput>;
  /** Mutation to delete a client of the B2B product. */
  deleteClientB2b?: Maybe<Scalars['String']>;
  /** Mutation to delete a client of the B2B product. */
  assignAssetToClientB2b?: Maybe<Scalars['String']>;
  /** Mutation to delete a client of the B2B product. */
  unassignAssetFromClientB2b?: Maybe<Scalars['String']>;
  userContactForm?: Maybe<Scalars['String']>;
  /** Create a new Canary Network from a Collaboration. */
  moveCollaborationToCanaryNetwork?: Maybe<ConfigurationOutput>;
  /** Create a new Collaboration from a Canary Network. */
  moveCanaryNetworkToCollaboration?: Maybe<ConfigurationOutput>;
  /** Create a Canary Network that is a clone/duplicate of an existing one. */
  duplicateCanaryNetwork?: Maybe<ConfigurationOutput>;
  setGlobalSimulationLocation?: Maybe<Scalars['String']>;
  toggleGlobalSimulationVisibility?: Maybe<SimulationLocationVisibilityOutput>;
  updateUserSimulationVisibility?: Maybe<UserSimulationLocationVisibility>;
  deleteGlobalSimulationLocation?: Maybe<Scalars['String']>;
  /** GraphQL API for deleting area during runtime of simulation */
  deleteAreaLiveEvent?: Maybe<LiveEventResponse>;
  /** GraphQL API for updating area during runtime of simulation */
  updateAreaLiveEvent?: Maybe<LiveEventResponse>;
  /** GraphQL API for adding area during runtime of simulation */
  createAreaLiveEvent?: Maybe<LiveEventResponse>;
  /** GraphQL API for adding areas in bulk during runtime of simulation */
  bulkAreaLiveEvent?: Maybe<BulkLiveEventOutput>;
  /** Create a new Collaboration by copying an existing configuration. */
  moveConfigurationToCollaboration?: Maybe<ConfigurationOutput>;
  /** Create a copy/duplicate of a Collaboration. */
  duplicateCollaboration?: Maybe<ConfigurationOutput>;
  /** Allow an area of the given configuration to have external connectivity. */
  registerExternalConnection?: Maybe<RegistrationResponse>;
  /** Register areas to be able to receive external connections in batch. */
  batchRegisterExternalConnection?: Maybe<RegistrationResponse>;
  /** Request the move of simulation to Canary Network. */
  requestSimulationToCnConversion?: Maybe<Scalars['String']>;
  /** Mutation for uploading an SCM community sheet. */
  uploadCommunityDatasheet?: Maybe<CommunityDatasheetOutput>;
  /** Create a new member of the community. */
  createCommunityMember?: Maybe<ScmCommunityMember>;
  /** Update the information of one community member. */
  updateCommunityMember?: Maybe<Scalars['String']>;
  /** Remove a member from community. */
  removeCommunityMember?: Maybe<Scalars['String']>;
  /** Handle toggling of forecast_stream_enabled flag in scenario representations of SCM CNs. */
  batchSetForecastStreamEnabledScm?: Maybe<Scalars['String']>;
  /** Handle sending invitation links to join a community for selected community members. */
  sendScmInvitationEmail?: Maybe<Scalars['String']>;
  /** Update the fees and tariffs of the whole community. */
  updateCommunityFeesTariffs?: Maybe<Scalars['String']>;
  /** Request verification of the received measurements for one or multiple assets. */
  requestMeasurementVerification?: Maybe<Scalars['String']>;
  /** Accept or reject open verifications for one or multiple assets. */
  setMeasurementVerificationStatus?: Maybe<Scalars['String']>;
  /** Revoke verification request of the received measurements for one or multiple assets. */
  revokeMeasurementVerification?: Maybe<Scalars['String']>;
  /**
   * Change grid related settings
   * This mutation is currently not used in the workflow, but could be helpful later.
   */
  gridConfiguration?: Maybe<ConfigurationOutput>;
  /**
   * Set up an external connection for multiple areas of a configuration. Available only to
   * the exchange operator.
   */
  exchangeOperatorConfiguration?: Maybe<ConfigurationOutput>;
  /** Respond to an external connection request / application. */
  respondExternalConnectionRequest?: Maybe<Scalars['String']>;
  /** Similar to RespondExternalConnectionRequest, only for multiple external connection requests. */
  batchResponseExternalConnectionRequest?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Mutation to set User Role. */
  setUserRole?: Maybe<UserRoleOutput>;
  /** Mutation to invite user to a Canary Network or a Collaboration. */
  setUserInviteToConfiguration?: Maybe<Scalars['String']>;
  /** Mutation to set external connection of user to an area of a Canary Network. */
  setExternalConnection?: Maybe<Scalars['String']>;
  /** Mutation to respond to user's request of conversion of their simulation to CN. */
  respondToSimulationToCnRequest?: Maybe<MoveSimulationToCnOutput>;
  /** Create a new project. */
  createProject?: Maybe<ProjectOutput>;
  /** Update an existing project. */
  updateProject?: Maybe<ProjectOutput>;
  /** Delete and existing project. */
  deleteProject?: Maybe<Scalars['String']>;
  /** Mutation to create a new library. */
  createLibrary?: Maybe<LibraryOutput>;
  /** Mutation to update an existing library. */
  updateLibrary?: Maybe<LibraryOutput>;
  /** Mutation that deletes a library. */
  deleteLibrary?: Maybe<Scalars['String']>;
  /** Mutation that duplicates and existing library. */
  duplicateLibrary?: Maybe<LibraryOutput>;
  /** Mutation for creating a configuration */
  createConfiguration?: Maybe<ConfigurationOutput>;
  /** Mutation for updating a configuration */
  updateConfiguration?: Maybe<ConfigurationOutput>;
  /** Mutation that updates one area of the configuration */
  updateConfigurationArea?: Maybe<ConfigurationOutput>;
  /** Mutation that removes an area from the configuration */
  removeConfigurationArea?: Maybe<ConfigurationOutput>;
  /** Mutation that creates one area in the configuration */
  createConfigurationArea?: Maybe<ConfigurationOutput>;
  /** Mutation that deletes a configuration */
  deleteConfiguration?: Maybe<Scalars['String']>;
  /** Mutation that partially updates the configuration scenario */
  partialUpdateConfiguration?: Maybe<ConfigurationOutput>;
  /** Mutation that duplicates a configuration */
  duplicateConfiguration?: Maybe<ConfigurationOutput>;
  /** Mutation for updating ot creating an area via live event. */
  createUpdateEvents?: Maybe<ConfigurationEvents>;
  /** Mutation for deleting an area via live event. */
  deleteEvents?: Maybe<Scalars['String']>;
  /** Mutation updates the community_area_uuids in the DB. */
  updateCommunityAreaUuids?: Maybe<ConfigurationOutput>;
  /** Mutation that duplicates a community.  */
  duplicateCommunity?: Maybe<ConfigurationOutput>;
  /** Mutation for pausing a simulation */
  pauseSimulation?: Maybe<Scalars['String']>;
  /** Mutation for resuming a simulation */
  resumeSimulation?: Maybe<Scalars['String']>;
  /** Mutation for stopping a simulation */
  stopSimulation?: Maybe<Scalars['String']>;
  /** Mutation for launching a simulation */
  launchSimulation?: Maybe<LaunchResponse>;
  /** Mutation for launching a simulation reusing the existing state from the DB */
  launchSimulationFromState?: Maybe<LaunchResponse>;
  tokenAuth?: Maybe<AuthToken>;
  /** User authentication method for the B2B exchange. */
  tokenAuthB2b?: Maybe<AuthToken>;
  socialAuth?: Maybe<AuthToken>;
  tokenVerify?: Maybe<Verify>;
  tokenRefresh?: Maybe<Refresh>;
  /** Register, save user's information and return an auth token upon successful operation. */
  registerUser?: Maybe<SignupResponse>;
  /**
   * Invite a new user to the website.
   *
   * The user is registered without password when the mutation is executed. An email with a password
   * reset link is then sent to the user to allow them to change their password.
   */
  inviteUserToWebsite?: Maybe<Scalars['String']>;
  confirmEmail?: Maybe<Scalars['String']>;
  confirmPasswordReset?: Maybe<Scalars['String']>;
  /** Mutation to update a user's details. */
  updateUser?: Maybe<UserInformationOutput>;
  changePaymentStatus?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateCommunityManagersArgs = {
  configUuid: Scalars['String'];
  usernames: Array<Maybe<Scalars['String']>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDeleteCommunityManagersArgs = {
  configUuid: Scalars['String'];
  usernames: Array<Maybe<Scalars['String']>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsAddOrderB2bArgs = {
  assetUuid: Scalars['String'];
  autoMode: Scalars['Boolean'];
  configUuid: Scalars['String'];
  consumptionVolumePercent: Scalars['Float'];
  endTime: Scalars['DateTime'];
  energyRateLimit: Scalars['Float'];
  productType: AvailableMarketTypes;
  startTime: Scalars['DateTime'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSendCommunityNotificationArgs = {
  configUuid: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  selectedMemberNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  uploadedFiles?: Maybe<Array<Maybe<NotificationFileUploadInput>>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSetReadCommunityNotificationsArgs = {
  notificationIDs?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateClientB2bArgs = {
  client: ClientInput;
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateClientB2bArgs = {
  client: ClientInput;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDeleteClientB2bArgs = {
  clientEmail: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsAssignAssetToClientB2bArgs = {
  assetUuid: Scalars['String'];
  clientEmail: Scalars['String'];
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUnassignAssetFromClientB2bArgs = {
  assetUuid: Scalars['String'];
  clientEmail: Scalars['String'];
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUserContactFormArgs = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsMoveCollaborationToCanaryNetworkArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  private?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsMoveCanaryNetworkToCollaborationArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  private?: Maybe<Scalars['Boolean']>;
  projectName?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDuplicateCanaryNetworkArgs = {
  description?: Maybe<Scalars['String']>;
  incorporateLiveEvents?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSetGlobalSimulationLocationArgs = {
  boundaryId: Scalars['String'];
  centreCoordinates?: Maybe<Position>;
  configUuid: Scalars['String'];
  visibility: Scalars['Boolean'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsToggleGlobalSimulationVisibilityArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateUserSimulationVisibilityArgs = {
  visibility: Scalars['Boolean'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDeleteGlobalSimulationLocationArgs = {
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDeleteAreaLiveEventArgs = {
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateAreaLiveEventArgs = {
  areaParams: AreaInput;
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateAreaLiveEventArgs = {
  areaRepresentation?: Maybe<AreaInput>;
  configUuid: Scalars['String'];
  library?: Maybe<LiveEventLibraryInput>;
  parentUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsBulkAreaLiveEventArgs = {
  configUuid: Scalars['String'];
  eventList?: Maybe<Array<Maybe<LiveEventInput>>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsMoveConfigurationToCollaborationArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDuplicateCollaborationArgs = {
  description?: Maybe<Scalars['String']>;
  incorporateLiveEvents?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRegisterExternalConnectionArgs = {
  apply: Scalars['Boolean'];
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsBatchRegisterExternalConnectionArgs = {
  applicationMapping?: Maybe<Array<Maybe<AreaUuidApplyMapping>>>;
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRequestSimulationToCnConversionArgs = {
  configUuid: Scalars['String'];
  timezone: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUploadCommunityDatasheetArgs = {
  configUuid: Scalars['String'];
  file: Scalars['Upload'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateCommunityMemberArgs = {
  address: Scalars['String'];
  assistanceMonthlyFee?: Maybe<Scalars['Float']>;
  configUuid: Scalars['String'];
  email: Scalars['String'];
  feedInTariff: Scalars['Float'];
  fixedMonthlyFee: Scalars['Float'];
  gridExportFeeConst?: Maybe<Scalars['Float']>;
  gridImportFeeConst: Scalars['Float'];
  marketMakerRate: Scalars['Float'];
  marketplaceMonthlyFee: Scalars['Float'];
  name: Scalars['String'];
  taxesSurcharges: Scalars['Float'];
  zipcode: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateCommunityMemberArgs = {
  address?: Maybe<Scalars['String']>;
  configUuid: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fees?: Maybe<CommunityFeeInput>;
  memberUuid: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRemoveCommunityMemberArgs = {
  configUuid: Scalars['String'];
  memberUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsBatchSetForecastStreamEnabledScmArgs = {
  areaUuidMapping: Array<Maybe<InputSetForecastStreamEnabledScm>>;
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSendScmInvitationEmailArgs = {
  configUuid: Scalars['String'];
  selectedMemberNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateCommunityFeesTariffsArgs = {
  assistanceMonthlyFee?: Maybe<Scalars['Float']>;
  configUuid: Scalars['String'];
  feedInTariff?: Maybe<Scalars['Float']>;
  fixedMonthlyFee?: Maybe<Scalars['Float']>;
  gridExportFeeConst?: Maybe<Scalars['Float']>;
  gridImportFeeConst?: Maybe<Scalars['Float']>;
  marketMakerRate?: Maybe<Scalars['Float']>;
  marketplaceFee?: Maybe<Scalars['Float']>;
  taxesSurcharges?: Maybe<Scalars['Float']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRequestMeasurementVerificationArgs = {
  areaUuids: Array<Maybe<Scalars['String']>>;
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSetMeasurementVerificationStatusArgs = {
  acceptedAreaUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
  configUuid: Scalars['String'];
  rejectedAreaUuids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRevokeMeasurementVerificationArgs = {
  areaUuids: Array<Maybe<Scalars['String']>>;
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsGridConfigurationArgs = {
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
  configurationSettings?: Maybe<GridSettings>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsExchangeOperatorConfigurationArgs = {
  configUuid: Scalars['String'];
  setExternalConnection?: Maybe<Array<Maybe<ConnectionDetails>>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRespondExternalConnectionRequestArgs = {
  accept: Scalars['Boolean'];
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
  username: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsBatchResponseExternalConnectionRequestArgs = {
  configUuid: Scalars['String'];
  responseList?: Maybe<Array<Maybe<InputRespondExternalConnectionRequest>>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSetUserRoleArgs = {
  email?: Maybe<Scalars['String']>;
  userRoleInt?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSetUserInviteToConfigurationArgs = {
  configUuid: Scalars['String'];
  inviteFlag: AdminInviteFlag;
  username: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSetExternalConnectionArgs = {
  areaUuid?: Maybe<Scalars['String']>;
  configUuid?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status?: Maybe<ExternalConnectionApprovalStatus>;
  username?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRespondToSimulationToCnRequestArgs = {
  action?: Maybe<UserRequestResponse>;
  requestId: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateProjectArgs = {
  centreCoordinates?: Maybe<Position>;
  description?: Maybe<Scalars['String']>;
  locationVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  private?: Maybe<Scalars['Boolean']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateProjectArgs = {
  centreCoordinates?: Maybe<Position>;
  description?: Maybe<Scalars['String']>;
  locationVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDeleteProjectArgs = {
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateLibraryArgs = {
  description?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  libraryType?: Maybe<LibraryTypesGraphql>;
  name?: Maybe<Scalars['String']>;
  scenarioData?: Maybe<ScenarioInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateLibraryArgs = {
  description?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  libraryType?: Maybe<LibraryTypesGraphql>;
  name?: Maybe<Scalars['String']>;
  scenarioData?: Maybe<ScenarioInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDeleteLibraryArgs = {
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDuplicateLibraryArgs = {
  isPrivate?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateConfigurationArgs = {
  centreCoordinates?: Maybe<Position>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectUUID: Scalars['String'];
  scenarioData?: Maybe<ScenarioInput>;
  settingsData?: Maybe<SettingsInput>;
  timezone?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateConfigurationArgs = {
  centreCoordinates?: Maybe<Position>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  projectUUID?: Maybe<Scalars['String']>;
  scenarioData?: Maybe<ScenarioInput>;
  settingsData?: Maybe<SettingsInput>;
  timezone?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateConfigurationAreaArgs = {
  areaParams: AreaInput;
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRemoveConfigurationAreaArgs = {
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateConfigurationAreaArgs = {
  areaRepresentation: AreaInput;
  configUuid: Scalars['String'];
  parentUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDeleteConfigurationArgs = {
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsPartialUpdateConfigurationArgs = {
  partialScenario: AreaInput;
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDuplicateConfigurationArgs = {
  description?: Maybe<Scalars['String']>;
  incorporateLiveEvents?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsCreateUpdateEventsArgs = {
  areaEvents?: Maybe<Array<Maybe<AreaEventsInput>>>;
  settingsEvents?: Maybe<Array<Maybe<SettingsEventInput>>>;
  uuid?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDeleteEventsArgs = {
  uuid?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateCommunityAreaUuidsArgs = {
  communityAreaUuids: Array<Maybe<Scalars['String']>>;
  configUuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsDuplicateCommunityArgs = {
  configUuid: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  incorporateLiveEvents?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsPauseSimulationArgs = {
  jobId: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsResumeSimulationArgs = {
  jobId: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsStopSimulationArgs = {
  jobId: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsLaunchSimulationArgs = {
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsLaunchSimulationFromStateArgs = {
  uuid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsTokenAuthArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsTokenAuthB2bArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsSocialAuthArgs = {
  accessToken: Scalars['String'];
  provider: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsTokenVerifyArgs = {
  token: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsTokenRefreshArgs = {
  token: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsRegisterUserArgs = {
  email: Scalars['String'];
  interest?: Maybe<Scalars['String']>;
  interestedInCn?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  organisation: Scalars['String'];
  password: Scalars['String'];
  passwordRepeat?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['Upload']>;
  username?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsInviteUserToWebsiteArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  userRole?: Maybe<UserRoleEnum>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsConfirmEmailArgs = {
  confirmKey?: Maybe<Scalars['String']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsConfirmPasswordResetArgs = {
  key: Scalars['String'];
  password: Scalars['String'];
  passwordRepeat: Scalars['String'];
  uid: Scalars['String'];
};

/** Encapsulates all mutations of gsy-web */
export type MutationsUpdateUserArgs = {
  interest?: Maybe<Scalars['String']>;
  interestedInCn?: Maybe<Scalars['Boolean']>;
  organisation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  passwordRepeat?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['Upload']>;
};

/** Encapsulates all mutations of gsy-web */
export type MutationsChangePaymentStatusArgs = {
  payingCustomer: Scalars['Boolean'];
  username: Scalars['String'];
};

/** GraphQL input of a single notification uploaded file. */
export type NotificationFileUploadInput = {
  /** Uploaded file associated with the notification. */
  file: Scalars['Upload'];
  /** Name of the uploaded file. */
  name: Scalars['String'];
};

/** Input for the client mutations for creating/updating a client. */
export type ClientInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  emailAddress: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

/** Input class for coordinates. */
export type Position = {
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
};

export type UserSimulationLocationVisibility = {
  __typename?: 'UserSimulationLocationVisibility';
  username?: Maybe<Scalars['String']>;
  visibility?: Maybe<Scalars['Boolean']>;
};

/** Response of the live event mutations. */
export type LiveEventResponse = {
  __typename?: 'LiveEventResponse';
  eventType?: Maybe<Scalars['String']>;
  parameters?: Maybe<Scalars['JSONString']>;
  timeStamp?: Maybe<Scalars['DateTime']>;
  diff?: Maybe<Scalars['JSONString']>;
  latestConfig?: Maybe<ConfigurationOutput>;
};

/** Class representing the GraphQL input for any area. */
export type AreaInput = {
  name?: Maybe<Scalars['String']>;
  gridFeePercentage?: Maybe<Scalars['Float']>;
  gridFeeConstant?: Maybe<Scalars['Float']>;
  importCapacityKva?: Maybe<Scalars['Float']>;
  exportCapacityKva?: Maybe<Scalars['Float']>;
  allowExternalConnection?: Maybe<Scalars['Boolean']>;
  forecastStreamEnabled?: Maybe<Scalars['Boolean']>;
  forecastStreamId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  libraryUUID?: Maybe<Scalars['String']>;
  children?: Maybe<Array<Maybe<AreaInput>>>;
  type?: Maybe<Scalars['String']>;
  baselinePeakEnergyImportKwh?: Maybe<Scalars['Float']>;
  baselinePeakEnergyExportKwh?: Maybe<Scalars['Float']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  fitAreaBoundary?: Maybe<Scalars['Boolean']>;
  fitToLimit?: Maybe<Scalars['Boolean']>;
  energyRateDecreasePerUpdate?: Maybe<Scalars['Float']>;
  energyRateIncreasePerUpdate?: Maybe<Scalars['Float']>;
  updateInterval?: Maybe<Scalars['Int']>;
  initialBuyingRate?: Maybe<Scalars['Float']>;
  finalBuyingRate?: Maybe<Scalars['Float']>;
  initialSellingRate?: Maybe<Scalars['Float']>;
  finalSellingRate?: Maybe<Scalars['Float']>;
  cloudCoverage?: Maybe<Scalars['Int']>;
  energyRate?: Maybe<Scalars['Float']>;
  maxAvailablePowerKw?: Maybe<Scalars['Float']>;
  avgPowerW?: Maybe<Scalars['Int']>;
  dailyLoadProfile?: Maybe<Scalars['Upload']>;
  dailyLoadProfileUuid?: Maybe<Scalars['String']>;
  panelCount?: Maybe<Scalars['Int']>;
  powerProfile?: Maybe<Scalars['Upload']>;
  powerProfileUuid?: Maybe<Scalars['String']>;
  maxPanelPowerW?: Maybe<Scalars['Float']>;
  capacityKw?: Maybe<Scalars['Float']>;
  azimuth?: Maybe<Scalars['Float']>;
  tilt?: Maybe<Scalars['Float']>;
  initialSoc?: Maybe<Scalars['Float']>;
  minAllowedSoc?: Maybe<Scalars['Float']>;
  batteryCapacityKwh?: Maybe<Scalars['Float']>;
  maxAbsBatteryPowerKw?: Maybe<Scalars['Float']>;
  capPriceStrategy?: Maybe<Scalars['Boolean']>;
  smartMeterProfile?: Maybe<Scalars['Upload']>;
  energyRateProfile?: Maybe<Scalars['Upload']>;
  energyRateProfileUuid?: Maybe<Scalars['String']>;
  gridConnected?: Maybe<Scalars['Boolean']>;
  useMarketMakerRate?: Maybe<Scalars['Boolean']>;
  energyBuyRate?: Maybe<Scalars['Float']>;
  buyingRateProfile?: Maybe<Scalars['Upload']>;
  maximumPowerRatingKw?: Maybe<Scalars['Float']>;
  minTempC?: Maybe<Scalars['Float']>;
  maxTempC?: Maybe<Scalars['Float']>;
  initialTempC?: Maybe<Scalars['Float']>;
  sourceTempCProfile?: Maybe<Scalars['Upload']>;
  sourceTempCProfileUuid?: Maybe<Scalars['String']>;
  tankVolumeL?: Maybe<Scalars['Float']>;
  consumptionKwhProfile?: Maybe<Scalars['Upload']>;
  consumptionKwhProfileUuid?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['Int']>;
  preferredBuyingRate?: Maybe<Scalars['Float']>;
  prosumptionKwhProfile?: Maybe<Scalars['Upload']>;
  prosumptionKwhProfileUuid?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

/** Define a library that needs to be imported as live event. */
export type LiveEventLibraryInput = {
  libraryUuid?: Maybe<Scalars['String']>;
  rootAreaName?: Maybe<Scalars['String']>;
  geoTagLocation?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

/** Output of the bulk live event mutation. */
export type BulkLiveEventOutput = {
  __typename?: 'BulkLiveEventOutput';
  latestConfig?: Maybe<ConfigurationOutput>;
  eventList?: Maybe<Array<Maybe<LiveEventListOutput>>>;
};

/** Define the live event parameters, part of a bulk live event. */
export type LiveEventInput = {
  eventType?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  parameters?: Maybe<AreaInput>;
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  response?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type AreaUuidApplyMapping = {
  areaUuid?: Maybe<Scalars['String']>;
  apply?: Maybe<Scalars['Boolean']>;
};

/** GraphQL output of Community Datasheet uploads. */
export type CommunityDatasheetOutput = {
  __typename?: 'CommunityDatasheetOutput';
  name?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  type?: Maybe<ConfigType>;
  cloudCoverageOptions?: Maybe<Scalars['JSONString']>;
  scenarioData?: Maybe<ScenarioOutput>;
  settingsData?: Maybe<SettingsOutput>;
  availableAreaTypes?: Maybe<AvailableAreaTypesOutput>;
  simulationResults?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  events?: Maybe<ConfigurationEvents>;
  timestamp?: Maybe<Scalars['DateTime']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  project?: Maybe<Project>;
  warnings?: Maybe<Scalars['String']>;
  areaSummary?: Maybe<ConfigurationDetails>;
  resultsStatus?: Maybe<Scalars['String']>;
  resultsProgressInfo?: Maybe<ProgressInfo>;
  private?: Maybe<Scalars['Boolean']>;
  deviceAggregatorMapping?: Maybe<Scalars['JSONString']>;
  coordinates?: Maybe<CoordinatesOutput>;
  /** Members included in the Community Datasheet */
  members?: Maybe<Array<Maybe<ScmCommunityMember>>>;
};

/** Input class for fees in the updateCommunityMemberMutation */
export type CommunityFeeInput = {
  taxesSurcharges?: Maybe<Scalars['Float']>;
  fixedMonthlyFee?: Maybe<Scalars['Float']>;
  marketplaceMonthlyFee?: Maybe<Scalars['Float']>;
  assistanceMonthlyFee?: Maybe<Scalars['Float']>;
  gridImportFeeConst?: Maybe<Scalars['Float']>;
  gridExportFeeConst?: Maybe<Scalars['Float']>;
};

export type InputSetForecastStreamEnabledScm = {
  areaUuid?: Maybe<Scalars['String']>;
  enableForecast?: Maybe<Scalars['Boolean']>;
};

export type GridSettings = {
  gridFeePercentage?: Maybe<Scalars['Float']>;
  gridFeeConstant?: Maybe<Scalars['Float']>;
  importCapacityKva?: Maybe<Scalars['Float']>;
  exportCapacityKva?: Maybe<Scalars['Float']>;
};

export type ConnectionDetails = {
  areaUuid: Scalars['String'];
  allowExternalConnection: Scalars['Boolean'];
};

export type InputRespondExternalConnectionRequest = {
  username?: Maybe<Scalars['String']>;
  areaUuid?: Maybe<Scalars['String']>;
  accept?: Maybe<Scalars['Boolean']>;
};

/** Admin can either invite or block user. */
export enum AdminInviteFlag {
  Invited = 'INVITED',
  Blocked = 'BLOCKED',
}

/** Admin can either approve or block users connection to an area. */
export enum ExternalConnectionApprovalStatus {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
}

/** Graphene output for moving simulation to Canary Network. */
export type MoveSimulationToCnOutput = {
  __typename?: 'MoveSimulationToCNOutput';
  configuration?: Maybe<ConfigurationOutput>;
  message?: Maybe<Scalars['String']>;
};

/** Response to the user requests to admin. */
export enum UserRequestResponse {
  Execute = 'EXECUTE',
  Reject = 'REJECT',
}

/** Class representing the GraphQL input for the scenario. */
export type ScenarioInput = {
  representation?: Maybe<AreaInput>;
};

/** Class representing the GraphQL input for the settings. */
export type SettingsInput = {
  durationDays?: Maybe<Scalars['Float']>;
  slotLengthMinutes?: Maybe<Scalars['Int']>;
  tickLengthSeconds?: Maybe<Scalars['Int']>;
  marketCount?: Maybe<Scalars['Int']>;
  slotLengthRealtimeSeconds?: Maybe<Scalars['Int']>;
  maxPanelPowerW?: Maybe<Scalars['Float']>;
  capacityKw?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  spotMarketType?: Maybe<SpotMarketType>;
  bidOfferMatchAlgo?: Maybe<BidOfferMatchAlgo>;
  advancedSettings?: Maybe<Scalars['String']>;
  randomSeed?: Maybe<Scalars['Int']>;
  externalConnectionEnabled?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currencies>;
  settlementMarketEnabled?: Maybe<Scalars['Boolean']>;
  relativeStdFromForecastPercent?: Maybe<Scalars['Float']>;
  scmCoefficientAlgorithm?: Maybe<ScmCoefficientAlgorithm>;
  scmGroupSettingsName?: Maybe<Scalars['String']>;
  scmCnHoursOfDelay?: Maybe<Scalars['Int']>;
};

export type AreaEventsInput = {
  areaUuid?: Maybe<Scalars['String']>;
  triggerEvents?: Maybe<Array<Maybe<AreaTriggerEventInput>>>;
};

export type AreaTriggerEventInput = {
  time?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EventType>;
};

export type SettingsEventInput = {
  time?: Maybe<Scalars['DateTime']>;
  marketMakerRate?: Maybe<Scalars['Int']>;
  marketMakerRateFile?: Maybe<Scalars['Upload']>;
  spotMarketType?: Maybe<SpotMarketType>;
  bidOfferMatchAlgo?: Maybe<BidOfferMatchAlgo>;
};

/** Response of the launch mutation */
export type LaunchResponse = {
  __typename?: 'LaunchResponse';
  job?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  configUUID?: Maybe<Scalars['String']>;
};

/** Hold the auth token and other related data needed upon successful authentication. */
export type AuthToken = {
  __typename?: 'AuthToken';
  username?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  userRole?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
};

export type Verify = {
  __typename?: 'Verify';
  payload?: Maybe<Scalars['GenericScalar']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  token?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['GenericScalar']>;
};

/** Graphql output returned upon sign up. */
export type SignupResponse = {
  __typename?: 'SignupResponse';
  response?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  authToken?: Maybe<AuthToken>;
};

/** Available User roles */
export enum UserRoleEnum {
  Admin = 'ADMIN',
  ExchangeOperator = 'EXCHANGE_OPERATOR',
  Dso = 'DSO',
  Researcher = 'RESEARCHER',
  Aggregator = 'AGGREGATOR',
}

/** Wrapper graphql output for part of the User model. */
export type UserInformationOutput = {
  __typename?: 'UserInformationOutput';
  organisation?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['String']>;
  interestedInCn?: Maybe<Scalars['Boolean']>;
  profilePicture?: Maybe<Scalars['String']>;
};

/** Subscription for the simulation results. */
export type Subscription = {
  __typename?: 'Subscription';
  simulationAreaResultsPartial?: Maybe<Array<Maybe<SimulationResultsData>>>;
  scmAccumulatedKpiDifferences?: Maybe<Scalars['JSONString']>;
  scmAccumulatedBillsDifferences?: Maybe<Scalars['JSONString']>;
  scmAccumulatedBillsDifferencesCommunity?: Maybe<Scalars['JSONString']>;
  communityNotifications?: Maybe<CommunityNotificationOutput>;
  scmAccumulatedResultsStats?: Maybe<AccumulatedSimulationResultsData>;
  scmProfileResults?: Maybe<ScmProfileResultsData>;
};

/** Subscription for the simulation results. */
export type SubscriptionSimulationAreaResultsPartialArgs = {
  jobId: Scalars['String'];
  areaUUID: Scalars['String'];
  timeResolutionHrs?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['DateTime']>;
};

/** Subscription for the simulation results. */
export type SubscriptionScmAccumulatedKpiDifferencesArgs = {
  jobId: Scalars['String'];
  areaUUID: Scalars['String'];
  startTime: Scalars['DateTime'];
};

/** Subscription for the simulation results. */
export type SubscriptionScmAccumulatedBillsDifferencesArgs = {
  jobId: Scalars['String'];
  areaUUID: Scalars['String'];
  startTime: Scalars['DateTime'];
};

/** Subscription for the simulation results. */
export type SubscriptionScmAccumulatedBillsDifferencesCommunityArgs = {
  jobId: Scalars['String'];
  areaUUID: Scalars['String'];
  startTime: Scalars['DateTime'];
};

/** Subscription for the simulation results. */
export type SubscriptionCommunityNotificationsArgs = {
  configUuid?: Maybe<Scalars['String']>;
};

/** Subscription for the simulation results. */
export type SubscriptionScmAccumulatedResultsStatsArgs = {
  jobId: Scalars['String'];
  areaUUID: Scalars['String'];
  startTime: Scalars['DateTime'];
};

/** Subscription for the simulation results. */
export type SubscriptionScmProfileResultsArgs = {
  jobId: Scalars['String'];
  areaUUID: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type AuthTokenFieldsFragment = { __typename?: 'AuthToken' } & Pick<
  AuthToken,
  'email' | 'isAdmin' | 'userRole' | 'token' | 'username' | 'profilePicture' | 'name'
> & { isSuperuser: AuthToken['isAdmin'] };

export type ConfigurationFieldsFragment = { __typename?: 'ConfigurationOutput' } & Pick<
  ConfigurationOutput,
  | 'uuid'
  | 'name'
  | 'description'
  | 'readOnly'
  | 'simulationResults'
  | 'resultsStatus'
  | 'timestamp'
  | 'timezone'
  | 'warnings'
  | 'user'
  | 'private'
> & { configType: ConfigurationOutput['type'] } & {
    areaSummary?: Maybe<
      { __typename?: 'ConfigurationDetails' } & Pick<
        ConfigurationDetails,
        'configuredAreas' | 'configuredDevices'
      >
    >;
    scenarioData?: Maybe<
      { __typename?: 'ScenarioOutput' } & {
        latest?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized' | 'uuid'>>;
        homeInfo?: Maybe<
          { __typename?: 'HomeInformationOutput' } & Pick<HomeInformationOutput, 'homeUuids'> & {
              energyBills?: Maybe<
                Array<
                  Maybe<{ __typename?: 'HomeBillOutput' } & Pick<HomeBillOutput, 'uuid' | 'bill'>>
                >
              >;
              scmHomeDetails?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'SCMCommunityMember' } & Pick<
                      ScmCommunityMember,
                      | 'address'
                      | 'assetCount'
                      | 'coefficientPercentage'
                      | 'email'
                      | 'feedInTariff'
                      | 'fixedMonthlyFee'
                      | 'gridImportFeeConst'
                      | 'marketMakerRate'
                      | 'marketplaceMonthlyFee'
                      | 'assistanceMonthlyFee'
                      | 'name'
                      | 'taxesSurcharges'
                      | 'uuid'
                      | 'zipCode'
                    >
                  >
                >
              >;
            }
        >;
      }
    >;
    settingsData?: Maybe<{ __typename?: 'SettingsOutput' } & SettingsDataFieldsFragment>;
    project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid' | 'name' | 'user'>>;
    coordinates?: Maybe<
      { __typename?: 'CoordinatesOutput' } & Pick<CoordinatesOutput, 'longitude' | 'latitude'>
    >;
  };

export type SettingsDataFieldsFragment = { __typename?: 'SettingsOutput' } & Pick<
  SettingsOutput,
  | 'slotLengthMinutes'
  | 'tickLengthSeconds'
  | 'marketCount'
  | 'slotLengthRealtimeSeconds'
  | 'startDate'
  | 'endDate'
  | 'endDateTime'
  | 'spotMarketType'
  | 'bidOfferMatchAlgo'
  | 'currency'
  | 'scmCoefficientAlgorithm'
  | 'scmGroupSettingsName'
>;

export type ConfirmEmailMutationVariables = Exact<{
  confirmKey: Scalars['String'];
}>;

export type ConfirmEmailMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'confirmEmail'>;

export type ConfirmPasswordResetMutationVariables = Exact<{
  uid: Scalars['String'];
  key: Scalars['String'];
  password: Scalars['String'];
  passwordRepeat: Scalars['String'];
}>;

export type ConfirmPasswordResetMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'confirmPasswordReset'
>;

export type InviteUserToWebsiteMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  userRole?: Maybe<UserRoleEnum>;
}>;

export type InviteUserToWebsiteMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'inviteUserToWebsite'
>;

export type SetUserRoleMutationVariables = Exact<{
  email: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  role: Scalars['Int'];
}>;

export type SetUserRoleMutation = { __typename?: 'Mutations' } & {
  setUserRole?: Maybe<
    { __typename?: 'UserRoleOutput' } & Pick<UserRoleOutput, 'roleName' | 'value'>
  >;
};

export type SignupMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  organisation: Scalars['String'];
  password: Scalars['String'];
  passwordRepeat?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  interest?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['Upload']>;
}>;

export type SignupMutation = { __typename?: 'Mutations' } & {
  registerUser?: Maybe<
    { __typename?: 'SignupResponse' } & Pick<SignupResponse, 'response' | 'message'> & {
        authToken?: Maybe<{ __typename?: 'AuthToken' } & AuthTokenFieldsFragment>;
      }
  >;
};

export type SocialAuthMutationVariables = Exact<{
  accessToken: Scalars['String'];
  provider: Scalars['String'];
}>;

export type SocialAuthMutation = { __typename?: 'Mutations' } & {
  socialAuth?: Maybe<{ __typename?: 'AuthToken' } & AuthTokenFieldsFragment>;
};

export type TokenAuthMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type TokenAuthMutation = { __typename?: 'Mutations' } & {
  tokenAuth?: Maybe<{ __typename?: 'AuthToken' } & AuthTokenFieldsFragment>;
};

export type TokenRefreshMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type TokenRefreshMutation = { __typename?: 'Mutations' } & {
  tokenRefresh?: Maybe<{ __typename?: 'Refresh' } & Pick<Refresh, 'token'>>;
};

export type TokenVerifyMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type TokenVerifyMutation = { __typename?: 'Mutations' } & {
  tokenVerify?: Maybe<{ __typename?: 'Verify' } & Pick<Verify, 'payload'>>;
};

export type UpdateUserMutationVariables = Exact<{
  interest?: Maybe<Scalars['String']>;
  interestedInCn?: Maybe<Scalars['Boolean']>;
  organisation?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  passwordRepeat?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['Upload']>;
}>;

export type UpdateUserMutation = { __typename?: 'Mutations' } & {
  updateUser?: Maybe<
    { __typename?: 'UserInformationOutput' } & Pick<
      UserInformationOutput,
      'organisation' | 'interest' | 'interestedInCn' | 'profilePicture'
    >
  >;
};

export type BatchRegisterExternalConnectionMutationVariables = Exact<{
  applicationMapping?: Maybe<Array<Maybe<AreaUuidApplyMapping>> | Maybe<AreaUuidApplyMapping>>;
  configUuid: Scalars['String'];
}>;

export type BatchRegisterExternalConnectionMutation = { __typename?: 'Mutations' } & {
  batchRegisterExternalConnection?: Maybe<
    { __typename?: 'RegistrationResponse' } & Pick<RegistrationResponse, 'response' | 'message'>
  >;
};

export type DuplicateCanaryNetworkMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  uuid: Scalars['String'];
  incorporateLiveEvents?: Maybe<Scalars['Boolean']>;
}>;

export type DuplicateCanaryNetworkMutation = { __typename?: 'Mutations' } & {
  duplicateCanaryNetwork?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<
      ConfigurationOutput,
      'name' | 'uuid' | 'description' | 'user' | 'simulationResults'
    > & { configType: ConfigurationOutput['type'] } & {
        project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
        scenarioData?: Maybe<
          { __typename?: 'ScenarioOutput' } & {
            representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
          }
        >;
      }
  >;
};

export type RequestMeasurementVerificationMutationVariables = Exact<{
  configUuid: Scalars['String'];
  areaUuids: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type RequestMeasurementVerificationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'requestMeasurementVerification'
>;

export type RequestSimulationToCnConversionMutationVariables = Exact<{
  configUuid: Scalars['String'];
  timezone: Scalars['String'];
}>;

export type RequestSimulationToCnConversionMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'requestSimulationToCnConversion'
>;

export type RespondToSimulationToCnRequestMutationVariables = Exact<{
  action?: Maybe<UserRequestResponse>;
  requestId: Scalars['String'];
}>;

export type RespondToSimulationToCnRequestMutation = { __typename?: 'Mutations' } & {
  respondToSimulationToCnRequest?: Maybe<
    { __typename?: 'MoveSimulationToCNOutput' } & Pick<MoveSimulationToCnOutput, 'message'> & {
        configuration?: Maybe<
          { __typename?: 'ConfigurationOutput' } & Pick<ConfigurationOutput, 'uuid'>
        >;
      }
  >;
};

export type RevokeMeasurementVerificationMutationVariables = Exact<{
  configUuid: Scalars['String'];
  areaUuids: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type RevokeMeasurementVerificationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'revokeMeasurementVerification'
>;

export type SetExternalConnectionMutationVariables = Exact<{
  configUuid: Scalars['String'];
  areaUuid: Scalars['String'];
  email: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  status: ExternalConnectionApprovalStatus;
}>;

export type SetExternalConnectionMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'setExternalConnection'
>;

export type SetMeasurementVerificationStatusMutationVariables = Exact<{
  acceptedAreaUuids: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  configUuid: Scalars['String'];
  rejectedAreaUuids: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type SetMeasurementVerificationStatusMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'setMeasurementVerificationStatus'
>;

export type SetUserInviteMutationVariables = Exact<{
  configUuid: Scalars['String'];
  username: Scalars['String'];
  inviteFlag: AdminInviteFlag;
}>;

export type SetUserInviteMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'setUserInviteToConfiguration'
>;

export type BatchResponseExternalConnectionRequestMutationVariables = Exact<{
  configUuid: Scalars['String'];
  responseList?: Maybe<
    | Array<Maybe<InputRespondExternalConnectionRequest>>
    | Maybe<InputRespondExternalConnectionRequest>
  >;
}>;

export type BatchResponseExternalConnectionRequestMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'batchResponseExternalConnectionRequest'
>;

export type CreateCanaryNetworkMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  private?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['String'];
}>;

export type CreateCanaryNetworkMutation = { __typename?: 'Mutations' } & {
  moveCollaborationToCanaryNetwork?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<ConfigurationOutput, 'name' | 'uuid' | 'type'>
  >;
};

export type DuplicateCollaborationMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  uuid: Scalars['String'];
  incorporateLiveEvents?: Maybe<Scalars['Boolean']>;
}>;

export type DuplicateCollaborationMutation = { __typename?: 'Mutations' } & {
  duplicateCollaboration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<
      ConfigurationOutput,
      'name' | 'uuid' | 'description' | 'user' | 'simulationResults'
    > & { configType: ConfigurationOutput['type'] } & {
        project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
        scenarioData?: Maybe<
          { __typename?: 'ScenarioOutput' } & {
            representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
          }
        >;
      }
  >;
};

export type MoveCanaryNetworkToCollaborationMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
}>;

export type MoveCanaryNetworkToCollaborationMutation = { __typename?: 'Mutations' } & {
  moveCanaryNetworkToCollaboration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<ConfigurationOutput, 'uuid'> & {
        project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
      }
  >;
};

export type RegExternalConnectionMutationVariables = Exact<{
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
  apply: Scalars['Boolean'];
}>;

export type RegExternalConnectionMutation = { __typename?: 'Mutations' } & {
  registerExternalConnection?: Maybe<
    { __typename?: 'RegistrationResponse' } & Pick<RegistrationResponse, 'response' | 'message'>
  >;
};

export type RespondExternalConnectionRequestMutationVariables = Exact<{
  username: Scalars['String'];
  areaUuid: Scalars['String'];
  configUuid: Scalars['String'];
  accept: Scalars['Boolean'];
}>;

export type RespondExternalConnectionRequestMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'respondExternalConnectionRequest'
>;

export type SendScmInvitationEmailMutationVariables = Exact<{
  configUuid: Scalars['String'];
  selectedMemberNames?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type SendScmInvitationEmailMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'sendScmInvitationEmail'
>;

export type UploadCommunityDatasheetMutationVariables = Exact<{
  configUuid: Scalars['String'];
  file: Scalars['Upload'];
}>;

export type UploadCommunityDatasheetMutation = { __typename?: 'Mutations' } & {
  uploadCommunityDatasheet?: Maybe<
    { __typename?: 'CommunityDatasheetOutput' } & Pick<
      CommunityDatasheetOutput,
      | 'name'
      | 'uuid'
      | 'cloudCoverageOptions'
      | 'simulationResults'
      | 'description'
      | 'timezone'
      | 'user'
      | 'timestamp'
      | 'readOnly'
      | 'warnings'
      | 'resultsStatus'
      | 'private'
      | 'deviceAggregatorMapping'
    > & { configType: CommunityDatasheetOutput['type'] } & {
        scenarioData?: Maybe<
          { __typename?: 'ScenarioOutput' } & {
            representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
            latest?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
            homeInfo?: Maybe<
              { __typename?: 'HomeInformationOutput' } & Pick<
                HomeInformationOutput,
                'homeUuids'
              > & {
                  energyBills?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'HomeBillOutput' } & Pick<HomeBillOutput, 'uuid' | 'bill'>
                      >
                    >
                  >;
                  scmHomeDetails?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'SCMCommunityMember' } & Pick<
                          ScmCommunityMember,
                          | 'address'
                          | 'assetCount'
                          | 'coefficientPercentage'
                          | 'email'
                          | 'feedInTariff'
                          | 'fixedMonthlyFee'
                          | 'gridImportFeeConst'
                          | 'marketMakerRate'
                          | 'marketplaceMonthlyFee'
                          | 'assistanceMonthlyFee'
                          | 'name'
                          | 'taxesSurcharges'
                          | 'uuid'
                          | 'zipCode'
                        >
                      >
                    >
                  >;
                }
            >;
          }
        >;
        settingsData?: Maybe<{ __typename?: 'SettingsOutput' } & SettingsDataFieldsFragment>;
        events?: Maybe<
          { __typename?: 'ConfigurationEvents' } & Pick<
            ConfigurationEvents,
            'configurationUuid'
          > & {
              areaEvents?: Maybe<
                Array<Maybe<{ __typename?: 'AreaEvents' } & Pick<AreaEvents, 'areaUuid'>>>
              >;
              settingsEvents?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'SettingsEventOutput' } & Pick<
                      SettingsEventOutput,
                      | 'time'
                      | 'marketMakerRate'
                      | 'marketMakerRateFile'
                      | 'spotMarketType'
                      | 'bidOfferMatchAlgo'
                    >
                  >
                >
              >;
            }
        >;
        project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid' | 'name' | 'user'>>;
        areaSummary?: Maybe<
          { __typename?: 'ConfigurationDetails' } & Pick<
            ConfigurationDetails,
            'configuredAreas' | 'configuredDevices'
          >
        >;
        resultsProgressInfo?: Maybe<
          { __typename?: 'ProgressInfo' } & Pick<ProgressInfo, 'percentageCompleted'>
        >;
        coordinates?: Maybe<
          { __typename?: 'CoordinatesOutput' } & Pick<CoordinatesOutput, 'latitude' | 'longitude'>
        >;
        members?: Maybe<
          Array<
            Maybe<
              { __typename?: 'SCMCommunityMember' } & Pick<
                ScmCommunityMember,
                'name' | 'email' | 'address'
              >
            >
          >
        >;
      }
  >;
};

export type CreateConfigurationMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  projectUuid: Scalars['String'];
  settingsData?: Maybe<SettingsInput>;
  scenarioData?: Maybe<ScenarioInput>;
  centreCoordinates?: Maybe<Position>;
  timezone?: Maybe<Scalars['String']>;
}>;

export type CreateConfigurationMutation = { __typename?: 'Mutations' } & {
  createConfiguration?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
};

export type CreateConfigurationAreaMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  parentUuid: Scalars['String'];
  areaRepresentation: AreaInput;
}>;

export type CreateConfigurationAreaMutation = { __typename?: 'Mutations' } & {
  createConfigurationArea?: Maybe<
    { __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment
  >;
};

export type DeleteConfigurationMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
}>;

export type DeleteConfigurationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'deleteConfiguration'
>;

export type DuplicateCommunityMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  configurationUuid: Scalars['String'];
  incorporateLiveEvents?: Maybe<Scalars['Boolean']>;
}>;

export type DuplicateCommunityMutation = { __typename?: 'Mutations' } & {
  duplicateCommunity?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<
      ConfigurationOutput,
      'name' | 'uuid' | 'description' | 'user' | 'simulationResults'
    > & {
        project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
        scenarioData?: Maybe<
          { __typename?: 'ScenarioOutput' } & {
            representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
          }
        >;
      }
  >;
};

export type DuplicateConfigurationMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
  configurationUuid: Scalars['String'];
  incorporateLiveEvents?: Maybe<Scalars['Boolean']>;
}>;

export type DuplicateConfigurationMutation = { __typename?: 'Mutations' } & {
  duplicateConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<
      ConfigurationOutput,
      'name' | 'uuid' | 'description' | 'user' | 'simulationResults'
    > & {
        project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
        scenarioData?: Maybe<
          { __typename?: 'ScenarioOutput' } & {
            representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
          }
        >;
      }
  >;
};

export type MoveConfigurationToCollaborationMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;

export type MoveConfigurationToCollaborationMutation = { __typename?: 'Mutations' } & {
  moveConfigurationToCollaboration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<
      ConfigurationOutput,
      | 'name'
      | 'uuid'
      | 'simulationResults'
      | 'description'
      | 'user'
      | 'timestamp'
      | 'readOnly'
      | 'warnings'
    > & { configType: ConfigurationOutput['type'] } & {
        project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
        scenarioData?: Maybe<
          { __typename?: 'ScenarioOutput' } & {
            representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
          }
        >;
      }
  >;
};

export type PartialUpdateConfigurationMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  partialScenario: AreaInput;
}>;

export type PartialUpdateConfigurationMutation = { __typename?: 'Mutations' } & {
  partialUpdateConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment
  >;
};

export type RemoveConfigurationAreaMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  areaUuid: Scalars['String'];
}>;

export type RemoveConfigurationAreaMutation = { __typename?: 'Mutations' } & {
  removeConfigurationArea?: Maybe<
    { __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment
  >;
};

export type UpdateConfigurationMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  configurationUuid: Scalars['String'];
  projectUuid: Scalars['String'];
  settingsData?: Maybe<SettingsInput>;
  scenarioData?: Maybe<ScenarioInput>;
  timezone?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateConfigurationMutation = { __typename?: 'Mutations' } & {
  updateConfiguration?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
};

export type UpdateConfigurationAreaMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  areaUuid: Scalars['String'];
  areaParams: AreaInput;
}>;

export type UpdateConfigurationAreaMutation = { __typename?: 'Mutations' } & {
  updateConfigurationArea?: Maybe<
    { __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment
  >;
};

export type UpdateConfigurationTreeMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  scenarioData: ScenarioInput;
}>;

export type UpdateConfigurationTreeMutation = { __typename?: 'Mutations' } & {
  updateConfiguration?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
};

export type UserContactFormMutationVariables = Exact<{
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
}>;

export type UserContactFormMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'userContactForm'
>;

export type DeleteGlobalSimulationLocationMutationVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type DeleteGlobalSimulationLocationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'deleteGlobalSimulationLocation'
>;

export type SetGlobalSimulationLocationMutationVariables = Exact<{
  configUuid: Scalars['String'];
  centreCoordinates?: Maybe<Position>;
  boundaryId: Scalars['String'];
  visibility: Scalars['Boolean'];
}>;

export type SetGlobalSimulationLocationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'setGlobalSimulationLocation'
>;

export type ToggleGlobalSimulationVisibilityMutationVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type ToggleGlobalSimulationVisibilityMutation = { __typename?: 'Mutations' } & {
  toggleGlobalSimulationVisibility?: Maybe<
    { __typename?: 'SimulationLocationVisibilityOutput' } & Pick<
      SimulationLocationVisibilityOutput,
      'configUuid' | 'boundaryId' | 'visibility'
    > & { configType: SimulationLocationVisibilityOutput['type'] }
  >;
};

export type UpdateUserSimulationVisibilityMutationVariables = Exact<{
  visibility: Scalars['Boolean'];
}>;

export type UpdateUserSimulationVisibilityMutation = { __typename?: 'Mutations' } & {
  updateUserSimulationVisibility?: Maybe<
    { __typename?: 'UserSimulationLocationVisibility' } & Pick<
      UserSimulationLocationVisibility,
      'username' | 'visibility'
    >
  >;
};

export type CreateLibraryMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  scenarioData?: Maybe<ScenarioInput>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type CreateLibraryMutation = { __typename?: 'Mutations' } & {
  createLibrary?: Maybe<{ __typename?: 'LibraryOutput' } & Pick<LibraryOutput, 'uuid'>>;
};

export type DeleteLibraryMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type DeleteLibraryMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'deleteLibrary'>;

export type DuplicateLibraryMutationVariables = Exact<{
  uuid: Scalars['String'];
  isPrivate: Scalars['Boolean'];
}>;

export type DuplicateLibraryMutation = { __typename?: 'Mutations' } & {
  duplicateLibrary?: Maybe<
    { __typename?: 'LibraryOutput' } & Pick<
      LibraryOutput,
      'name' | 'uuid' | 'description' | 'user' | 'private' | 'readOnly' | 'tags' | 'libraryType'
    > & {
        scenarioData?: Maybe<
          { __typename?: 'ScenarioLibraryOutput' } & Pick<
            ScenarioLibraryOutput,
            'deviceCountNumber' | 'areaCountNumber'
          > & {
              representation?: Maybe<
                | ({ __typename?: 'PV' } & Pick<Pv, 'serialized' | 'type' | 'uuid'>)
                | ({ __typename?: 'Storage' } & Pick<Storage, 'serialized' | 'type' | 'uuid'>)
                | ({ __typename?: 'Load' } & Pick<Load, 'serialized' | 'type' | 'uuid'>)
                | ({ __typename?: 'SmartMeter' } & Pick<SmartMeter, 'serialized' | 'type' | 'uuid'>)
                | ({ __typename?: 'FiniteDieselGenerator' } & Pick<
                    FiniteDieselGenerator,
                    'serialized' | 'type' | 'uuid'
                  >)
                | ({ __typename?: 'MarketMaker' } & Pick<
                    MarketMaker,
                    'serialized' | 'type' | 'uuid'
                  >)
                | ({ __typename?: 'InfiniteBus' } & Pick<
                    InfiniteBus,
                    'serialized' | 'type' | 'uuid'
                  >)
                | { __typename?: 'WindTurbine' }
                | { __typename?: 'HeatPump' }
                | { __typename?: 'ScmStorage' }
                | { __typename?: 'ScmHeatPump' }
                | ({ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized' | 'type' | 'uuid'>)
              >;
            }
        >;
      }
  >;
};

export type PostDuplicationLibraryMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;

export type PostDuplicationLibraryMutation = { __typename?: 'Mutations' } & {
  updateLibrary?: Maybe<
    { __typename?: 'LibraryOutput' } & Pick<LibraryOutput, 'uuid' | 'name' | 'description'>
  >;
};

export type UpdateLibraryMutationVariables = Exact<{
  uuid: Scalars['String'];
  scenarioData: ScenarioInput;
}>;

export type UpdateLibraryMutation = { __typename?: 'Mutations' } & {
  updateLibrary?: Maybe<
    { __typename?: 'LibraryOutput' } & Pick<LibraryOutput, 'name' | 'readOnly'> & {
        scenarioData?: Maybe<
          { __typename?: 'ScenarioLibraryOutput' } & Pick<
            ScenarioLibraryOutput,
            'deviceCountNumber' | 'areaCountNumber'
          > & {
              representation?: Maybe<
                | ({ __typename?: 'PV' } & Pick<Pv, 'serialized'>)
                | ({ __typename?: 'Storage' } & Pick<Storage, 'serialized'>)
                | ({ __typename?: 'Load' } & Pick<Load, 'serialized'>)
                | { __typename?: 'SmartMeter' }
                | ({ __typename?: 'FiniteDieselGenerator' } & Pick<
                    FiniteDieselGenerator,
                    'serialized'
                  >)
                | ({ __typename?: 'MarketMaker' } & Pick<MarketMaker, 'serialized'>)
                | ({ __typename?: 'InfiniteBus' } & Pick<InfiniteBus, 'serialized'>)
                | { __typename?: 'WindTurbine' }
                | { __typename?: 'HeatPump' }
                | { __typename?: 'ScmStorage' }
                | { __typename?: 'ScmHeatPump' }
                | ({ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>)
              >;
            }
        >;
      }
  >;
};

export type UpdateLibraryDataMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;

export type UpdateLibraryDataMutation = { __typename?: 'Mutations' } & {
  updateLibrary?: Maybe<
    { __typename?: 'LibraryOutput' } & Pick<LibraryOutput, 'uuid' | 'name' | 'description'>
  >;
};

export type BulkAreaLiveEventMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  eventList?: Maybe<Array<Maybe<LiveEventInput>> | Maybe<LiveEventInput>>;
}>;

export type BulkAreaLiveEventMutation = { __typename?: 'Mutations' } & {
  bulkAreaLiveEvent?: Maybe<
    { __typename?: 'BulkLiveEventOutput' } & {
      latestConfig?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
      eventList?: Maybe<
        Array<
          Maybe<
            { __typename?: 'LiveEventListOutput' } & Pick<
              LiveEventListOutput,
              'eventType' | 'parameters' | 'timeStamp' | 'diff'
            >
          >
        >
      >;
    }
  >;
};

export type CreateAreaLiveEventMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  parentUuid: Scalars['String'];
  areaRepresentation?: Maybe<AreaInput>;
  library?: Maybe<LiveEventLibraryInput>;
}>;

export type CreateAreaLiveEventMutation = { __typename?: 'Mutations' } & {
  createAreaLiveEvent?: Maybe<
    { __typename?: 'LiveEventResponse' } & {
      latestConfig?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
    }
  >;
};

export type DeleteAreaLiveEventMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  areaUuid: Scalars['String'];
}>;

export type DeleteAreaLiveEventMutation = { __typename?: 'Mutations' } & {
  deleteAreaLiveEvent?: Maybe<
    { __typename?: 'LiveEventResponse' } & {
      latestConfig?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
    }
  >;
};

export type UpdateAreaLiveEventMutationVariables = Exact<{
  configurationUuid: Scalars['String'];
  areaUuid: Scalars['String'];
  areaParams: AreaInput;
}>;

export type UpdateAreaLiveEventMutation = { __typename?: 'Mutations' } & {
  updateAreaLiveEvent?: Maybe<
    { __typename?: 'LiveEventResponse' } & {
      latestConfig?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
    }
  >;
};

export type SendCommunityNotificationMutationVariables = Exact<{
  configUuid: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  selectedMemberNames?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  uploadedFiles?: Maybe<
    Array<Maybe<NotificationFileUploadInput>> | Maybe<NotificationFileUploadInput>
  >;
}>;

export type SendCommunityNotificationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'sendCommunityNotification'
>;

export type CreateProjectMutationVariables = Exact<{
  centreCoordinates?: Maybe<Position>;
  description?: Maybe<Scalars['String']>;
  locationVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  private?: Maybe<Scalars['Boolean']>;
}>;

export type CreateProjectMutation = { __typename?: 'Mutations' } & {
  createProject?: Maybe<
    { __typename?: 'ProjectOutput' } & Pick<
      ProjectOutput,
      'name' | 'uuid' | 'description' | 'private' | 'user'
    > & {
        configurations?: Maybe<
          Array<Maybe<{ __typename?: 'ConfigurationOutput' } & Pick<ConfigurationOutput, 'uuid'>>>
        >;
      }
  >;
};

export type DeleteProjectMutationVariables = Exact<{
  projectUuid: Scalars['String'];
}>;

export type DeleteProjectMutation = { __typename?: 'Mutations' } & Pick<Mutations, 'deleteProject'>;

export type UpdateProjectMutationVariables = Exact<{
  projectUuid: Scalars['String'];
  centreCoordinates?: Maybe<Position>;
  description?: Maybe<Scalars['String']>;
  locationVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
}>;

export type UpdateProjectMutation = { __typename?: 'Mutations' } & {
  updateProject?: Maybe<
    { __typename?: 'ProjectOutput' } & Pick<
      ProjectOutput,
      'name' | 'uuid' | 'user' | 'timestamp' | 'private' | 'latitude' | 'longitude'
    >
  >;
};

export type LaunchSimulationMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type LaunchSimulationMutation = { __typename?: 'Mutations' } & {
  launchSimulation?: Maybe<
    { __typename?: 'LaunchResponse' } & Pick<LaunchResponse, 'job' | 'status' | 'configUUID'>
  >;
};

export type PauseSimulationMutationVariables = Exact<{
  jobId: Scalars['String'];
}>;

export type PauseSimulationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'pauseSimulation'
>;

export type ResumeSimulationMutationVariables = Exact<{
  jobId: Scalars['String'];
}>;

export type ResumeSimulationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'resumeSimulation'
>;

export type StopSimulationMutationVariables = Exact<{
  jobId: Scalars['String'];
}>;

export type StopSimulationMutation = { __typename?: 'Mutations' } & Pick<
  Mutations,
  'stopSimulation'
>;

export type AvailableRolesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableRolesQuery = { __typename?: 'Queries' } & {
  availableUserRoles?: Maybe<
    { __typename?: 'AvailableUserRolesOutput' } & {
      userRoles?: Maybe<
        Array<Maybe<{ __typename?: 'UserRoleOutput' } & Pick<UserRoleOutput, 'roleName' | 'value'>>>
      >;
    }
  >;
};

export type ListAdminStatsQueryVariables = Exact<{
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  emailSearch?: Maybe<Scalars['String']>;
  userRoles?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type ListAdminStatsQuery = { __typename?: 'Queries' } & {
  listAdminStats?: Maybe<
    { __typename?: 'AdminStats' } & {
      userActivity?: Maybe<
        { __typename?: 'ActivityDetailOutput' } & {
          newRegistration?: Maybe<
            Array<
              Maybe<
                { __typename?: 'RegistrationOutput' } & Pick<
                  RegistrationOutput,
                  'username' | 'timestamp'
                >
              >
            >
          >;
          loginDetail?: Maybe<
            Array<
              Maybe<
                { __typename?: 'ActivityOutput' } & Pick<
                  ActivityOutput,
                  'timestamp' | 'durationMs' | 'username'
                >
              >
            >
          >;
        }
      >;
    }
  >;
};

export type ListAdminStatsWithRolesQueryVariables = Exact<{ [key: string]: never }>;

export type ListAdminStatsWithRolesQuery = { __typename?: 'Queries' } & {
  listAdminStats?: Maybe<
    { __typename?: 'AdminStats' } & {
      userDetails?: Maybe<
        Array<
          Maybe<
            { __typename?: 'UserDetailOutput' } & Pick<
              UserDetailOutput,
              | 'id'
              | 'username'
              | 'numberOfProjects'
              | 'numberOfSimulations'
              | 'activeTimeInSeconds'
              | 'numberOfConfiguredAgents'
              | 'numberOfLibraries'
              | 'userRole'
            > & { joined: UserDetailOutput['joinedDate'] }
          >
        >
      >;
    }
  >;
  availableUserRoles?: Maybe<
    { __typename?: 'AvailableUserRolesOutput' } & {
      userRoles?: Maybe<
        Array<Maybe<{ __typename?: 'UserRoleOutput' } & Pick<UserRoleOutput, 'roleName' | 'value'>>>
      >;
    }
  >;
};

export type ListAdminUserDetailsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  filterRole?: Maybe<UserRoleFilterField>;
  orderBy?: Maybe<UserStatsOrderField>;
}>;

export type ListAdminUserDetailsQuery = { __typename?: 'Queries' } & {
  listAdminUserDetails?: Maybe<
    { __typename?: 'RegistryPageUserListOutput' } & Pick<
      RegistryPageUserListOutput,
      'totalNumberOfUsers'
    > & {
        userList?: Maybe<
          Array<
            Maybe<
              { __typename?: 'UserSimpleOutput' } & Pick<
                UserSimpleOutput,
                | 'id'
                | 'username'
                | 'name'
                | 'email'
                | 'profilePicture'
                | 'userRole'
                | 'joinedDate'
                | 'numberOfProjects'
                | 'chosenRole'
                | 'numberOfSimulations'
                | 'numberOfConfiguredAgents'
                | 'numberOfLibraries'
                | 'numberOfCanaryNetworks'
                | 'numberOfCollaborations'
                | 'requestStatus'
              >
            >
          >
        >;
      }
  >;
};

export type ReadUserRoleQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type ReadUserRoleQuery = { __typename?: 'Queries' } & {
  readUserRole?: Maybe<
    { __typename?: 'UserRoleOutput' } & Pick<UserRoleOutput, 'roleName' | 'value'>
  >;
};

export type PasswordResetQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type PasswordResetQuery = { __typename?: 'Queries' } & Pick<Queries, 'passwordResetEmail'>;

export type AssetReportedDataQueryVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type AssetReportedDataQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'assetReportedData'
>;

export type ListAdminRegistryUsersQueryVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type ListAdminRegistryUsersQuery = { __typename?: 'Queries' } & {
  listAdminRegistryUsers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'RegistryPageSingleUserOutput' } & {
          userDetails?: Maybe<
            { __typename?: 'UserSimpleOutput' } & Pick<
              UserSimpleOutput,
              | 'id'
              | 'username'
              | 'name'
              | 'email'
              | 'profilePicture'
              | 'joinedDate'
              | 'numberOfProjects'
              | 'chosenRole'
              | 'userRole'
              | 'numberOfSimulations'
              | 'numberOfConfiguredAgents'
              | 'numberOfLibraries'
              | 'requestStatus'
            > & {
                canaryInvitations?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CanaryInvitationsOutput' } & Pick<
                        CanaryInvitationsOutput,
                        'configUuid' | 'status'
                      >
                    >
                  >
                >;
              }
          >;
          assetsConfigured?: Maybe<
            Array<
              Maybe<
                { __typename?: 'AssetRegistrationOutput' } & Pick<
                  AssetRegistrationOutput,
                  'assetUuid' | 'registrationStatus'
                >
              >
            >
          >;
        }
      >
    >
  >;
};

export type ListAggregatorRegistryUsersQueryVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type ListAggregatorRegistryUsersQuery = { __typename?: 'Queries' } & {
  listAggregatorRegistryUsers?: Maybe<
    Array<
      Maybe<
        { __typename?: 'RegistryPageSingleUserOutput' } & {
          userDetails?: Maybe<
            { __typename?: 'UserSimpleOutput' } & Pick<
              UserSimpleOutput,
              | 'id'
              | 'username'
              | 'name'
              | 'email'
              | 'profilePicture'
              | 'joinedDate'
              | 'numberOfProjects'
              | 'chosenRole'
              | 'userRole'
              | 'numberOfSimulations'
              | 'numberOfConfiguredAgents'
              | 'numberOfLibraries'
              | 'requestStatus'
            > & {
                canaryInvitations?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CanaryInvitationsOutput' } & Pick<
                        CanaryInvitationsOutput,
                        'configUuid' | 'status'
                      >
                    >
                  >
                >;
              }
          >;
          assetsConfigured?: Maybe<
            Array<
              Maybe<
                { __typename?: 'AssetRegistrationOutput' } & Pick<
                  AssetRegistrationOutput,
                  'assetUuid' | 'registrationStatus'
                >
              >
            >
          >;
        }
      >
    >
  >;
};

export type ListAvailableExternalApplicationQueryVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type ListAvailableExternalApplicationQuery = { __typename?: 'Queries' } & {
  listAvailableExternalApplication?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ExternalConnectionApplicantOutput' } & Pick<
          ExternalConnectionApplicantOutput,
          'username' | 'configUuid' | 'areaUuid' | 'status'
        >
      >
    >
  >;
};

export type ListCanaryNetworksQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
}>;

export type ListCanaryNetworksQuery = { __typename?: 'Queries' } & {
  listCanaryNetworks?: Maybe<
    { __typename?: 'ListConfigurationOutput' } & Pick<ListConfigurationOutput, 'totalCount'> & {
        configurations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ConfigurationOutput' } & Pick<
                ConfigurationOutput,
                'name' | 'uuid' | 'user' | 'timestamp' | 'private' | 'resultsStatus'
              > & { configType: ConfigurationOutput['type'] } & {
                  coordinates?: Maybe<
                    { __typename?: 'CoordinatesOutput' } & Pick<
                      CoordinatesOutput,
                      'latitude' | 'longitude'
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ListCanaryNetworksStatusesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
}>;

export type ListCanaryNetworksStatusesQuery = { __typename?: 'Queries' } & {
  listCanaryNetworks?: Maybe<
    { __typename?: 'ListConfigurationOutput' } & {
      configurations?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ConfigurationOutput' } & Pick<
              ConfigurationOutput,
              'uuid' | 'resultsStatus'
            > & { project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>> }
          >
        >
      >;
    }
  >;
};

export type ListGridOperatorRegistryMarketsQueryVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type ListGridOperatorRegistryMarketsQuery = { __typename?: 'Queries' } & {
  listGridOperatorRegistryMarkets?: Maybe<
    Array<Maybe<{ __typename?: 'MarketInformation' } & Pick<MarketInformation, 'name' | 'uuid'>>>
  >;
};

export type ListGsyUserRegistryAssetsQueryVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type ListGsyUserRegistryAssetsQuery = { __typename?: 'Queries' } & {
  listGsyUserRegistryAssets?: Maybe<
    { __typename?: 'GsyUserRegistryOutput' } & {
      assetInformation?: Maybe<
        Array<
          Maybe<
            { __typename?: 'AssetInformation' } & Pick<AssetInformation, 'name' | 'uuid' | 'type'>
          >
        >
      >;
      aggregatorInformation?: Maybe<
        { __typename?: 'GsyUserAggregatorOutput' } & Pick<GsyUserAggregatorOutput, 'name' | 'uuid'>
      >;
    }
  >;
};

export type ListMeasurementVerificationRequestsQueryVariables = Exact<{
  configUuid?: Maybe<Scalars['String']>;
}>;

export type ListMeasurementVerificationRequestsQuery = { __typename?: 'Queries' } & {
  listMeasurementVerificationRequests?: Maybe<
    Array<
      Maybe<
        { __typename?: 'MeasurementVerificationRequest' } & Pick<
          MeasurementVerificationRequest,
          'configUuid' | 'areaUuid' | 'status' | 'userName'
        >
      >
    >
  >;
};

export type ListUserToAdminRequestsQueryVariables = Exact<{
  username?: Maybe<Scalars['String']>;
  status?: Maybe<UserRequestsStatus>;
}>;

export type ListUserToAdminRequestsQuery = { __typename?: 'Queries' } & {
  listUserToAdminRequests?: Maybe<
    Array<
      Maybe<
        { __typename?: 'UserToAdminRequestsOutput' } & Pick<
          UserToAdminRequestsOutput,
          | 'id'
          | 'username'
          | 'requestType'
          | 'status'
          | 'configUuid'
          | 'profilePicture'
          | 'profileThumbnail'
        >
      >
    >
  >;
};

export type MoveCollaborationToCanaryNetworkMutationVariables = Exact<{
  uuid: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
}>;

export type MoveCollaborationToCanaryNetworkMutation = { __typename?: 'Mutations' } & {
  moveCollaborationToCanaryNetwork?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<ConfigurationOutput, 'uuid'> & {
        project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
      }
  >;
};

export type ListCollaborationsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
}>;

export type ListCollaborationsQuery = { __typename?: 'Queries' } & {
  listCollaborations?: Maybe<
    { __typename?: 'ListConfigurationOutput' } & Pick<ListConfigurationOutput, 'totalCount'> & {
        configurations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ConfigurationOutput' } & Pick<
                ConfigurationOutput,
                'name' | 'uuid' | 'user' | 'timestamp' | 'private' | 'resultsStatus'
              > & { configType: ConfigurationOutput['type'] } & {
                  project?: Maybe<
                    { __typename?: 'Project' } & Pick<
                      Project,
                      'name' | 'uuid' | 'user' | 'description' | 'private'
                    >
                  >;
                  coordinates?: Maybe<
                    { __typename?: 'CoordinatesOutput' } & Pick<
                      CoordinatesOutput,
                      'latitude' | 'longitude'
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ListCollaborationsStatusesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
}>;

export type ListCollaborationsStatusesQuery = { __typename?: 'Queries' } & {
  listCollaborations?: Maybe<
    { __typename?: 'ListConfigurationOutput' } & {
      configurations?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ConfigurationOutput' } & Pick<
              ConfigurationOutput,
              'uuid' | 'resultsStatus'
            > & { project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>> }
          >
        >
      >;
    }
  >;
};

export type MyApplicationsQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type MyApplicationsQuery = { __typename?: 'Queries' } & {
  listApplicationStatus?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ExternalConnectionApplicantOutput' } & Pick<
          ExternalConnectionApplicantOutput,
          'areaUuid' | 'status'
        >
      >
    >
  >;
};

export type ReadCollaborationConfigurationQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadCollaborationConfigurationQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
};

export type ListCommunityInfoQueryVariables = Exact<{ [key: string]: never }>;

export type ListCommunityInfoQuery = { __typename?: 'Queries' } & {
  listCommunityInfo?: Maybe<
    { __typename?: 'CommunityOutput' } & {
      projects?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CommunityProjectOutput' } & Pick<
              CommunityProjectOutput,
              | 'name'
              | 'uuid'
              | 'user'
              | 'timestamp'
              | 'private'
              | 'latitude'
              | 'longitude'
              | 'locationVisible'
            > & {
                configurations?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CommunityConfigurationOutput' } & Pick<
                        CommunityConfigurationOutput,
                        'name' | 'uuid' | 'globalCumulativeNetEnergyFlow' | 'latitude' | 'longitude'
                      > & { configType: CommunityConfigurationOutput['type'] }
                    >
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type ListPersonalCommunityInfoQueryVariables = Exact<{ [key: string]: never }>;

export type ListPersonalCommunityInfoQuery = { __typename?: 'Queries' } & {
  listPersonalCommunityInfo?: Maybe<
    { __typename?: 'CommunityOutput' } & {
      projects?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CommunityProjectOutput' } & Pick<
              CommunityProjectOutput,
              | 'name'
              | 'uuid'
              | 'user'
              | 'timestamp'
              | 'private'
              | 'latitude'
              | 'longitude'
              | 'locationVisible'
            > & {
                configurations?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'CommunityConfigurationOutput' } & Pick<
                        CommunityConfigurationOutput,
                        'name' | 'uuid' | 'globalCumulativeNetEnergyFlow' | 'latitude' | 'longitude'
                      > & { configType: CommunityConfigurationOutput['type'] }
                    >
                  >
                >;
              }
          >
        >
      >;
    }
  >;
};

export type AreaSummaryQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type AreaSummaryQuery = { __typename?: 'Queries' } & Pick<Queries, 'areaSummary'>;

export type ListConfigurationsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  projectUuid?: Maybe<Scalars['String']>;
}>;

export type ListConfigurationsQuery = { __typename?: 'Queries' } & {
  listConfigurations?: Maybe<
    { __typename?: 'ListConfigurationOutput' } & Pick<ListConfigurationOutput, 'totalCount'> & {
        configurations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ConfigurationOutput' } & Pick<
                ConfigurationOutput,
                | 'uuid'
                | 'name'
                | 'user'
                | 'description'
                | 'readOnly'
                | 'simulationResults'
                | 'resultsStatus'
                | 'timestamp'
                | 'private'
              > & { configType: ConfigurationOutput['type'] } & {
                  scenarioData?: Maybe<
                    { __typename?: 'ScenarioOutput' } & Pick<ScenarioOutput, 'deviceCount'>
                  >;
                  areaSummary?: Maybe<
                    { __typename?: 'ConfigurationDetails' } & Pick<
                      ConfigurationDetails,
                      'configuredAreas' | 'configuredDevices'
                    >
                  >;
                  project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
                }
            >
          >
        >;
      }
  >;
};

export type LookForCommunityUuidQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type LookForCommunityUuidQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<
      ConfigurationOutput,
      'name' | 'uuid' | 'simulationResults' | 'resultsStatus'
    > & {
        scenarioData?: Maybe<
          { __typename?: 'ScenarioOutput' } & {
            latest?: Maybe<
              { __typename?: 'AreaOutput' } & {
                children?: Maybe<
                  Array<
                    Maybe<
                      | { __typename?: 'PV' }
                      | { __typename?: 'Storage' }
                      | { __typename?: 'Load' }
                      | { __typename?: 'SmartMeter' }
                      | { __typename?: 'FiniteDieselGenerator' }
                      | { __typename?: 'MarketMaker' }
                      | { __typename?: 'InfiniteBus' }
                      | { __typename?: 'WindTurbine' }
                      | { __typename?: 'HeatPump' }
                      | { __typename?: 'ScmStorage' }
                      | { __typename?: 'ScmHeatPump' }
                      | ({ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'name' | 'uuid'>)
                    >
                  >
                >;
              }
            >;
          }
        >;
      }
  >;
};

export type LookForSimulationResultsQueryVariables = Exact<{
  configurationUuid: Scalars['String'];
}>;

export type LookForSimulationResultsQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<
      ConfigurationOutput,
      'uuid' | 'simulationResults' | 'resultsStatus'
    >
  >;
};

export type ReadConfigRepresentationWithoutEventsQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadConfigRepresentationWithoutEventsQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & {
      scenarioData?: Maybe<
        { __typename?: 'ScenarioOutput' } & {
          representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
        }
      >;
    }
  >;
};

export type ReadConfigurationQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadConfigurationQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<{ __typename?: 'ConfigurationOutput' } & ConfigurationFieldsFragment>;
};

export type ReadConfigurationBaseInfoQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadConfigurationBaseInfoQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<
      ConfigurationOutput,
      'uuid' | 'user' | 'private'
    > & { configType: ConfigurationOutput['type'] }
  >;
};

export type ReadConfigurationSummaryQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadConfigurationSummaryQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & {
      areaSummary?: Maybe<
        { __typename?: 'ConfigurationDetails' } & Pick<
          ConfigurationDetails,
          'configuredAreas' | 'configuredDevices'
        >
      >;
    }
  >;
};

export type ReadDataToDetermineUsedNodesNamesQueryVariables = Exact<{
  configurationUuid: Scalars['String'];
  skipReadConfiguration?: Maybe<Scalars['Boolean']>;
  skipReadLibrary?: Maybe<Scalars['Boolean']>;
}>;

export type ReadDataToDetermineUsedNodesNamesQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & {
      scenarioData?: Maybe<
        { __typename?: 'ScenarioOutput' } & {
          representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
          latest?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
        }
      >;
    }
  >;
  readLibrary?: Maybe<
    { __typename?: 'LibraryOutput' } & {
      scenarioData?: Maybe<
        { __typename?: 'ScenarioLibraryOutput' } & {
          representation?: Maybe<
            | ({ __typename?: 'PV' } & Pick<Pv, 'serialized'>)
            | ({ __typename?: 'Storage' } & Pick<Storage, 'serialized'>)
            | ({ __typename?: 'Load' } & Pick<Load, 'serialized'>)
            | { __typename?: 'SmartMeter' }
            | ({ __typename?: 'FiniteDieselGenerator' } & Pick<FiniteDieselGenerator, 'serialized'>)
            | ({ __typename?: 'MarketMaker' } & Pick<MarketMaker, 'serialized'>)
            | ({ __typename?: 'InfiniteBus' } & Pick<InfiniteBus, 'serialized'>)
            | { __typename?: 'WindTurbine' }
            | { __typename?: 'HeatPump' }
            | { __typename?: 'ScmStorage' }
            | { __typename?: 'ScmHeatPump' }
            | ({ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>)
          >;
        }
      >;
    }
  >;
  listLiveEvents?: Maybe<
    Array<Maybe<{ __typename?: 'LiveEventListOutput' } & Pick<LiveEventListOutput, 'diff'>>>
  >;
};

export type GetUserGlobalLocationVisibilityQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserGlobalLocationVisibilityQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'getUserGlobalLocationVisibility'
>;

export type ListGlobalSimulationLocationQueryVariables = Exact<{
  minLat: Scalars['Float'];
  minLong: Scalars['Float'];
  maxLat: Scalars['Float'];
  maxLong: Scalars['Float'];
}>;

export type ListGlobalSimulationLocationQuery = { __typename?: 'Queries' } & {
  listGlobalSimulationLocation?: Maybe<
    Array<
      Maybe<
        { __typename?: 'LocationBoundaryOutput' } & Pick<
          LocationBoundaryOutput,
          'boundaryId' | 'totalMarkets' | 'totalDevices' | 'simulationsInProgress'
        >
      >
    >
  >;
};

export type ListUserGlobalSimulationVisibilityQueryVariables = Exact<{ [key: string]: never }>;

export type ListUserGlobalSimulationVisibilityQuery = { __typename?: 'Queries' } & {
  listUserGlobalSimulationVisibility?: Maybe<
    Array<
      Maybe<
        { __typename?: 'SimulationLocationVisibilityOutput' } & Pick<
          SimulationLocationVisibilityOutput,
          | 'configUuid'
          | 'visibility'
          | 'boundaryId'
          | 'totalMarkets'
          | 'totalDevices'
          | 'simulationsInProgress'
          | 'projectUuid'
        > & { configType: SimulationLocationVisibilityOutput['type'] }
      >
    >
  >;
};

export type ReadGlobalSimulationLocationQueryVariables = Exact<{
  configUuid: Scalars['String'];
}>;

export type ReadGlobalSimulationLocationQuery = { __typename?: 'Queries' } & {
  readGlobalSimulationLocation?: Maybe<
    { __typename?: 'SimulationLocationOutput' } & Pick<
      SimulationLocationOutput,
      'longitude' | 'latitude' | 'boundaryId' | 'visibility'
    >
  >;
};

export type ListLibrariesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  libraryTypes?: Maybe<Array<Maybe<LibraryTypesGraphql>> | Maybe<LibraryTypesGraphql>>;
}>;

export type ListLibrariesQuery = { __typename?: 'Queries' } & {
  listLibraries?: Maybe<
    { __typename?: 'ListLibraryOutput' } & Pick<ListLibraryOutput, 'totalCount'> & {
        libraries?: Maybe<
          Array<
            Maybe<
              { __typename?: 'LibraryOutput' } & Pick<
                LibraryOutput,
                | 'name'
                | 'uuid'
                | 'description'
                | 'user'
                | 'private'
                | 'readOnly'
                | 'tags'
                | 'libraryType'
              > & {
                  scenarioData?: Maybe<
                    { __typename?: 'ScenarioLibraryOutput' } & Pick<
                      ScenarioLibraryOutput,
                      'deviceCountNumber' | 'areaCountNumber'
                    > & {
                        representation?: Maybe<
                          | ({ __typename?: 'PV' } & Pick<Pv, 'serialized' | 'type' | 'uuid'>)
                          | ({ __typename?: 'Storage' } & Pick<
                              Storage,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'Load' } & Pick<Load, 'serialized' | 'type' | 'uuid'>)
                          | ({ __typename?: 'SmartMeter' } & Pick<
                              SmartMeter,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'FiniteDieselGenerator' } & Pick<
                              FiniteDieselGenerator,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'MarketMaker' } & Pick<
                              MarketMaker,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'InfiniteBus' } & Pick<
                              InfiniteBus,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'WindTurbine' } & Pick<
                              WindTurbine,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'HeatPump' } & Pick<
                              HeatPump,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'ScmStorage' } & Pick<
                              ScmStorage,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'ScmHeatPump' } & Pick<
                              ScmHeatPump,
                              'serialized' | 'type' | 'uuid'
                            >)
                          | ({ __typename?: 'AreaOutput' } & Pick<
                              AreaOutput,
                              'serialized' | 'type' | 'uuid'
                            >)
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ListLibrariesWithDataQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type ListLibrariesWithDataQuery = { __typename?: 'Queries' } & {
  listLibraries?: Maybe<
    { __typename?: 'ListLibraryOutput' } & Pick<ListLibraryOutput, 'totalCount'> & {
        libraries?: Maybe<
          Array<
            Maybe<
              { __typename?: 'LibraryOutput' } & Pick<
                LibraryOutput,
                'name' | 'uuid' | 'private'
              > & {
                  scenarioData?: Maybe<
                    { __typename?: 'ScenarioLibraryOutput' } & Pick<
                      ScenarioLibraryOutput,
                      'deviceCount' | 'deviceCountNumber' | 'areaCountNumber'
                    > & {
                        representation?: Maybe<
                          | ({ __typename?: 'PV' } & Pick<Pv, 'serialized'>)
                          | ({ __typename?: 'Storage' } & Pick<Storage, 'serialized'>)
                          | ({ __typename?: 'Load' } & Pick<Load, 'serialized'>)
                          | { __typename?: 'SmartMeter' }
                          | ({ __typename?: 'FiniteDieselGenerator' } & Pick<
                              FiniteDieselGenerator,
                              'serialized'
                            >)
                          | ({ __typename?: 'MarketMaker' } & Pick<
                              MarketMaker,
                              'serialized' | 'energyRateProfileUuid'
                            >)
                          | ({ __typename?: 'InfiniteBus' } & Pick<
                              InfiniteBus,
                              'serialized' | 'energyRateProfileUuid' | 'buyingRateProfileUuid'
                            >)
                          | { __typename?: 'WindTurbine' }
                          | { __typename?: 'HeatPump' }
                          | { __typename?: 'ScmStorage' }
                          | { __typename?: 'ScmHeatPump' }
                          | ({ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>)
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ListLibrariesWithoutScenarioQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  libraryTypes?: Maybe<Array<Maybe<LibraryTypesGraphql>> | Maybe<LibraryTypesGraphql>>;
}>;

export type ListLibrariesWithoutScenarioQuery = { __typename?: 'Queries' } & {
  listLibraries?: Maybe<
    { __typename?: 'ListLibraryOutput' } & Pick<ListLibraryOutput, 'totalCount'> & {
        libraries?: Maybe<
          Array<
            Maybe<
              { __typename?: 'LibraryOutput' } & Pick<
                LibraryOutput,
                | 'name'
                | 'uuid'
                | 'description'
                | 'user'
                | 'private'
                | 'readOnly'
                | 'tags'
                | 'libraryType'
              > & {
                  scenarioData?: Maybe<
                    { __typename?: 'ScenarioLibraryOutput' } & Pick<
                      ScenarioLibraryOutput,
                      'deviceCountNumber' | 'areaCountNumber'
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ReadLibraryQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadLibraryQuery = { __typename?: 'Queries' } & {
  readLibrary?: Maybe<
    { __typename?: 'LibraryOutput' } & Pick<
      LibraryOutput,
      'name' | 'uuid' | 'description' | 'user' | 'private' | 'readOnly' | 'tags' | 'libraryType'
    > & {
        scenarioData?: Maybe<
          { __typename?: 'ScenarioLibraryOutput' } & Pick<
            ScenarioLibraryOutput,
            'deviceCountNumber' | 'areaCountNumber'
          > & {
              representation?: Maybe<
                | ({ __typename?: 'PV' } & Pick<Pv, 'serialized' | 'type' | 'uuid'>)
                | ({ __typename?: 'Storage' } & Pick<Storage, 'serialized' | 'type' | 'uuid'>)
                | ({ __typename?: 'Load' } & Pick<Load, 'serialized' | 'type' | 'uuid'>)
                | ({ __typename?: 'SmartMeter' } & Pick<SmartMeter, 'serialized' | 'type' | 'uuid'>)
                | ({ __typename?: 'FiniteDieselGenerator' } & Pick<
                    FiniteDieselGenerator,
                    'serialized' | 'type' | 'uuid'
                  >)
                | ({ __typename?: 'MarketMaker' } & Pick<
                    MarketMaker,
                    'serialized' | 'type' | 'uuid'
                  >)
                | ({ __typename?: 'InfiniteBus' } & Pick<
                    InfiniteBus,
                    'serialized' | 'type' | 'uuid'
                  >)
                | { __typename?: 'WindTurbine' }
                | { __typename?: 'HeatPump' }
                | { __typename?: 'ScmStorage' }
                | { __typename?: 'ScmHeatPump' }
                | ({ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized' | 'type' | 'uuid'>)
              >;
            }
        >;
      }
  >;
};

export type ListLiveEventsQueryVariables = Exact<{
  configUUID: Scalars['String'];
}>;

export type ListLiveEventsQuery = { __typename?: 'Queries' } & {
  listLiveEvents?: Maybe<
    Array<
      Maybe<
        { __typename?: 'LiveEventListOutput' } & Pick<
          LiveEventListOutput,
          'eventType' | 'parameters' | 'timeStamp' | 'diff'
        >
      >
    >
  >;
};

export type LiveEventsRepresentationQueryVariables = Exact<{
  configUuid: Scalars['String'];
  time: Scalars['DateTime'];
}>;

export type LiveEventsRepresentationQuery = { __typename?: 'Queries' } & {
  liveEventsRepresentation?: Maybe<
    { __typename?: 'LiveEventRepresentation' } & Pick<LiveEventRepresentation, 'timeStamp'> & {
        representation?: Maybe<{ __typename?: 'AreaOutput' } & Pick<AreaOutput, 'serialized'>>;
      }
  >;
};

export type ListCommunityNotificationsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  configUuid?: Maybe<Scalars['String']>;
}>;

export type ListCommunityNotificationsQuery = { __typename?: 'Queries' } & {
  listCommunityNotifications?: Maybe<
    { __typename?: 'ListCommunityNotificationOutput' } & Pick<
      ListCommunityNotificationOutput,
      'totalCount' | 'unreadCount'
    > & {
        notifications?: Maybe<
          Array<
            Maybe<
              { __typename?: 'CommunityNotificationOutput' } & Pick<
                CommunityNotificationOutput,
                'id' | 'title' | 'message' | 'status' | 'sender' | 'createdAt'
              > & {
                  uploadedFiles?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'NotificationFileUploadOutput' } & Pick<
                          NotificationFileUploadOutput,
                          'name' | 'url' | 'uuid'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ListProjectStatusesQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  nrConfigs?: Maybe<Scalars['Int']>;
}>;

export type ListProjectStatusesQuery = { __typename?: 'Queries' } & {
  listProjects?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectOutput' } & Pick<ProjectOutput, 'uuid'> & {
            configurations?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ConfigurationOutput' } & Pick<
                    ConfigurationOutput,
                    'uuid' | 'name' | 'resultsStatus'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type ListProjectsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  nrConfigs?: Maybe<Scalars['Int']>;
}>;

export type ListProjectsQuery = { __typename?: 'Queries' } & {
  listProjects?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ProjectOutput' } & Pick<
          ProjectOutput,
          'name' | 'uuid' | 'description' | 'user' | 'private'
        > & {
            configurations?: Maybe<
              Array<
                Maybe<
                  { __typename?: 'ConfigurationOutput' } & Pick<
                    ConfigurationOutput,
                    'uuid' | 'name' | 'resultsStatus'
                  >
                >
              >
            >;
          }
      >
    >
  >;
};

export type ReadProjectQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadProjectQuery = { __typename?: 'Queries' } & {
  readProject?: Maybe<
    { __typename?: 'ProjectOutput' } & Pick<
      ProjectOutput,
      'name' | 'uuid' | 'user' | 'description' | 'private'
    > & {
        configurations?: Maybe<
          Array<Maybe<{ __typename?: 'ConfigurationOutput' } & Pick<ConfigurationOutput, 'uuid'>>>
        >;
      }
  >;
};

export type ReadProjectLocationVisibilityQueryVariables = Exact<{
  projectUuid: Scalars['String'];
}>;

export type ReadProjectLocationVisibilityQuery = { __typename?: 'Queries' } & {
  readProject?: Maybe<
    { __typename?: 'ProjectOutput' } & Pick<ProjectOutput, 'locationVisible' | 'private'>
  >;
};

export type ReadProjectTitleQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadProjectTitleQuery = { __typename?: 'Queries' } & {
  readProject?: Maybe<{ __typename?: 'ProjectOutput' } & Pick<ProjectOutput, 'name'>>;
};

export type CommunitySummaryResultsQueryVariables = Exact<{
  jobId: Scalars['String'];
  communityUuid: Scalars['String'];
}>;

export type CommunitySummaryResultsQuery = { __typename?: 'Queries' } & {
  simulationResults?: Maybe<
    { __typename?: 'SimulationResultsData' } & Pick<
      SimulationResultsData,
      'bills' | 'cumulativeNetEnergyFlow'
    >
  >;
};

export type GetOperationalQueryVariables = Exact<{ [key: string]: never }>;

export type GetOperationalQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'getScmMemberOperationalUuid'
>;

export type HouseSummaryResultsQueryVariables = Exact<{
  jobId: Scalars['String'];
  assetUuid: Scalars['String'];
  timeResolutionHrs?: Maybe<Scalars['Int']>;
}>;

export type HouseSummaryResultsQuery = { __typename?: 'Queries' } & {
  simulationResults?: Maybe<
    { __typename?: 'SimulationResultsData' } & Pick<
      SimulationResultsData,
      'currentMarket' | 'areaThroughput' | 'status'
    > & {
        marketSummary?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MarketSummary' } & Pick<
                MarketSummary,
                'averageEnergyRate' | 'timestamp'
              >
            >
          >
        >;
      }
  >;
};

export type ListScmQueryVariables = Exact<{
  configType?: Maybe<ConfigType>;
}>;

export type ListScmQuery = { __typename?: 'Queries' } & {
  listScmCommunities?: Maybe<
    { __typename?: 'ListConfigurationOutput' } & Pick<ListConfigurationOutput, 'totalCount'> & {
        configurations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'ConfigurationOutput' } & Pick<
                ConfigurationOutput,
                'name' | 'uuid' | 'user' | 'timestamp' | 'resultsStatus'
              > & { configType: ConfigurationOutput['type'] } & {
                  project?: Maybe<{ __typename?: 'Project' } & Pick<Project, 'uuid'>>;
                  coordinates?: Maybe<
                    { __typename?: 'CoordinatesOutput' } & Pick<
                      CoordinatesOutput,
                      'latitude' | 'longitude'
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ReadScoreboardResultsQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;

export type ReadScoreboardResultsQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'simulationStatus' | 'scoreboardResults'
>;

export type ReadSimulationProgressQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReadSimulationProgressQuery = { __typename?: 'Queries' } & {
  readConfiguration?: Maybe<
    { __typename?: 'ConfigurationOutput' } & Pick<ConfigurationOutput, 'resultsStatus'> & {
        resultsProgressInfo?: Maybe<
          { __typename?: 'ProgressInfo' } & Pick<ProgressInfo, 'percentageCompleted'>
        >;
      }
  >;
};

export type ReadSimulationResultsQueryVariables = Exact<{
  jobId: Scalars['String'];
  uuid: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
}>;

export type ReadSimulationResultsQuery = { __typename?: 'Queries' } & {
  simulationResults?: Maybe<
    { __typename?: 'SimulationResultsData' } & Pick<
      SimulationResultsData,
      | 'areaUuid'
      | 'cumulativeBills'
      | 'cumulativeGridTrades'
      | 'currentMarket'
      | 'cumulativeNetEnergyFlow'
      | 'deviceStatistics'
      | 'tradeProfileAreaThroughput'
      | 'kpi'
      | 'bills'
      | 'priceEnergyDay'
      | 'latestSavingsKpi'
      | 'savingsKpiProfile'
      | 'assetsInfo'
      | 'status'
    > & {
        marketSummary?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MarketSummary' } & Pick<
                MarketSummary,
                'externalTradedVolume' | 'averageEnergyRate' | 'timestamp' | 'tradedVolume'
              >
            >
          >
        >;
        progressInfo?: Maybe<
          { __typename?: 'ProgressInfo' } & Pick<
            ProgressInfo,
            'percentageCompleted' | 'etaSeconds' | 'elapsedTimeSeconds'
          >
        >;
      }
  >;
};

export type ReadSimulationResultsBidTradeQueryVariables = Exact<{
  jobId: Scalars['String'];
  assetUuid: Scalars['String'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
}>;

export type ReadSimulationResultsBidTradeQuery = { __typename?: 'Queries' } & {
  simulationResults?: Maybe<
    { __typename?: 'SimulationResultsData' } & Pick<SimulationResultsData, 'bidsOffersTrades'>
  >;
};

export type ReadSimulationResultsStatusQueryVariables = Exact<{
  jobId: Scalars['String'];
  uuid: Scalars['String'];
}>;

export type ReadSimulationResultsStatusQuery = { __typename?: 'Queries' } & {
  simulationResults?: Maybe<
    { __typename?: 'SimulationResultsData' } & Pick<SimulationResultsData, 'status'> & {
        progressInfo?: Maybe<
          { __typename?: 'ProgressInfo' } & Pick<
            ProgressInfo,
            'percentageCompleted' | 'etaSeconds' | 'elapsedTimeSeconds'
          >
        >;
      }
  >;
};

export type ReadSimulationStatusQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;

export type ReadSimulationStatusQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'simulationStatus'
>;

export type ScmAccumulatedBillsDifferencesQueryVariables = Exact<{
  jobId: Scalars['String'];
  uuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
}>;

export type ScmAccumulatedBillsDifferencesQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'scmAccumulatedBillsDifferences'
>;

export type ScmAccumulatedBillsDifferencesCommunityQueryVariables = Exact<{
  jobId: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
}>;

export type ScmAccumulatedBillsDifferencesCommunityQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'scmAccumulatedBillsDifferencesCommunity'
>;

export type ScmAccumulatedKpiDifferencesQueryVariables = Exact<{
  jobId: Scalars['String'];
  uuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
}>;

export type ScmAccumulatedKpiDifferencesQuery = { __typename?: 'Queries' } & Pick<
  Queries,
  'scmAccumulatedKpiDifferences'
>;

export type ScmAccumulatedResultsQueryVariables = Exact<{
  jobId: Scalars['String'];
  uuid: Scalars['String'];
  timestamp: Scalars['DateTime'];
}>;

export type ScmAccumulatedResultsQuery = { __typename?: 'Queries' } & {
  scmAccumulatedResults?: Maybe<
    { __typename?: 'AccumulatedSimulationResultsData' } & Pick<
      AccumulatedSimulationResultsData,
      'cumulativeGridTrades' | 'cumulativeNetEnergyFlow' | 'bills' | 'cumulativeBills' | 'kpi'
    >
  >;
};

export type ScmProfileResultsQueryVariables = Exact<{
  jobId: Scalars['String'];
  uuid: Scalars['String'];
  startTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
}>;

export type ScmProfileResultsQuery = { __typename?: 'Queries' } & {
  scmProfileResults?: Maybe<
    { __typename?: 'ScmProfileResultsData' } & Pick<ScmProfileResultsData, 'billsEnergy' | 'kpi'>
  >;
};

export type SimulationGlobalResultsQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;

export type SimulationGlobalResultsQuery = { __typename?: 'Queries' } & {
  simulationGlobalResults?: Maybe<
    { __typename?: 'GlobalSimulationResultsData' } & Pick<
      GlobalSimulationResultsData,
      'launchDatetime' | 'status'
    >
  >;
};

export type AvailableUserRolesQueryVariables = Exact<{ [key: string]: never }>;

export type AvailableUserRolesQuery = { __typename?: 'Queries' } & {
  availableUserRoles?: Maybe<
    { __typename?: 'AvailableUserRolesOutput' } & {
      userRoles?: Maybe<
        Array<Maybe<{ __typename?: 'UserRoleOutput' } & Pick<UserRoleOutput, 'roleName' | 'value'>>>
      >;
    }
  >;
};

export type RegisteredUserQueryVariables = Exact<{ [key: string]: never }>;

export type RegisteredUserQuery = { __typename?: 'Queries' } & {
  registeredUsers?: Maybe<
    Array<Maybe<{ __typename?: 'UserOutput' } & Pick<UserOutput, 'username' | 'superuser'>>>
  >;
};

export const AuthTokenFieldsFragmentDoc = gql`
  fragment AuthTokenFields on AuthToken {
    email
    isAdmin
    userRole
    token
    username
    isSuperuser: isAdmin
    userRole
    profilePicture
    name
  }
`;
export const SettingsDataFieldsFragmentDoc = gql`
  fragment SettingsDataFields on SettingsOutput {
    slotLengthMinutes
    tickLengthSeconds
    marketCount
    slotLengthRealtimeSeconds
    startDate
    endDate
    endDateTime
    spotMarketType
    bidOfferMatchAlgo
    currency
    scmCoefficientAlgorithm
    scmGroupSettingsName
  }
`;
export const ConfigurationFieldsFragmentDoc = gql`
  fragment ConfigurationFields on ConfigurationOutput {
    uuid
    name
    description
    readOnly
    simulationResults
    resultsStatus
    timestamp
    timezone
    warnings
    areaSummary {
      configuredAreas
      configuredDevices
    }
    scenarioData {
      latest {
        serialized
        uuid
      }
      homeInfo {
        homeUuids
        energyBills {
          uuid
          bill
        }
        scmHomeDetails {
          address
          assetCount
          coefficientPercentage
          email
          feedInTariff
          fixedMonthlyFee
          gridImportFeeConst
          marketMakerRate
          marketplaceMonthlyFee
          assistanceMonthlyFee
          name
          taxesSurcharges
          uuid
          zipCode
        }
      }
    }
    settingsData {
      ...SettingsDataFields
    }
    project {
      uuid
      name
      user
    }
    configType: type
    user
    private
    coordinates {
      longitude
      latitude
    }
  }
  ${SettingsDataFieldsFragmentDoc}
`;
export const ConfirmEmailDocument = gql`
  mutation confirmEmail($confirmKey: String!) {
    confirmEmail(confirmKey: $confirmKey)
  }
`;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      confirmKey: // value for 'confirmKey'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>,
) {
  return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(
    ConfirmEmailDocument,
    baseOptions,
  );
}
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const ConfirmPasswordResetDocument = gql`
  mutation confirmPasswordReset(
    $uid: String!
    $key: String!
    $password: String!
    $passwordRepeat: String!
  ) {
    confirmPasswordReset(uid: $uid, key: $key, password: $password, passwordRepeat: $passwordRepeat)
  }
`;
export type ConfirmPasswordResetMutationFn = Apollo.MutationFunction<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;

/**
 * __useConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPasswordResetMutation, { data, loading, error }] = useConfirmPasswordResetMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      key: // value for 'key'
 *      password: // value for 'password'
 *      passwordRepeat: // value for 'passwordRepeat'
 *   },
 * });
 */
export function useConfirmPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
  >,
) {
  return Apollo.useMutation<ConfirmPasswordResetMutation, ConfirmPasswordResetMutationVariables>(
    ConfirmPasswordResetDocument,
    baseOptions,
  );
}
export type ConfirmPasswordResetMutationHookResult = ReturnType<
  typeof useConfirmPasswordResetMutation
>;
export type ConfirmPasswordResetMutationResult = Apollo.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;
export const InviteUserToWebsiteDocument = gql`
  mutation inviteUserToWebsite($email: String!, $name: String!, $userRole: UserRoleEnum) {
    inviteUserToWebsite(email: $email, name: $name, userRole: $userRole)
  }
`;
export type InviteUserToWebsiteMutationFn = Apollo.MutationFunction<
  InviteUserToWebsiteMutation,
  InviteUserToWebsiteMutationVariables
>;

/**
 * __useInviteUserToWebsiteMutation__
 *
 * To run a mutation, you first call `useInviteUserToWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserToWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserToWebsiteMutation, { data, loading, error }] = useInviteUserToWebsiteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      userRole: // value for 'userRole'
 *   },
 * });
 */
export function useInviteUserToWebsiteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteUserToWebsiteMutation,
    InviteUserToWebsiteMutationVariables
  >,
) {
  return Apollo.useMutation<InviteUserToWebsiteMutation, InviteUserToWebsiteMutationVariables>(
    InviteUserToWebsiteDocument,
    baseOptions,
  );
}
export type InviteUserToWebsiteMutationHookResult = ReturnType<
  typeof useInviteUserToWebsiteMutation
>;
export type InviteUserToWebsiteMutationResult = Apollo.MutationResult<InviteUserToWebsiteMutation>;
export type InviteUserToWebsiteMutationOptions = Apollo.BaseMutationOptions<
  InviteUserToWebsiteMutation,
  InviteUserToWebsiteMutationVariables
>;
export const SetUserRoleDocument = gql`
  mutation setUserRole($email: String!, $username: String, $role: Int!) {
    setUserRole(email: $email, username: $username, userRoleInt: $role) {
      roleName
      value
    }
  }
`;
export type SetUserRoleMutationFn = Apollo.MutationFunction<
  SetUserRoleMutation,
  SetUserRoleMutationVariables
>;

/**
 * __useSetUserRoleMutation__
 *
 * To run a mutation, you first call `useSetUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserRoleMutation, { data, loading, error }] = useSetUserRoleMutation({
 *   variables: {
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useSetUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<SetUserRoleMutation, SetUserRoleMutationVariables>,
) {
  return Apollo.useMutation<SetUserRoleMutation, SetUserRoleMutationVariables>(
    SetUserRoleDocument,
    baseOptions,
  );
}
export type SetUserRoleMutationHookResult = ReturnType<typeof useSetUserRoleMutation>;
export type SetUserRoleMutationResult = Apollo.MutationResult<SetUserRoleMutation>;
export type SetUserRoleMutationOptions = Apollo.BaseMutationOptions<
  SetUserRoleMutation,
  SetUserRoleMutationVariables
>;
export const SignupDocument = gql`
  mutation signup(
    $email: String!
    $name: String!
    $organisation: String!
    $password: String!
    $passwordRepeat: String
    $username: String
    $interest: String
    $profilePicture: Upload
  ) {
    registerUser(
      email: $email
      name: $name
      organisation: $organisation
      password: $password
      passwordRepeat: $passwordRepeat
      username: $username
      interest: $interest
      profilePicture: $profilePicture
    ) {
      response
      message
      authToken {
        ...AuthTokenFields
      }
    }
  }
  ${AuthTokenFieldsFragmentDoc}
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      organisation: // value for 'organisation'
 *      password: // value for 'password'
 *      passwordRepeat: // value for 'passwordRepeat'
 *      username: // value for 'username'
 *      interest: // value for 'interest'
 *      profilePicture: // value for 'profilePicture'
 *   },
 * });
 */
export function useSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>,
) {
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, baseOptions);
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<
  SignupMutation,
  SignupMutationVariables
>;
export const SocialAuthDocument = gql`
  mutation socialAuth($accessToken: String!, $provider: String!) {
    socialAuth(accessToken: $accessToken, provider: $provider) {
      ...AuthTokenFields
    }
  }
  ${AuthTokenFieldsFragmentDoc}
`;
export type SocialAuthMutationFn = Apollo.MutationFunction<
  SocialAuthMutation,
  SocialAuthMutationVariables
>;

/**
 * __useSocialAuthMutation__
 *
 * To run a mutation, you first call `useSocialAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialAuthMutation, { data, loading, error }] = useSocialAuthMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useSocialAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<SocialAuthMutation, SocialAuthMutationVariables>,
) {
  return Apollo.useMutation<SocialAuthMutation, SocialAuthMutationVariables>(
    SocialAuthDocument,
    baseOptions,
  );
}
export type SocialAuthMutationHookResult = ReturnType<typeof useSocialAuthMutation>;
export type SocialAuthMutationResult = Apollo.MutationResult<SocialAuthMutation>;
export type SocialAuthMutationOptions = Apollo.BaseMutationOptions<
  SocialAuthMutation,
  SocialAuthMutationVariables
>;
export const TokenAuthDocument = gql`
  mutation tokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      ...AuthTokenFields
    }
  }
  ${AuthTokenFieldsFragmentDoc}
`;
export type TokenAuthMutationFn = Apollo.MutationFunction<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenAuthMutation(
  baseOptions?: Apollo.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>,
) {
  return Apollo.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(
    TokenAuthDocument,
    baseOptions,
  );
}
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationResult = Apollo.MutationResult<TokenAuthMutation>;
export type TokenAuthMutationOptions = Apollo.BaseMutationOptions<
  TokenAuthMutation,
  TokenAuthMutationVariables
>;
export const TokenRefreshDocument = gql`
  mutation tokenRefresh($token: String!) {
    tokenRefresh(token: $token) {
      token
    }
  }
`;
export type TokenRefreshMutationFn = Apollo.MutationFunction<
  TokenRefreshMutation,
  TokenRefreshMutationVariables
>;

/**
 * __useTokenRefreshMutation__
 *
 * To run a mutation, you first call `useTokenRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenRefreshMutation, { data, loading, error }] = useTokenRefreshMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTokenRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<TokenRefreshMutation, TokenRefreshMutationVariables>,
) {
  return Apollo.useMutation<TokenRefreshMutation, TokenRefreshMutationVariables>(
    TokenRefreshDocument,
    baseOptions,
  );
}
export type TokenRefreshMutationHookResult = ReturnType<typeof useTokenRefreshMutation>;
export type TokenRefreshMutationResult = Apollo.MutationResult<TokenRefreshMutation>;
export type TokenRefreshMutationOptions = Apollo.BaseMutationOptions<
  TokenRefreshMutation,
  TokenRefreshMutationVariables
>;
export const TokenVerifyDocument = gql`
  mutation tokenVerify($token: String!) {
    tokenVerify(token: $token) {
      payload
    }
  }
`;
export type TokenVerifyMutationFn = Apollo.MutationFunction<
  TokenVerifyMutation,
  TokenVerifyMutationVariables
>;

/**
 * __useTokenVerifyMutation__
 *
 * To run a mutation, you first call `useTokenVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenVerifyMutation, { data, loading, error }] = useTokenVerifyMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTokenVerifyMutation(
  baseOptions?: Apollo.MutationHookOptions<TokenVerifyMutation, TokenVerifyMutationVariables>,
) {
  return Apollo.useMutation<TokenVerifyMutation, TokenVerifyMutationVariables>(
    TokenVerifyDocument,
    baseOptions,
  );
}
export type TokenVerifyMutationHookResult = ReturnType<typeof useTokenVerifyMutation>;
export type TokenVerifyMutationResult = Apollo.MutationResult<TokenVerifyMutation>;
export type TokenVerifyMutationOptions = Apollo.BaseMutationOptions<
  TokenVerifyMutation,
  TokenVerifyMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser(
    $interest: String
    $interestedInCn: Boolean
    $organisation: String
    $password: String
    $passwordRepeat: String
    $profilePicture: Upload
  ) {
    updateUser(
      interest: $interest
      interestedInCn: $interestedInCn
      organisation: $organisation
      password: $password
      passwordRepeat: $passwordRepeat
      profilePicture: $profilePicture
    ) {
      organisation
      interest
      interestedInCn
      profilePicture
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      interest: // value for 'interest'
 *      interestedInCn: // value for 'interestedInCn'
 *      organisation: // value for 'organisation'
 *      password: // value for 'password'
 *      passwordRepeat: // value for 'passwordRepeat'
 *      profilePicture: // value for 'profilePicture'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    baseOptions,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const BatchRegisterExternalConnectionDocument = gql`
  mutation batchRegisterExternalConnection(
    $applicationMapping: [AreaUuidApplyMapping]
    $configUuid: String!
  ) {
    batchRegisterExternalConnection(
      applicationMapping: $applicationMapping
      configUuid: $configUuid
    ) {
      response
      message
    }
  }
`;
export type BatchRegisterExternalConnectionMutationFn = Apollo.MutationFunction<
  BatchRegisterExternalConnectionMutation,
  BatchRegisterExternalConnectionMutationVariables
>;

/**
 * __useBatchRegisterExternalConnectionMutation__
 *
 * To run a mutation, you first call `useBatchRegisterExternalConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchRegisterExternalConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchRegisterExternalConnectionMutation, { data, loading, error }] = useBatchRegisterExternalConnectionMutation({
 *   variables: {
 *      applicationMapping: // value for 'applicationMapping'
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useBatchRegisterExternalConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchRegisterExternalConnectionMutation,
    BatchRegisterExternalConnectionMutationVariables
  >,
) {
  return Apollo.useMutation<
    BatchRegisterExternalConnectionMutation,
    BatchRegisterExternalConnectionMutationVariables
  >(BatchRegisterExternalConnectionDocument, baseOptions);
}
export type BatchRegisterExternalConnectionMutationHookResult = ReturnType<
  typeof useBatchRegisterExternalConnectionMutation
>;
export type BatchRegisterExternalConnectionMutationResult = Apollo.MutationResult<BatchRegisterExternalConnectionMutation>;
export type BatchRegisterExternalConnectionMutationOptions = Apollo.BaseMutationOptions<
  BatchRegisterExternalConnectionMutation,
  BatchRegisterExternalConnectionMutationVariables
>;
export const DuplicateCanaryNetworkDocument = gql`
  mutation duplicateCanaryNetwork(
    $name: String!
    $description: String!
    $uuid: String!
    $incorporateLiveEvents: Boolean
  ) {
    duplicateCanaryNetwork(
      name: $name
      description: $description
      uuid: $uuid
      incorporateLiveEvents: $incorporateLiveEvents
    ) {
      name
      uuid
      description
      user
      simulationResults
      project {
        uuid
      }
      configType: type
      scenarioData {
        representation {
          serialized
        }
      }
    }
  }
`;
export type DuplicateCanaryNetworkMutationFn = Apollo.MutationFunction<
  DuplicateCanaryNetworkMutation,
  DuplicateCanaryNetworkMutationVariables
>;

/**
 * __useDuplicateCanaryNetworkMutation__
 *
 * To run a mutation, you first call `useDuplicateCanaryNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCanaryNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCanaryNetworkMutation, { data, loading, error }] = useDuplicateCanaryNetworkMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      uuid: // value for 'uuid'
 *      incorporateLiveEvents: // value for 'incorporateLiveEvents'
 *   },
 * });
 */
export function useDuplicateCanaryNetworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateCanaryNetworkMutation,
    DuplicateCanaryNetworkMutationVariables
  >,
) {
  return Apollo.useMutation<
    DuplicateCanaryNetworkMutation,
    DuplicateCanaryNetworkMutationVariables
  >(DuplicateCanaryNetworkDocument, baseOptions);
}
export type DuplicateCanaryNetworkMutationHookResult = ReturnType<
  typeof useDuplicateCanaryNetworkMutation
>;
export type DuplicateCanaryNetworkMutationResult = Apollo.MutationResult<DuplicateCanaryNetworkMutation>;
export type DuplicateCanaryNetworkMutationOptions = Apollo.BaseMutationOptions<
  DuplicateCanaryNetworkMutation,
  DuplicateCanaryNetworkMutationVariables
>;
export const RequestMeasurementVerificationDocument = gql`
  mutation requestMeasurementVerification($configUuid: String!, $areaUuids: [String]!) {
    requestMeasurementVerification(configUuid: $configUuid, areaUuids: $areaUuids)
  }
`;
export type RequestMeasurementVerificationMutationFn = Apollo.MutationFunction<
  RequestMeasurementVerificationMutation,
  RequestMeasurementVerificationMutationVariables
>;

/**
 * __useRequestMeasurementVerificationMutation__
 *
 * To run a mutation, you first call `useRequestMeasurementVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMeasurementVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMeasurementVerificationMutation, { data, loading, error }] = useRequestMeasurementVerificationMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      areaUuids: // value for 'areaUuids'
 *   },
 * });
 */
export function useRequestMeasurementVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMeasurementVerificationMutation,
    RequestMeasurementVerificationMutationVariables
  >,
) {
  return Apollo.useMutation<
    RequestMeasurementVerificationMutation,
    RequestMeasurementVerificationMutationVariables
  >(RequestMeasurementVerificationDocument, baseOptions);
}
export type RequestMeasurementVerificationMutationHookResult = ReturnType<
  typeof useRequestMeasurementVerificationMutation
>;
export type RequestMeasurementVerificationMutationResult = Apollo.MutationResult<RequestMeasurementVerificationMutation>;
export type RequestMeasurementVerificationMutationOptions = Apollo.BaseMutationOptions<
  RequestMeasurementVerificationMutation,
  RequestMeasurementVerificationMutationVariables
>;
export const RequestSimulationToCnConversionDocument = gql`
  mutation requestSimulationToCnConversion($configUuid: String!, $timezone: String!) {
    requestSimulationToCnConversion(configUuid: $configUuid, timezone: $timezone)
  }
`;
export type RequestSimulationToCnConversionMutationFn = Apollo.MutationFunction<
  RequestSimulationToCnConversionMutation,
  RequestSimulationToCnConversionMutationVariables
>;

/**
 * __useRequestSimulationToCnConversionMutation__
 *
 * To run a mutation, you first call `useRequestSimulationToCnConversionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestSimulationToCnConversionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestSimulationToCnConversionMutation, { data, loading, error }] = useRequestSimulationToCnConversionMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useRequestSimulationToCnConversionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestSimulationToCnConversionMutation,
    RequestSimulationToCnConversionMutationVariables
  >,
) {
  return Apollo.useMutation<
    RequestSimulationToCnConversionMutation,
    RequestSimulationToCnConversionMutationVariables
  >(RequestSimulationToCnConversionDocument, baseOptions);
}
export type RequestSimulationToCnConversionMutationHookResult = ReturnType<
  typeof useRequestSimulationToCnConversionMutation
>;
export type RequestSimulationToCnConversionMutationResult = Apollo.MutationResult<RequestSimulationToCnConversionMutation>;
export type RequestSimulationToCnConversionMutationOptions = Apollo.BaseMutationOptions<
  RequestSimulationToCnConversionMutation,
  RequestSimulationToCnConversionMutationVariables
>;
export const RespondToSimulationToCnRequestDocument = gql`
  mutation respondToSimulationToCnRequest($action: UserRequestResponse, $requestId: String!) {
    respondToSimulationToCnRequest(action: $action, requestId: $requestId) {
      configuration {
        uuid
      }
      message
    }
  }
`;
export type RespondToSimulationToCnRequestMutationFn = Apollo.MutationFunction<
  RespondToSimulationToCnRequestMutation,
  RespondToSimulationToCnRequestMutationVariables
>;

/**
 * __useRespondToSimulationToCnRequestMutation__
 *
 * To run a mutation, you first call `useRespondToSimulationToCnRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondToSimulationToCnRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondToSimulationToCnRequestMutation, { data, loading, error }] = useRespondToSimulationToCnRequestMutation({
 *   variables: {
 *      action: // value for 'action'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useRespondToSimulationToCnRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RespondToSimulationToCnRequestMutation,
    RespondToSimulationToCnRequestMutationVariables
  >,
) {
  return Apollo.useMutation<
    RespondToSimulationToCnRequestMutation,
    RespondToSimulationToCnRequestMutationVariables
  >(RespondToSimulationToCnRequestDocument, baseOptions);
}
export type RespondToSimulationToCnRequestMutationHookResult = ReturnType<
  typeof useRespondToSimulationToCnRequestMutation
>;
export type RespondToSimulationToCnRequestMutationResult = Apollo.MutationResult<RespondToSimulationToCnRequestMutation>;
export type RespondToSimulationToCnRequestMutationOptions = Apollo.BaseMutationOptions<
  RespondToSimulationToCnRequestMutation,
  RespondToSimulationToCnRequestMutationVariables
>;
export const RevokeMeasurementVerificationDocument = gql`
  mutation revokeMeasurementVerification($configUuid: String!, $areaUuids: [String]!) {
    revokeMeasurementVerification(configUuid: $configUuid, areaUuids: $areaUuids)
  }
`;
export type RevokeMeasurementVerificationMutationFn = Apollo.MutationFunction<
  RevokeMeasurementVerificationMutation,
  RevokeMeasurementVerificationMutationVariables
>;

/**
 * __useRevokeMeasurementVerificationMutation__
 *
 * To run a mutation, you first call `useRevokeMeasurementVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeMeasurementVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeMeasurementVerificationMutation, { data, loading, error }] = useRevokeMeasurementVerificationMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      areaUuids: // value for 'areaUuids'
 *   },
 * });
 */
export function useRevokeMeasurementVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeMeasurementVerificationMutation,
    RevokeMeasurementVerificationMutationVariables
  >,
) {
  return Apollo.useMutation<
    RevokeMeasurementVerificationMutation,
    RevokeMeasurementVerificationMutationVariables
  >(RevokeMeasurementVerificationDocument, baseOptions);
}
export type RevokeMeasurementVerificationMutationHookResult = ReturnType<
  typeof useRevokeMeasurementVerificationMutation
>;
export type RevokeMeasurementVerificationMutationResult = Apollo.MutationResult<RevokeMeasurementVerificationMutation>;
export type RevokeMeasurementVerificationMutationOptions = Apollo.BaseMutationOptions<
  RevokeMeasurementVerificationMutation,
  RevokeMeasurementVerificationMutationVariables
>;
export const SetExternalConnectionDocument = gql`
  mutation setExternalConnection(
    $configUuid: String!
    $areaUuid: String!
    $email: String!
    $username: String
    $status: ExternalConnectionApprovalStatus!
  ) {
    setExternalConnection(
      configUuid: $configUuid
      areaUuid: $areaUuid
      email: $email
      username: $username
      status: $status
    )
  }
`;
export type SetExternalConnectionMutationFn = Apollo.MutationFunction<
  SetExternalConnectionMutation,
  SetExternalConnectionMutationVariables
>;

/**
 * __useSetExternalConnectionMutation__
 *
 * To run a mutation, you first call `useSetExternalConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExternalConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExternalConnectionMutation, { data, loading, error }] = useSetExternalConnectionMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      areaUuid: // value for 'areaUuid'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetExternalConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetExternalConnectionMutation,
    SetExternalConnectionMutationVariables
  >,
) {
  return Apollo.useMutation<SetExternalConnectionMutation, SetExternalConnectionMutationVariables>(
    SetExternalConnectionDocument,
    baseOptions,
  );
}
export type SetExternalConnectionMutationHookResult = ReturnType<
  typeof useSetExternalConnectionMutation
>;
export type SetExternalConnectionMutationResult = Apollo.MutationResult<SetExternalConnectionMutation>;
export type SetExternalConnectionMutationOptions = Apollo.BaseMutationOptions<
  SetExternalConnectionMutation,
  SetExternalConnectionMutationVariables
>;
export const SetMeasurementVerificationStatusDocument = gql`
  mutation setMeasurementVerificationStatus(
    $acceptedAreaUuids: [String]!
    $configUuid: String!
    $rejectedAreaUuids: [String]!
  ) {
    setMeasurementVerificationStatus(
      acceptedAreaUuids: $acceptedAreaUuids
      configUuid: $configUuid
      rejectedAreaUuids: $rejectedAreaUuids
    )
  }
`;
export type SetMeasurementVerificationStatusMutationFn = Apollo.MutationFunction<
  SetMeasurementVerificationStatusMutation,
  SetMeasurementVerificationStatusMutationVariables
>;

/**
 * __useSetMeasurementVerificationStatusMutation__
 *
 * To run a mutation, you first call `useSetMeasurementVerificationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMeasurementVerificationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMeasurementVerificationStatusMutation, { data, loading, error }] = useSetMeasurementVerificationStatusMutation({
 *   variables: {
 *      acceptedAreaUuids: // value for 'acceptedAreaUuids'
 *      configUuid: // value for 'configUuid'
 *      rejectedAreaUuids: // value for 'rejectedAreaUuids'
 *   },
 * });
 */
export function useSetMeasurementVerificationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetMeasurementVerificationStatusMutation,
    SetMeasurementVerificationStatusMutationVariables
  >,
) {
  return Apollo.useMutation<
    SetMeasurementVerificationStatusMutation,
    SetMeasurementVerificationStatusMutationVariables
  >(SetMeasurementVerificationStatusDocument, baseOptions);
}
export type SetMeasurementVerificationStatusMutationHookResult = ReturnType<
  typeof useSetMeasurementVerificationStatusMutation
>;
export type SetMeasurementVerificationStatusMutationResult = Apollo.MutationResult<SetMeasurementVerificationStatusMutation>;
export type SetMeasurementVerificationStatusMutationOptions = Apollo.BaseMutationOptions<
  SetMeasurementVerificationStatusMutation,
  SetMeasurementVerificationStatusMutationVariables
>;
export const SetUserInviteDocument = gql`
  mutation setUserInvite($configUuid: String!, $username: String!, $inviteFlag: AdminInviteFlag!) {
    setUserInviteToConfiguration(
      configUuid: $configUuid
      username: $username
      inviteFlag: $inviteFlag
    )
  }
`;
export type SetUserInviteMutationFn = Apollo.MutationFunction<
  SetUserInviteMutation,
  SetUserInviteMutationVariables
>;

/**
 * __useSetUserInviteMutation__
 *
 * To run a mutation, you first call `useSetUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserInviteMutation, { data, loading, error }] = useSetUserInviteMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      username: // value for 'username'
 *      inviteFlag: // value for 'inviteFlag'
 *   },
 * });
 */
export function useSetUserInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<SetUserInviteMutation, SetUserInviteMutationVariables>,
) {
  return Apollo.useMutation<SetUserInviteMutation, SetUserInviteMutationVariables>(
    SetUserInviteDocument,
    baseOptions,
  );
}
export type SetUserInviteMutationHookResult = ReturnType<typeof useSetUserInviteMutation>;
export type SetUserInviteMutationResult = Apollo.MutationResult<SetUserInviteMutation>;
export type SetUserInviteMutationOptions = Apollo.BaseMutationOptions<
  SetUserInviteMutation,
  SetUserInviteMutationVariables
>;
export const BatchResponseExternalConnectionRequestDocument = gql`
  mutation batchResponseExternalConnectionRequest(
    $configUuid: String!
    $responseList: [InputRespondExternalConnectionRequest]
  ) {
    batchResponseExternalConnectionRequest(configUuid: $configUuid, responseList: $responseList)
  }
`;
export type BatchResponseExternalConnectionRequestMutationFn = Apollo.MutationFunction<
  BatchResponseExternalConnectionRequestMutation,
  BatchResponseExternalConnectionRequestMutationVariables
>;

/**
 * __useBatchResponseExternalConnectionRequestMutation__
 *
 * To run a mutation, you first call `useBatchResponseExternalConnectionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchResponseExternalConnectionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchResponseExternalConnectionRequestMutation, { data, loading, error }] = useBatchResponseExternalConnectionRequestMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      responseList: // value for 'responseList'
 *   },
 * });
 */
export function useBatchResponseExternalConnectionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BatchResponseExternalConnectionRequestMutation,
    BatchResponseExternalConnectionRequestMutationVariables
  >,
) {
  return Apollo.useMutation<
    BatchResponseExternalConnectionRequestMutation,
    BatchResponseExternalConnectionRequestMutationVariables
  >(BatchResponseExternalConnectionRequestDocument, baseOptions);
}
export type BatchResponseExternalConnectionRequestMutationHookResult = ReturnType<
  typeof useBatchResponseExternalConnectionRequestMutation
>;
export type BatchResponseExternalConnectionRequestMutationResult = Apollo.MutationResult<BatchResponseExternalConnectionRequestMutation>;
export type BatchResponseExternalConnectionRequestMutationOptions = Apollo.BaseMutationOptions<
  BatchResponseExternalConnectionRequestMutation,
  BatchResponseExternalConnectionRequestMutationVariables
>;
export const CreateCanaryNetworkDocument = gql`
  mutation createCanaryNetwork(
    $description: String
    $name: String!
    $private: Boolean
    $uuid: String!
  ) {
    moveCollaborationToCanaryNetwork(
      name: $name
      description: $description
      uuid: $uuid
      private: $private
    ) {
      name
      uuid
      type
    }
  }
`;
export type CreateCanaryNetworkMutationFn = Apollo.MutationFunction<
  CreateCanaryNetworkMutation,
  CreateCanaryNetworkMutationVariables
>;

/**
 * __useCreateCanaryNetworkMutation__
 *
 * To run a mutation, you first call `useCreateCanaryNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCanaryNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCanaryNetworkMutation, { data, loading, error }] = useCreateCanaryNetworkMutation({
 *   variables: {
 *      description: // value for 'description'
 *      name: // value for 'name'
 *      private: // value for 'private'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCreateCanaryNetworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCanaryNetworkMutation,
    CreateCanaryNetworkMutationVariables
  >,
) {
  return Apollo.useMutation<CreateCanaryNetworkMutation, CreateCanaryNetworkMutationVariables>(
    CreateCanaryNetworkDocument,
    baseOptions,
  );
}
export type CreateCanaryNetworkMutationHookResult = ReturnType<
  typeof useCreateCanaryNetworkMutation
>;
export type CreateCanaryNetworkMutationResult = Apollo.MutationResult<CreateCanaryNetworkMutation>;
export type CreateCanaryNetworkMutationOptions = Apollo.BaseMutationOptions<
  CreateCanaryNetworkMutation,
  CreateCanaryNetworkMutationVariables
>;
export const DuplicateCollaborationDocument = gql`
  mutation duplicateCollaboration(
    $name: String!
    $description: String!
    $uuid: String!
    $incorporateLiveEvents: Boolean
  ) {
    duplicateCollaboration(
      name: $name
      description: $description
      uuid: $uuid
      incorporateLiveEvents: $incorporateLiveEvents
    ) {
      name
      uuid
      description
      user
      simulationResults
      project {
        uuid
      }
      configType: type
      scenarioData {
        representation {
          serialized
        }
      }
    }
  }
`;
export type DuplicateCollaborationMutationFn = Apollo.MutationFunction<
  DuplicateCollaborationMutation,
  DuplicateCollaborationMutationVariables
>;

/**
 * __useDuplicateCollaborationMutation__
 *
 * To run a mutation, you first call `useDuplicateCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCollaborationMutation, { data, loading, error }] = useDuplicateCollaborationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      uuid: // value for 'uuid'
 *      incorporateLiveEvents: // value for 'incorporateLiveEvents'
 *   },
 * });
 */
export function useDuplicateCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateCollaborationMutation,
    DuplicateCollaborationMutationVariables
  >,
) {
  return Apollo.useMutation<
    DuplicateCollaborationMutation,
    DuplicateCollaborationMutationVariables
  >(DuplicateCollaborationDocument, baseOptions);
}
export type DuplicateCollaborationMutationHookResult = ReturnType<
  typeof useDuplicateCollaborationMutation
>;
export type DuplicateCollaborationMutationResult = Apollo.MutationResult<DuplicateCollaborationMutation>;
export type DuplicateCollaborationMutationOptions = Apollo.BaseMutationOptions<
  DuplicateCollaborationMutation,
  DuplicateCollaborationMutationVariables
>;
export const MoveCanaryNetworkToCollaborationDocument = gql`
  mutation moveCanaryNetworkToCollaboration(
    $uuid: String!
    $name: String!
    $description: String
    $private: Boolean
  ) {
    moveCanaryNetworkToCollaboration(
      uuid: $uuid
      name: $name
      description: $description
      private: $private
    ) {
      uuid
      project {
        uuid
      }
    }
  }
`;
export type MoveCanaryNetworkToCollaborationMutationFn = Apollo.MutationFunction<
  MoveCanaryNetworkToCollaborationMutation,
  MoveCanaryNetworkToCollaborationMutationVariables
>;

/**
 * __useMoveCanaryNetworkToCollaborationMutation__
 *
 * To run a mutation, you first call `useMoveCanaryNetworkToCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCanaryNetworkToCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCanaryNetworkToCollaborationMutation, { data, loading, error }] = useMoveCanaryNetworkToCollaborationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useMoveCanaryNetworkToCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveCanaryNetworkToCollaborationMutation,
    MoveCanaryNetworkToCollaborationMutationVariables
  >,
) {
  return Apollo.useMutation<
    MoveCanaryNetworkToCollaborationMutation,
    MoveCanaryNetworkToCollaborationMutationVariables
  >(MoveCanaryNetworkToCollaborationDocument, baseOptions);
}
export type MoveCanaryNetworkToCollaborationMutationHookResult = ReturnType<
  typeof useMoveCanaryNetworkToCollaborationMutation
>;
export type MoveCanaryNetworkToCollaborationMutationResult = Apollo.MutationResult<MoveCanaryNetworkToCollaborationMutation>;
export type MoveCanaryNetworkToCollaborationMutationOptions = Apollo.BaseMutationOptions<
  MoveCanaryNetworkToCollaborationMutation,
  MoveCanaryNetworkToCollaborationMutationVariables
>;
export const RegExternalConnectionDocument = gql`
  mutation regExternalConnection($areaUuid: String!, $configUuid: String!, $apply: Boolean!) {
    registerExternalConnection(areaUuid: $areaUuid, configUuid: $configUuid, apply: $apply) {
      response
      message
    }
  }
`;
export type RegExternalConnectionMutationFn = Apollo.MutationFunction<
  RegExternalConnectionMutation,
  RegExternalConnectionMutationVariables
>;

/**
 * __useRegExternalConnectionMutation__
 *
 * To run a mutation, you first call `useRegExternalConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegExternalConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regExternalConnectionMutation, { data, loading, error }] = useRegExternalConnectionMutation({
 *   variables: {
 *      areaUuid: // value for 'areaUuid'
 *      configUuid: // value for 'configUuid'
 *      apply: // value for 'apply'
 *   },
 * });
 */
export function useRegExternalConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegExternalConnectionMutation,
    RegExternalConnectionMutationVariables
  >,
) {
  return Apollo.useMutation<RegExternalConnectionMutation, RegExternalConnectionMutationVariables>(
    RegExternalConnectionDocument,
    baseOptions,
  );
}
export type RegExternalConnectionMutationHookResult = ReturnType<
  typeof useRegExternalConnectionMutation
>;
export type RegExternalConnectionMutationResult = Apollo.MutationResult<RegExternalConnectionMutation>;
export type RegExternalConnectionMutationOptions = Apollo.BaseMutationOptions<
  RegExternalConnectionMutation,
  RegExternalConnectionMutationVariables
>;
export const RespondExternalConnectionRequestDocument = gql`
  mutation respondExternalConnectionRequest(
    $username: String!
    $areaUuid: String!
    $configUuid: String!
    $accept: Boolean!
  ) {
    respondExternalConnectionRequest(
      username: $username
      areaUuid: $areaUuid
      configUuid: $configUuid
      accept: $accept
    )
  }
`;
export type RespondExternalConnectionRequestMutationFn = Apollo.MutationFunction<
  RespondExternalConnectionRequestMutation,
  RespondExternalConnectionRequestMutationVariables
>;

/**
 * __useRespondExternalConnectionRequestMutation__
 *
 * To run a mutation, you first call `useRespondExternalConnectionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondExternalConnectionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondExternalConnectionRequestMutation, { data, loading, error }] = useRespondExternalConnectionRequestMutation({
 *   variables: {
 *      username: // value for 'username'
 *      areaUuid: // value for 'areaUuid'
 *      configUuid: // value for 'configUuid'
 *      accept: // value for 'accept'
 *   },
 * });
 */
export function useRespondExternalConnectionRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RespondExternalConnectionRequestMutation,
    RespondExternalConnectionRequestMutationVariables
  >,
) {
  return Apollo.useMutation<
    RespondExternalConnectionRequestMutation,
    RespondExternalConnectionRequestMutationVariables
  >(RespondExternalConnectionRequestDocument, baseOptions);
}
export type RespondExternalConnectionRequestMutationHookResult = ReturnType<
  typeof useRespondExternalConnectionRequestMutation
>;
export type RespondExternalConnectionRequestMutationResult = Apollo.MutationResult<RespondExternalConnectionRequestMutation>;
export type RespondExternalConnectionRequestMutationOptions = Apollo.BaseMutationOptions<
  RespondExternalConnectionRequestMutation,
  RespondExternalConnectionRequestMutationVariables
>;
export const SendScmInvitationEmailDocument = gql`
  mutation sendScmInvitationEmail($configUuid: String!, $selectedMemberNames: [String]) {
    sendScmInvitationEmail(configUuid: $configUuid, selectedMemberNames: $selectedMemberNames)
  }
`;
export type SendScmInvitationEmailMutationFn = Apollo.MutationFunction<
  SendScmInvitationEmailMutation,
  SendScmInvitationEmailMutationVariables
>;

/**
 * __useSendScmInvitationEmailMutation__
 *
 * To run a mutation, you first call `useSendScmInvitationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendScmInvitationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendScmInvitationEmailMutation, { data, loading, error }] = useSendScmInvitationEmailMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      selectedMemberNames: // value for 'selectedMemberNames'
 *   },
 * });
 */
export function useSendScmInvitationEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendScmInvitationEmailMutation,
    SendScmInvitationEmailMutationVariables
  >,
) {
  return Apollo.useMutation<
    SendScmInvitationEmailMutation,
    SendScmInvitationEmailMutationVariables
  >(SendScmInvitationEmailDocument, baseOptions);
}
export type SendScmInvitationEmailMutationHookResult = ReturnType<
  typeof useSendScmInvitationEmailMutation
>;
export type SendScmInvitationEmailMutationResult = Apollo.MutationResult<SendScmInvitationEmailMutation>;
export type SendScmInvitationEmailMutationOptions = Apollo.BaseMutationOptions<
  SendScmInvitationEmailMutation,
  SendScmInvitationEmailMutationVariables
>;
export const UploadCommunityDatasheetDocument = gql`
  mutation uploadCommunityDatasheet($configUuid: String!, $file: Upload!) {
    uploadCommunityDatasheet(configUuid: $configUuid, file: $file) {
      name
      uuid
      configType: type
      cloudCoverageOptions
      scenarioData {
        representation {
          serialized
        }
        latest {
          serialized
        }
        homeInfo {
          homeUuids
          energyBills {
            uuid
            bill
          }
          scmHomeDetails {
            address
            assetCount
            coefficientPercentage
            email
            feedInTariff
            fixedMonthlyFee
            gridImportFeeConst
            marketMakerRate
            marketplaceMonthlyFee
            assistanceMonthlyFee
            name
            taxesSurcharges
            uuid
            zipCode
          }
        }
      }
      settingsData {
        ...SettingsDataFields
      }
      simulationResults
      description
      timezone
      user
      events {
        configurationUuid
        areaEvents {
          areaUuid
        }
        settingsEvents {
          time
          marketMakerRate
          marketMakerRateFile
          spotMarketType
          bidOfferMatchAlgo
        }
      }
      timestamp
      readOnly
      project {
        uuid
        name
        user
      }
      warnings
      areaSummary {
        configuredAreas
        configuredDevices
      }
      resultsStatus
      resultsProgressInfo {
        percentageCompleted
      }
      private
      deviceAggregatorMapping
      coordinates {
        latitude
        longitude
      }
      members {
        name
        email
        address
      }
    }
  }
  ${SettingsDataFieldsFragmentDoc}
`;
export type UploadCommunityDatasheetMutationFn = Apollo.MutationFunction<
  UploadCommunityDatasheetMutation,
  UploadCommunityDatasheetMutationVariables
>;

/**
 * __useUploadCommunityDatasheetMutation__
 *
 * To run a mutation, you first call `useUploadCommunityDatasheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCommunityDatasheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCommunityDatasheetMutation, { data, loading, error }] = useUploadCommunityDatasheetMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCommunityDatasheetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCommunityDatasheetMutation,
    UploadCommunityDatasheetMutationVariables
  >,
) {
  return Apollo.useMutation<
    UploadCommunityDatasheetMutation,
    UploadCommunityDatasheetMutationVariables
  >(UploadCommunityDatasheetDocument, baseOptions);
}
export type UploadCommunityDatasheetMutationHookResult = ReturnType<
  typeof useUploadCommunityDatasheetMutation
>;
export type UploadCommunityDatasheetMutationResult = Apollo.MutationResult<UploadCommunityDatasheetMutation>;
export type UploadCommunityDatasheetMutationOptions = Apollo.BaseMutationOptions<
  UploadCommunityDatasheetMutation,
  UploadCommunityDatasheetMutationVariables
>;
export const CreateConfigurationDocument = gql`
  mutation createConfiguration(
    $name: String
    $description: String
    $projectUuid: String!
    $settingsData: SettingsInput
    $scenarioData: ScenarioInput
    $centreCoordinates: Position
    $timezone: String
  ) {
    createConfiguration(
      name: $name
      description: $description
      projectUUID: $projectUuid
      settingsData: $settingsData
      scenarioData: $scenarioData
      centreCoordinates: $centreCoordinates
      timezone: $timezone
    ) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type CreateConfigurationMutationFn = Apollo.MutationFunction<
  CreateConfigurationMutation,
  CreateConfigurationMutationVariables
>;

/**
 * __useCreateConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigurationMutation, { data, loading, error }] = useCreateConfigurationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      projectUuid: // value for 'projectUuid'
 *      settingsData: // value for 'settingsData'
 *      scenarioData: // value for 'scenarioData'
 *      centreCoordinates: // value for 'centreCoordinates'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCreateConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConfigurationMutation,
    CreateConfigurationMutationVariables
  >,
) {
  return Apollo.useMutation<CreateConfigurationMutation, CreateConfigurationMutationVariables>(
    CreateConfigurationDocument,
    baseOptions,
  );
}
export type CreateConfigurationMutationHookResult = ReturnType<
  typeof useCreateConfigurationMutation
>;
export type CreateConfigurationMutationResult = Apollo.MutationResult<CreateConfigurationMutation>;
export type CreateConfigurationMutationOptions = Apollo.BaseMutationOptions<
  CreateConfigurationMutation,
  CreateConfigurationMutationVariables
>;
export const CreateConfigurationAreaDocument = gql`
  mutation createConfigurationArea(
    $configurationUuid: String!
    $parentUuid: String!
    $areaRepresentation: AreaInput!
  ) {
    createConfigurationArea(
      configUuid: $configurationUuid
      parentUuid: $parentUuid
      areaRepresentation: $areaRepresentation
    ) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type CreateConfigurationAreaMutationFn = Apollo.MutationFunction<
  CreateConfigurationAreaMutation,
  CreateConfigurationAreaMutationVariables
>;

/**
 * __useCreateConfigurationAreaMutation__
 *
 * To run a mutation, you first call `useCreateConfigurationAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConfigurationAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConfigurationAreaMutation, { data, loading, error }] = useCreateConfigurationAreaMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      parentUuid: // value for 'parentUuid'
 *      areaRepresentation: // value for 'areaRepresentation'
 *   },
 * });
 */
export function useCreateConfigurationAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConfigurationAreaMutation,
    CreateConfigurationAreaMutationVariables
  >,
) {
  return Apollo.useMutation<
    CreateConfigurationAreaMutation,
    CreateConfigurationAreaMutationVariables
  >(CreateConfigurationAreaDocument, baseOptions);
}
export type CreateConfigurationAreaMutationHookResult = ReturnType<
  typeof useCreateConfigurationAreaMutation
>;
export type CreateConfigurationAreaMutationResult = Apollo.MutationResult<CreateConfigurationAreaMutation>;
export type CreateConfigurationAreaMutationOptions = Apollo.BaseMutationOptions<
  CreateConfigurationAreaMutation,
  CreateConfigurationAreaMutationVariables
>;
export const DeleteConfigurationDocument = gql`
  mutation deleteConfiguration($configurationUuid: String!) {
    deleteConfiguration(uuid: $configurationUuid)
  }
`;
export type DeleteConfigurationMutationFn = Apollo.MutationFunction<
  DeleteConfigurationMutation,
  DeleteConfigurationMutationVariables
>;

/**
 * __useDeleteConfigurationMutation__
 *
 * To run a mutation, you first call `useDeleteConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConfigurationMutation, { data, loading, error }] = useDeleteConfigurationMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *   },
 * });
 */
export function useDeleteConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteConfigurationMutation,
    DeleteConfigurationMutationVariables
  >,
) {
  return Apollo.useMutation<DeleteConfigurationMutation, DeleteConfigurationMutationVariables>(
    DeleteConfigurationDocument,
    baseOptions,
  );
}
export type DeleteConfigurationMutationHookResult = ReturnType<
  typeof useDeleteConfigurationMutation
>;
export type DeleteConfigurationMutationResult = Apollo.MutationResult<DeleteConfigurationMutation>;
export type DeleteConfigurationMutationOptions = Apollo.BaseMutationOptions<
  DeleteConfigurationMutation,
  DeleteConfigurationMutationVariables
>;
export const DuplicateCommunityDocument = gql`
  mutation duplicateCommunity(
    $name: String!
    $description: String!
    $configurationUuid: String!
    $incorporateLiveEvents: Boolean
  ) {
    duplicateCommunity(
      name: $name
      description: $description
      configUuid: $configurationUuid
      incorporateLiveEvents: $incorporateLiveEvents
    ) {
      name
      uuid
      description
      user
      simulationResults
      project {
        uuid
      }
      scenarioData {
        representation {
          serialized
        }
      }
    }
  }
`;
export type DuplicateCommunityMutationFn = Apollo.MutationFunction<
  DuplicateCommunityMutation,
  DuplicateCommunityMutationVariables
>;

/**
 * __useDuplicateCommunityMutation__
 *
 * To run a mutation, you first call `useDuplicateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCommunityMutation, { data, loading, error }] = useDuplicateCommunityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      configurationUuid: // value for 'configurationUuid'
 *      incorporateLiveEvents: // value for 'incorporateLiveEvents'
 *   },
 * });
 */
export function useDuplicateCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateCommunityMutation,
    DuplicateCommunityMutationVariables
  >,
) {
  return Apollo.useMutation<DuplicateCommunityMutation, DuplicateCommunityMutationVariables>(
    DuplicateCommunityDocument,
    baseOptions,
  );
}
export type DuplicateCommunityMutationHookResult = ReturnType<typeof useDuplicateCommunityMutation>;
export type DuplicateCommunityMutationResult = Apollo.MutationResult<DuplicateCommunityMutation>;
export type DuplicateCommunityMutationOptions = Apollo.BaseMutationOptions<
  DuplicateCommunityMutation,
  DuplicateCommunityMutationVariables
>;
export const DuplicateConfigurationDocument = gql`
  mutation duplicateConfiguration(
    $name: String!
    $description: String!
    $configurationUuid: String!
    $incorporateLiveEvents: Boolean
  ) {
    duplicateConfiguration(
      name: $name
      description: $description
      uuid: $configurationUuid
      incorporateLiveEvents: $incorporateLiveEvents
    ) {
      name
      uuid
      description
      user
      simulationResults
      project {
        uuid
      }
      scenarioData {
        representation {
          serialized
        }
      }
    }
  }
`;
export type DuplicateConfigurationMutationFn = Apollo.MutationFunction<
  DuplicateConfigurationMutation,
  DuplicateConfigurationMutationVariables
>;

/**
 * __useDuplicateConfigurationMutation__
 *
 * To run a mutation, you first call `useDuplicateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateConfigurationMutation, { data, loading, error }] = useDuplicateConfigurationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      configurationUuid: // value for 'configurationUuid'
 *      incorporateLiveEvents: // value for 'incorporateLiveEvents'
 *   },
 * });
 */
export function useDuplicateConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateConfigurationMutation,
    DuplicateConfigurationMutationVariables
  >,
) {
  return Apollo.useMutation<
    DuplicateConfigurationMutation,
    DuplicateConfigurationMutationVariables
  >(DuplicateConfigurationDocument, baseOptions);
}
export type DuplicateConfigurationMutationHookResult = ReturnType<
  typeof useDuplicateConfigurationMutation
>;
export type DuplicateConfigurationMutationResult = Apollo.MutationResult<DuplicateConfigurationMutation>;
export type DuplicateConfigurationMutationOptions = Apollo.BaseMutationOptions<
  DuplicateConfigurationMutation,
  DuplicateConfigurationMutationVariables
>;
export const MoveConfigurationToCollaborationDocument = gql`
  mutation moveConfigurationToCollaboration(
    $configurationUuid: String!
    $name: String!
    $description: String
  ) {
    moveConfigurationToCollaboration(
      uuid: $configurationUuid
      name: $name
      description: $description
    ) {
      name
      uuid
      simulationResults
      description
      user
      timestamp
      readOnly
      warnings
      configType: type
      project {
        uuid
      }
      scenarioData {
        representation {
          serialized
        }
      }
    }
  }
`;
export type MoveConfigurationToCollaborationMutationFn = Apollo.MutationFunction<
  MoveConfigurationToCollaborationMutation,
  MoveConfigurationToCollaborationMutationVariables
>;

/**
 * __useMoveConfigurationToCollaborationMutation__
 *
 * To run a mutation, you first call `useMoveConfigurationToCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveConfigurationToCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveConfigurationToCollaborationMutation, { data, loading, error }] = useMoveConfigurationToCollaborationMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useMoveConfigurationToCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveConfigurationToCollaborationMutation,
    MoveConfigurationToCollaborationMutationVariables
  >,
) {
  return Apollo.useMutation<
    MoveConfigurationToCollaborationMutation,
    MoveConfigurationToCollaborationMutationVariables
  >(MoveConfigurationToCollaborationDocument, baseOptions);
}
export type MoveConfigurationToCollaborationMutationHookResult = ReturnType<
  typeof useMoveConfigurationToCollaborationMutation
>;
export type MoveConfigurationToCollaborationMutationResult = Apollo.MutationResult<MoveConfigurationToCollaborationMutation>;
export type MoveConfigurationToCollaborationMutationOptions = Apollo.BaseMutationOptions<
  MoveConfigurationToCollaborationMutation,
  MoveConfigurationToCollaborationMutationVariables
>;
export const PartialUpdateConfigurationDocument = gql`
  mutation partialUpdateConfiguration($configurationUuid: String!, $partialScenario: AreaInput!) {
    partialUpdateConfiguration(uuid: $configurationUuid, partialScenario: $partialScenario) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type PartialUpdateConfigurationMutationFn = Apollo.MutationFunction<
  PartialUpdateConfigurationMutation,
  PartialUpdateConfigurationMutationVariables
>;

/**
 * __usePartialUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `usePartialUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePartialUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partialUpdateConfigurationMutation, { data, loading, error }] = usePartialUpdateConfigurationMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      partialScenario: // value for 'partialScenario'
 *   },
 * });
 */
export function usePartialUpdateConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PartialUpdateConfigurationMutation,
    PartialUpdateConfigurationMutationVariables
  >,
) {
  return Apollo.useMutation<
    PartialUpdateConfigurationMutation,
    PartialUpdateConfigurationMutationVariables
  >(PartialUpdateConfigurationDocument, baseOptions);
}
export type PartialUpdateConfigurationMutationHookResult = ReturnType<
  typeof usePartialUpdateConfigurationMutation
>;
export type PartialUpdateConfigurationMutationResult = Apollo.MutationResult<PartialUpdateConfigurationMutation>;
export type PartialUpdateConfigurationMutationOptions = Apollo.BaseMutationOptions<
  PartialUpdateConfigurationMutation,
  PartialUpdateConfigurationMutationVariables
>;
export const RemoveConfigurationAreaDocument = gql`
  mutation removeConfigurationArea($configurationUuid: String!, $areaUuid: String!) {
    removeConfigurationArea(configUuid: $configurationUuid, areaUuid: $areaUuid) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type RemoveConfigurationAreaMutationFn = Apollo.MutationFunction<
  RemoveConfigurationAreaMutation,
  RemoveConfigurationAreaMutationVariables
>;

/**
 * __useRemoveConfigurationAreaMutation__
 *
 * To run a mutation, you first call `useRemoveConfigurationAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConfigurationAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConfigurationAreaMutation, { data, loading, error }] = useRemoveConfigurationAreaMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      areaUuid: // value for 'areaUuid'
 *   },
 * });
 */
export function useRemoveConfigurationAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveConfigurationAreaMutation,
    RemoveConfigurationAreaMutationVariables
  >,
) {
  return Apollo.useMutation<
    RemoveConfigurationAreaMutation,
    RemoveConfigurationAreaMutationVariables
  >(RemoveConfigurationAreaDocument, baseOptions);
}
export type RemoveConfigurationAreaMutationHookResult = ReturnType<
  typeof useRemoveConfigurationAreaMutation
>;
export type RemoveConfigurationAreaMutationResult = Apollo.MutationResult<RemoveConfigurationAreaMutation>;
export type RemoveConfigurationAreaMutationOptions = Apollo.BaseMutationOptions<
  RemoveConfigurationAreaMutation,
  RemoveConfigurationAreaMutationVariables
>;
export const UpdateConfigurationDocument = gql`
  mutation updateConfiguration(
    $name: String
    $description: String
    $configurationUuid: String!
    $projectUuid: String!
    $settingsData: SettingsInput
    $scenarioData: ScenarioInput
    $timezone: String
    $private: Boolean
  ) {
    updateConfiguration(
      uuid: $configurationUuid
      name: $name
      description: $description
      projectUUID: $projectUuid
      settingsData: $settingsData
      scenarioData: $scenarioData
      timezone: $timezone
      private: $private
    ) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type UpdateConfigurationMutationFn = Apollo.MutationFunction<
  UpdateConfigurationMutation,
  UpdateConfigurationMutationVariables
>;

/**
 * __useUpdateConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationMutation, { data, loading, error }] = useUpdateConfigurationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      configurationUuid: // value for 'configurationUuid'
 *      projectUuid: // value for 'projectUuid'
 *      settingsData: // value for 'settingsData'
 *      scenarioData: // value for 'scenarioData'
 *      timezone: // value for 'timezone'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useUpdateConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConfigurationMutation,
    UpdateConfigurationMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateConfigurationMutation, UpdateConfigurationMutationVariables>(
    UpdateConfigurationDocument,
    baseOptions,
  );
}
export type UpdateConfigurationMutationHookResult = ReturnType<
  typeof useUpdateConfigurationMutation
>;
export type UpdateConfigurationMutationResult = Apollo.MutationResult<UpdateConfigurationMutation>;
export type UpdateConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdateConfigurationMutation,
  UpdateConfigurationMutationVariables
>;
export const UpdateConfigurationAreaDocument = gql`
  mutation updateConfigurationArea(
    $configurationUuid: String!
    $areaUuid: String!
    $areaParams: AreaInput!
  ) {
    updateConfigurationArea(
      configUuid: $configurationUuid
      areaUuid: $areaUuid
      areaParams: $areaParams
    ) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type UpdateConfigurationAreaMutationFn = Apollo.MutationFunction<
  UpdateConfigurationAreaMutation,
  UpdateConfigurationAreaMutationVariables
>;

/**
 * __useUpdateConfigurationAreaMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationAreaMutation, { data, loading, error }] = useUpdateConfigurationAreaMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      areaUuid: // value for 'areaUuid'
 *      areaParams: // value for 'areaParams'
 *   },
 * });
 */
export function useUpdateConfigurationAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConfigurationAreaMutation,
    UpdateConfigurationAreaMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateConfigurationAreaMutation,
    UpdateConfigurationAreaMutationVariables
  >(UpdateConfigurationAreaDocument, baseOptions);
}
export type UpdateConfigurationAreaMutationHookResult = ReturnType<
  typeof useUpdateConfigurationAreaMutation
>;
export type UpdateConfigurationAreaMutationResult = Apollo.MutationResult<UpdateConfigurationAreaMutation>;
export type UpdateConfigurationAreaMutationOptions = Apollo.BaseMutationOptions<
  UpdateConfigurationAreaMutation,
  UpdateConfigurationAreaMutationVariables
>;
export const UpdateConfigurationTreeDocument = gql`
  mutation updateConfigurationTree($configurationUuid: String!, $scenarioData: ScenarioInput!) {
    updateConfiguration(uuid: $configurationUuid, scenarioData: $scenarioData) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type UpdateConfigurationTreeMutationFn = Apollo.MutationFunction<
  UpdateConfigurationTreeMutation,
  UpdateConfigurationTreeMutationVariables
>;

/**
 * __useUpdateConfigurationTreeMutation__
 *
 * To run a mutation, you first call `useUpdateConfigurationTreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConfigurationTreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConfigurationTreeMutation, { data, loading, error }] = useUpdateConfigurationTreeMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      scenarioData: // value for 'scenarioData'
 *   },
 * });
 */
export function useUpdateConfigurationTreeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConfigurationTreeMutation,
    UpdateConfigurationTreeMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateConfigurationTreeMutation,
    UpdateConfigurationTreeMutationVariables
  >(UpdateConfigurationTreeDocument, baseOptions);
}
export type UpdateConfigurationTreeMutationHookResult = ReturnType<
  typeof useUpdateConfigurationTreeMutation
>;
export type UpdateConfigurationTreeMutationResult = Apollo.MutationResult<UpdateConfigurationTreeMutation>;
export type UpdateConfigurationTreeMutationOptions = Apollo.BaseMutationOptions<
  UpdateConfigurationTreeMutation,
  UpdateConfigurationTreeMutationVariables
>;
export const UserContactFormDocument = gql`
  mutation userContactForm(
    $firstName: String
    $lastName: String
    $email: String
    $message: String
  ) {
    userContactForm(firstName: $firstName, lastName: $lastName, email: $email, message: $message)
  }
`;
export type UserContactFormMutationFn = Apollo.MutationFunction<
  UserContactFormMutation,
  UserContactFormMutationVariables
>;

/**
 * __useUserContactFormMutation__
 *
 * To run a mutation, you first call `useUserContactFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserContactFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userContactFormMutation, { data, loading, error }] = useUserContactFormMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUserContactFormMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserContactFormMutation,
    UserContactFormMutationVariables
  >,
) {
  return Apollo.useMutation<UserContactFormMutation, UserContactFormMutationVariables>(
    UserContactFormDocument,
    baseOptions,
  );
}
export type UserContactFormMutationHookResult = ReturnType<typeof useUserContactFormMutation>;
export type UserContactFormMutationResult = Apollo.MutationResult<UserContactFormMutation>;
export type UserContactFormMutationOptions = Apollo.BaseMutationOptions<
  UserContactFormMutation,
  UserContactFormMutationVariables
>;
export const DeleteGlobalSimulationLocationDocument = gql`
  mutation deleteGlobalSimulationLocation($configUuid: String!) {
    deleteGlobalSimulationLocation(configUuid: $configUuid)
  }
`;
export type DeleteGlobalSimulationLocationMutationFn = Apollo.MutationFunction<
  DeleteGlobalSimulationLocationMutation,
  DeleteGlobalSimulationLocationMutationVariables
>;

/**
 * __useDeleteGlobalSimulationLocationMutation__
 *
 * To run a mutation, you first call `useDeleteGlobalSimulationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalSimulationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalSimulationLocationMutation, { data, loading, error }] = useDeleteGlobalSimulationLocationMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useDeleteGlobalSimulationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGlobalSimulationLocationMutation,
    DeleteGlobalSimulationLocationMutationVariables
  >,
) {
  return Apollo.useMutation<
    DeleteGlobalSimulationLocationMutation,
    DeleteGlobalSimulationLocationMutationVariables
  >(DeleteGlobalSimulationLocationDocument, baseOptions);
}
export type DeleteGlobalSimulationLocationMutationHookResult = ReturnType<
  typeof useDeleteGlobalSimulationLocationMutation
>;
export type DeleteGlobalSimulationLocationMutationResult = Apollo.MutationResult<DeleteGlobalSimulationLocationMutation>;
export type DeleteGlobalSimulationLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteGlobalSimulationLocationMutation,
  DeleteGlobalSimulationLocationMutationVariables
>;
export const SetGlobalSimulationLocationDocument = gql`
  mutation setGlobalSimulationLocation(
    $configUuid: String!
    $centreCoordinates: Position
    $boundaryId: String!
    $visibility: Boolean!
  ) {
    setGlobalSimulationLocation(
      configUuid: $configUuid
      centreCoordinates: $centreCoordinates
      boundaryId: $boundaryId
      visibility: $visibility
    )
  }
`;
export type SetGlobalSimulationLocationMutationFn = Apollo.MutationFunction<
  SetGlobalSimulationLocationMutation,
  SetGlobalSimulationLocationMutationVariables
>;

/**
 * __useSetGlobalSimulationLocationMutation__
 *
 * To run a mutation, you first call `useSetGlobalSimulationLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGlobalSimulationLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGlobalSimulationLocationMutation, { data, loading, error }] = useSetGlobalSimulationLocationMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      centreCoordinates: // value for 'centreCoordinates'
 *      boundaryId: // value for 'boundaryId'
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useSetGlobalSimulationLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetGlobalSimulationLocationMutation,
    SetGlobalSimulationLocationMutationVariables
  >,
) {
  return Apollo.useMutation<
    SetGlobalSimulationLocationMutation,
    SetGlobalSimulationLocationMutationVariables
  >(SetGlobalSimulationLocationDocument, baseOptions);
}
export type SetGlobalSimulationLocationMutationHookResult = ReturnType<
  typeof useSetGlobalSimulationLocationMutation
>;
export type SetGlobalSimulationLocationMutationResult = Apollo.MutationResult<SetGlobalSimulationLocationMutation>;
export type SetGlobalSimulationLocationMutationOptions = Apollo.BaseMutationOptions<
  SetGlobalSimulationLocationMutation,
  SetGlobalSimulationLocationMutationVariables
>;
export const ToggleGlobalSimulationVisibilityDocument = gql`
  mutation toggleGlobalSimulationVisibility($configUuid: String!) {
    toggleGlobalSimulationVisibility(configUuid: $configUuid) {
      configUuid
      boundaryId
      visibility
      configType: type
    }
  }
`;
export type ToggleGlobalSimulationVisibilityMutationFn = Apollo.MutationFunction<
  ToggleGlobalSimulationVisibilityMutation,
  ToggleGlobalSimulationVisibilityMutationVariables
>;

/**
 * __useToggleGlobalSimulationVisibilityMutation__
 *
 * To run a mutation, you first call `useToggleGlobalSimulationVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleGlobalSimulationVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleGlobalSimulationVisibilityMutation, { data, loading, error }] = useToggleGlobalSimulationVisibilityMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useToggleGlobalSimulationVisibilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleGlobalSimulationVisibilityMutation,
    ToggleGlobalSimulationVisibilityMutationVariables
  >,
) {
  return Apollo.useMutation<
    ToggleGlobalSimulationVisibilityMutation,
    ToggleGlobalSimulationVisibilityMutationVariables
  >(ToggleGlobalSimulationVisibilityDocument, baseOptions);
}
export type ToggleGlobalSimulationVisibilityMutationHookResult = ReturnType<
  typeof useToggleGlobalSimulationVisibilityMutation
>;
export type ToggleGlobalSimulationVisibilityMutationResult = Apollo.MutationResult<ToggleGlobalSimulationVisibilityMutation>;
export type ToggleGlobalSimulationVisibilityMutationOptions = Apollo.BaseMutationOptions<
  ToggleGlobalSimulationVisibilityMutation,
  ToggleGlobalSimulationVisibilityMutationVariables
>;
export const UpdateUserSimulationVisibilityDocument = gql`
  mutation updateUserSimulationVisibility($visibility: Boolean!) {
    updateUserSimulationVisibility(visibility: $visibility) {
      username
      visibility
    }
  }
`;
export type UpdateUserSimulationVisibilityMutationFn = Apollo.MutationFunction<
  UpdateUserSimulationVisibilityMutation,
  UpdateUserSimulationVisibilityMutationVariables
>;

/**
 * __useUpdateUserSimulationVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateUserSimulationVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSimulationVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSimulationVisibilityMutation, { data, loading, error }] = useUpdateUserSimulationVisibilityMutation({
 *   variables: {
 *      visibility: // value for 'visibility'
 *   },
 * });
 */
export function useUpdateUserSimulationVisibilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserSimulationVisibilityMutation,
    UpdateUserSimulationVisibilityMutationVariables
  >,
) {
  return Apollo.useMutation<
    UpdateUserSimulationVisibilityMutation,
    UpdateUserSimulationVisibilityMutationVariables
  >(UpdateUserSimulationVisibilityDocument, baseOptions);
}
export type UpdateUserSimulationVisibilityMutationHookResult = ReturnType<
  typeof useUpdateUserSimulationVisibilityMutation
>;
export type UpdateUserSimulationVisibilityMutationResult = Apollo.MutationResult<UpdateUserSimulationVisibilityMutation>;
export type UpdateUserSimulationVisibilityMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserSimulationVisibilityMutation,
  UpdateUserSimulationVisibilityMutationVariables
>;
export const CreateLibraryDocument = gql`
  mutation createLibrary(
    $name: String
    $scenarioData: ScenarioInput
    $isPrivate: Boolean
    $description: String
    $tags: [String]
  ) {
    createLibrary(
      name: $name
      scenarioData: $scenarioData
      isPrivate: $isPrivate
      description: $description
      tags: $tags
    ) {
      uuid
    }
  }
`;
export type CreateLibraryMutationFn = Apollo.MutationFunction<
  CreateLibraryMutation,
  CreateLibraryMutationVariables
>;

/**
 * __useCreateLibraryMutation__
 *
 * To run a mutation, you first call `useCreateLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLibraryMutation, { data, loading, error }] = useCreateLibraryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      scenarioData: // value for 'scenarioData'
 *      isPrivate: // value for 'isPrivate'
 *      description: // value for 'description'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateLibraryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLibraryMutation, CreateLibraryMutationVariables>,
) {
  return Apollo.useMutation<CreateLibraryMutation, CreateLibraryMutationVariables>(
    CreateLibraryDocument,
    baseOptions,
  );
}
export type CreateLibraryMutationHookResult = ReturnType<typeof useCreateLibraryMutation>;
export type CreateLibraryMutationResult = Apollo.MutationResult<CreateLibraryMutation>;
export type CreateLibraryMutationOptions = Apollo.BaseMutationOptions<
  CreateLibraryMutation,
  CreateLibraryMutationVariables
>;
export const DeleteLibraryDocument = gql`
  mutation deleteLibrary($uuid: String!) {
    deleteLibrary(uuid: $uuid)
  }
`;
export type DeleteLibraryMutationFn = Apollo.MutationFunction<
  DeleteLibraryMutation,
  DeleteLibraryMutationVariables
>;

/**
 * __useDeleteLibraryMutation__
 *
 * To run a mutation, you first call `useDeleteLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLibraryMutation, { data, loading, error }] = useDeleteLibraryMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteLibraryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLibraryMutation, DeleteLibraryMutationVariables>,
) {
  return Apollo.useMutation<DeleteLibraryMutation, DeleteLibraryMutationVariables>(
    DeleteLibraryDocument,
    baseOptions,
  );
}
export type DeleteLibraryMutationHookResult = ReturnType<typeof useDeleteLibraryMutation>;
export type DeleteLibraryMutationResult = Apollo.MutationResult<DeleteLibraryMutation>;
export type DeleteLibraryMutationOptions = Apollo.BaseMutationOptions<
  DeleteLibraryMutation,
  DeleteLibraryMutationVariables
>;
export const DuplicateLibraryDocument = gql`
  mutation duplicateLibrary($uuid: String!, $isPrivate: Boolean!) {
    duplicateLibrary(uuid: $uuid, isPrivate: $isPrivate) {
      name
      uuid
      description
      user
      private
      readOnly
      tags
      libraryType
      scenarioData {
        deviceCountNumber
        areaCountNumber
        representation {
          ... on PV {
            serialized
            type
            uuid
          }
          ... on Storage {
            serialized
            type
            uuid
          }
          ... on Load {
            serialized
            type
            uuid
          }
          ... on FiniteDieselGenerator {
            serialized
            type
            uuid
          }
          ... on MarketMaker {
            serialized
            type
            uuid
          }
          ... on InfiniteBus {
            serialized
            type
            uuid
          }
          ... on AreaOutput {
            serialized
            type
            uuid
          }
          ... on SmartMeter {
            serialized
            type
            uuid
          }
        }
      }
    }
  }
`;
export type DuplicateLibraryMutationFn = Apollo.MutationFunction<
  DuplicateLibraryMutation,
  DuplicateLibraryMutationVariables
>;

/**
 * __useDuplicateLibraryMutation__
 *
 * To run a mutation, you first call `useDuplicateLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateLibraryMutation, { data, loading, error }] = useDuplicateLibraryMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      isPrivate: // value for 'isPrivate'
 *   },
 * });
 */
export function useDuplicateLibraryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateLibraryMutation,
    DuplicateLibraryMutationVariables
  >,
) {
  return Apollo.useMutation<DuplicateLibraryMutation, DuplicateLibraryMutationVariables>(
    DuplicateLibraryDocument,
    baseOptions,
  );
}
export type DuplicateLibraryMutationHookResult = ReturnType<typeof useDuplicateLibraryMutation>;
export type DuplicateLibraryMutationResult = Apollo.MutationResult<DuplicateLibraryMutation>;
export type DuplicateLibraryMutationOptions = Apollo.BaseMutationOptions<
  DuplicateLibraryMutation,
  DuplicateLibraryMutationVariables
>;
export const PostDuplicationLibraryDocument = gql`
  mutation postDuplicationLibrary($uuid: String!, $name: String!, $description: String) {
    updateLibrary(uuid: $uuid, name: $name, description: $description) {
      uuid
      name
      description
    }
  }
`;
export type PostDuplicationLibraryMutationFn = Apollo.MutationFunction<
  PostDuplicationLibraryMutation,
  PostDuplicationLibraryMutationVariables
>;

/**
 * __usePostDuplicationLibraryMutation__
 *
 * To run a mutation, you first call `usePostDuplicationLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostDuplicationLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postDuplicationLibraryMutation, { data, loading, error }] = usePostDuplicationLibraryMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function usePostDuplicationLibraryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PostDuplicationLibraryMutation,
    PostDuplicationLibraryMutationVariables
  >,
) {
  return Apollo.useMutation<
    PostDuplicationLibraryMutation,
    PostDuplicationLibraryMutationVariables
  >(PostDuplicationLibraryDocument, baseOptions);
}
export type PostDuplicationLibraryMutationHookResult = ReturnType<
  typeof usePostDuplicationLibraryMutation
>;
export type PostDuplicationLibraryMutationResult = Apollo.MutationResult<PostDuplicationLibraryMutation>;
export type PostDuplicationLibraryMutationOptions = Apollo.BaseMutationOptions<
  PostDuplicationLibraryMutation,
  PostDuplicationLibraryMutationVariables
>;
export const UpdateLibraryDocument = gql`
  mutation updateLibrary($uuid: String!, $scenarioData: ScenarioInput!) {
    updateLibrary(uuid: $uuid, scenarioData: $scenarioData) {
      name
      readOnly
      scenarioData {
        deviceCountNumber
        areaCountNumber
        representation {
          ... on PV {
            serialized
          }
          ... on Storage {
            serialized
          }
          ... on Load {
            serialized
          }
          ... on FiniteDieselGenerator {
            serialized
          }
          ... on MarketMaker {
            serialized
          }
          ... on InfiniteBus {
            serialized
          }
          ... on AreaOutput {
            serialized
          }
        }
      }
    }
  }
`;
export type UpdateLibraryMutationFn = Apollo.MutationFunction<
  UpdateLibraryMutation,
  UpdateLibraryMutationVariables
>;

/**
 * __useUpdateLibraryMutation__
 *
 * To run a mutation, you first call `useUpdateLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLibraryMutation, { data, loading, error }] = useUpdateLibraryMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      scenarioData: // value for 'scenarioData'
 *   },
 * });
 */
export function useUpdateLibraryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLibraryMutation, UpdateLibraryMutationVariables>,
) {
  return Apollo.useMutation<UpdateLibraryMutation, UpdateLibraryMutationVariables>(
    UpdateLibraryDocument,
    baseOptions,
  );
}
export type UpdateLibraryMutationHookResult = ReturnType<typeof useUpdateLibraryMutation>;
export type UpdateLibraryMutationResult = Apollo.MutationResult<UpdateLibraryMutation>;
export type UpdateLibraryMutationOptions = Apollo.BaseMutationOptions<
  UpdateLibraryMutation,
  UpdateLibraryMutationVariables
>;
export const UpdateLibraryDataDocument = gql`
  mutation updateLibraryData($uuid: String!, $name: String!, $description: String) {
    updateLibrary(uuid: $uuid, name: $name, description: $description) {
      uuid
      name
      description
    }
  }
`;
export type UpdateLibraryDataMutationFn = Apollo.MutationFunction<
  UpdateLibraryDataMutation,
  UpdateLibraryDataMutationVariables
>;

/**
 * __useUpdateLibraryDataMutation__
 *
 * To run a mutation, you first call `useUpdateLibraryDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLibraryDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLibraryDataMutation, { data, loading, error }] = useUpdateLibraryDataMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateLibraryDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLibraryDataMutation,
    UpdateLibraryDataMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateLibraryDataMutation, UpdateLibraryDataMutationVariables>(
    UpdateLibraryDataDocument,
    baseOptions,
  );
}
export type UpdateLibraryDataMutationHookResult = ReturnType<typeof useUpdateLibraryDataMutation>;
export type UpdateLibraryDataMutationResult = Apollo.MutationResult<UpdateLibraryDataMutation>;
export type UpdateLibraryDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateLibraryDataMutation,
  UpdateLibraryDataMutationVariables
>;
export const BulkAreaLiveEventDocument = gql`
  mutation bulkAreaLiveEvent($configurationUuid: String!, $eventList: [LiveEventInput]) {
    bulkAreaLiveEvent(configUuid: $configurationUuid, eventList: $eventList) {
      latestConfig {
        ...ConfigurationFields
      }
      eventList {
        eventType
        parameters
        timeStamp
        diff
      }
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type BulkAreaLiveEventMutationFn = Apollo.MutationFunction<
  BulkAreaLiveEventMutation,
  BulkAreaLiveEventMutationVariables
>;

/**
 * __useBulkAreaLiveEventMutation__
 *
 * To run a mutation, you first call `useBulkAreaLiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAreaLiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAreaLiveEventMutation, { data, loading, error }] = useBulkAreaLiveEventMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      eventList: // value for 'eventList'
 *   },
 * });
 */
export function useBulkAreaLiveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BulkAreaLiveEventMutation,
    BulkAreaLiveEventMutationVariables
  >,
) {
  return Apollo.useMutation<BulkAreaLiveEventMutation, BulkAreaLiveEventMutationVariables>(
    BulkAreaLiveEventDocument,
    baseOptions,
  );
}
export type BulkAreaLiveEventMutationHookResult = ReturnType<typeof useBulkAreaLiveEventMutation>;
export type BulkAreaLiveEventMutationResult = Apollo.MutationResult<BulkAreaLiveEventMutation>;
export type BulkAreaLiveEventMutationOptions = Apollo.BaseMutationOptions<
  BulkAreaLiveEventMutation,
  BulkAreaLiveEventMutationVariables
>;
export const CreateAreaLiveEventDocument = gql`
  mutation createAreaLiveEvent(
    $configurationUuid: String!
    $parentUuid: String!
    $areaRepresentation: AreaInput
    $library: LiveEventLibraryInput
  ) {
    createAreaLiveEvent(
      configUuid: $configurationUuid
      parentUuid: $parentUuid
      areaRepresentation: $areaRepresentation
      library: $library
    ) {
      latestConfig {
        ...ConfigurationFields
      }
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type CreateAreaLiveEventMutationFn = Apollo.MutationFunction<
  CreateAreaLiveEventMutation,
  CreateAreaLiveEventMutationVariables
>;

/**
 * __useCreateAreaLiveEventMutation__
 *
 * To run a mutation, you first call `useCreateAreaLiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAreaLiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAreaLiveEventMutation, { data, loading, error }] = useCreateAreaLiveEventMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      parentUuid: // value for 'parentUuid'
 *      areaRepresentation: // value for 'areaRepresentation'
 *      library: // value for 'library'
 *   },
 * });
 */
export function useCreateAreaLiveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAreaLiveEventMutation,
    CreateAreaLiveEventMutationVariables
  >,
) {
  return Apollo.useMutation<CreateAreaLiveEventMutation, CreateAreaLiveEventMutationVariables>(
    CreateAreaLiveEventDocument,
    baseOptions,
  );
}
export type CreateAreaLiveEventMutationHookResult = ReturnType<
  typeof useCreateAreaLiveEventMutation
>;
export type CreateAreaLiveEventMutationResult = Apollo.MutationResult<CreateAreaLiveEventMutation>;
export type CreateAreaLiveEventMutationOptions = Apollo.BaseMutationOptions<
  CreateAreaLiveEventMutation,
  CreateAreaLiveEventMutationVariables
>;
export const DeleteAreaLiveEventDocument = gql`
  mutation deleteAreaLiveEvent($configurationUuid: String!, $areaUuid: String!) {
    deleteAreaLiveEvent(configUuid: $configurationUuid, areaUuid: $areaUuid) {
      latestConfig {
        ...ConfigurationFields
      }
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type DeleteAreaLiveEventMutationFn = Apollo.MutationFunction<
  DeleteAreaLiveEventMutation,
  DeleteAreaLiveEventMutationVariables
>;

/**
 * __useDeleteAreaLiveEventMutation__
 *
 * To run a mutation, you first call `useDeleteAreaLiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAreaLiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAreaLiveEventMutation, { data, loading, error }] = useDeleteAreaLiveEventMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      areaUuid: // value for 'areaUuid'
 *   },
 * });
 */
export function useDeleteAreaLiveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAreaLiveEventMutation,
    DeleteAreaLiveEventMutationVariables
  >,
) {
  return Apollo.useMutation<DeleteAreaLiveEventMutation, DeleteAreaLiveEventMutationVariables>(
    DeleteAreaLiveEventDocument,
    baseOptions,
  );
}
export type DeleteAreaLiveEventMutationHookResult = ReturnType<
  typeof useDeleteAreaLiveEventMutation
>;
export type DeleteAreaLiveEventMutationResult = Apollo.MutationResult<DeleteAreaLiveEventMutation>;
export type DeleteAreaLiveEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteAreaLiveEventMutation,
  DeleteAreaLiveEventMutationVariables
>;
export const UpdateAreaLiveEventDocument = gql`
  mutation updateAreaLiveEvent(
    $configurationUuid: String!
    $areaUuid: String!
    $areaParams: AreaInput!
  ) {
    updateAreaLiveEvent(
      configUuid: $configurationUuid
      areaUuid: $areaUuid
      areaParams: $areaParams
    ) {
      latestConfig {
        ...ConfigurationFields
      }
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;
export type UpdateAreaLiveEventMutationFn = Apollo.MutationFunction<
  UpdateAreaLiveEventMutation,
  UpdateAreaLiveEventMutationVariables
>;

/**
 * __useUpdateAreaLiveEventMutation__
 *
 * To run a mutation, you first call `useUpdateAreaLiveEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaLiveEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaLiveEventMutation, { data, loading, error }] = useUpdateAreaLiveEventMutation({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      areaUuid: // value for 'areaUuid'
 *      areaParams: // value for 'areaParams'
 *   },
 * });
 */
export function useUpdateAreaLiveEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAreaLiveEventMutation,
    UpdateAreaLiveEventMutationVariables
  >,
) {
  return Apollo.useMutation<UpdateAreaLiveEventMutation, UpdateAreaLiveEventMutationVariables>(
    UpdateAreaLiveEventDocument,
    baseOptions,
  );
}
export type UpdateAreaLiveEventMutationHookResult = ReturnType<
  typeof useUpdateAreaLiveEventMutation
>;
export type UpdateAreaLiveEventMutationResult = Apollo.MutationResult<UpdateAreaLiveEventMutation>;
export type UpdateAreaLiveEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateAreaLiveEventMutation,
  UpdateAreaLiveEventMutationVariables
>;
export const SendCommunityNotificationDocument = gql`
  mutation sendCommunityNotification(
    $configUuid: String!
    $message: String
    $selectedMemberNames: [String]
    $title: String
    $uploadedFiles: [NotificationFileUploadInput]
  ) {
    sendCommunityNotification(
      configUuid: $configUuid
      message: $message
      selectedMemberNames: $selectedMemberNames
      title: $title
      uploadedFiles: $uploadedFiles
    )
  }
`;
export type SendCommunityNotificationMutationFn = Apollo.MutationFunction<
  SendCommunityNotificationMutation,
  SendCommunityNotificationMutationVariables
>;

/**
 * __useSendCommunityNotificationMutation__
 *
 * To run a mutation, you first call `useSendCommunityNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendCommunityNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendCommunityNotificationMutation, { data, loading, error }] = useSendCommunityNotificationMutation({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      message: // value for 'message'
 *      selectedMemberNames: // value for 'selectedMemberNames'
 *      title: // value for 'title'
 *      uploadedFiles: // value for 'uploadedFiles'
 *   },
 * });
 */
export function useSendCommunityNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendCommunityNotificationMutation,
    SendCommunityNotificationMutationVariables
  >,
) {
  return Apollo.useMutation<
    SendCommunityNotificationMutation,
    SendCommunityNotificationMutationVariables
  >(SendCommunityNotificationDocument, baseOptions);
}
export type SendCommunityNotificationMutationHookResult = ReturnType<
  typeof useSendCommunityNotificationMutation
>;
export type SendCommunityNotificationMutationResult = Apollo.MutationResult<SendCommunityNotificationMutation>;
export type SendCommunityNotificationMutationOptions = Apollo.BaseMutationOptions<
  SendCommunityNotificationMutation,
  SendCommunityNotificationMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject(
    $centreCoordinates: Position
    $description: String
    $locationVisible: Boolean
    $name: String!
    $private: Boolean
  ) {
    createProject(
      centreCoordinates: $centreCoordinates
      description: $description
      locationVisible: $locationVisible
      name: $name
      private: $private
    ) {
      name
      uuid
      description
      private
      user
      configurations {
        uuid
      }
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      centreCoordinates: // value for 'centreCoordinates'
 *      description: // value for 'description'
 *      locationVisible: // value for 'locationVisible'
 *      name: // value for 'name'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>,
) {
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    CreateProjectDocument,
    baseOptions,
  );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($projectUuid: String!) {
    deleteProject(uuid: $projectUuid)
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectUuid: // value for 'projectUuid'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>,
) {
  return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    DeleteProjectDocument,
    baseOptions,
  );
}
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject(
    $projectUuid: String!
    $centreCoordinates: Position
    $description: String
    $locationVisible: Boolean
    $name: String
    $private: Boolean
  ) {
    updateProject(
      uuid: $projectUuid
      centreCoordinates: $centreCoordinates
      description: $description
      locationVisible: $locationVisible
      name: $name
      private: $private
    ) {
      name
      uuid
      user
      timestamp
      private
      latitude
      longitude
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      projectUuid: // value for 'projectUuid'
 *      centreCoordinates: // value for 'centreCoordinates'
 *      description: // value for 'description'
 *      locationVisible: // value for 'locationVisible'
 *      name: // value for 'name'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>,
) {
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    baseOptions,
  );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const LaunchSimulationDocument = gql`
  mutation launchSimulation($uuid: String!) {
    launchSimulation(uuid: $uuid) {
      job
      status
      configUUID
    }
  }
`;
export type LaunchSimulationMutationFn = Apollo.MutationFunction<
  LaunchSimulationMutation,
  LaunchSimulationMutationVariables
>;

/**
 * __useLaunchSimulationMutation__
 *
 * To run a mutation, you first call `useLaunchSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLaunchSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [launchSimulationMutation, { data, loading, error }] = useLaunchSimulationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useLaunchSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LaunchSimulationMutation,
    LaunchSimulationMutationVariables
  >,
) {
  return Apollo.useMutation<LaunchSimulationMutation, LaunchSimulationMutationVariables>(
    LaunchSimulationDocument,
    baseOptions,
  );
}
export type LaunchSimulationMutationHookResult = ReturnType<typeof useLaunchSimulationMutation>;
export type LaunchSimulationMutationResult = Apollo.MutationResult<LaunchSimulationMutation>;
export type LaunchSimulationMutationOptions = Apollo.BaseMutationOptions<
  LaunchSimulationMutation,
  LaunchSimulationMutationVariables
>;
export const PauseSimulationDocument = gql`
  mutation pauseSimulation($jobId: String!) {
    pauseSimulation(jobId: $jobId)
  }
`;
export type PauseSimulationMutationFn = Apollo.MutationFunction<
  PauseSimulationMutation,
  PauseSimulationMutationVariables
>;

/**
 * __usePauseSimulationMutation__
 *
 * To run a mutation, you first call `usePauseSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseSimulationMutation, { data, loading, error }] = usePauseSimulationMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function usePauseSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PauseSimulationMutation,
    PauseSimulationMutationVariables
  >,
) {
  return Apollo.useMutation<PauseSimulationMutation, PauseSimulationMutationVariables>(
    PauseSimulationDocument,
    baseOptions,
  );
}
export type PauseSimulationMutationHookResult = ReturnType<typeof usePauseSimulationMutation>;
export type PauseSimulationMutationResult = Apollo.MutationResult<PauseSimulationMutation>;
export type PauseSimulationMutationOptions = Apollo.BaseMutationOptions<
  PauseSimulationMutation,
  PauseSimulationMutationVariables
>;
export const ResumeSimulationDocument = gql`
  mutation resumeSimulation($jobId: String!) {
    resumeSimulation(jobId: $jobId)
  }
`;
export type ResumeSimulationMutationFn = Apollo.MutationFunction<
  ResumeSimulationMutation,
  ResumeSimulationMutationVariables
>;

/**
 * __useResumeSimulationMutation__
 *
 * To run a mutation, you first call `useResumeSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeSimulationMutation, { data, loading, error }] = useResumeSimulationMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useResumeSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResumeSimulationMutation,
    ResumeSimulationMutationVariables
  >,
) {
  return Apollo.useMutation<ResumeSimulationMutation, ResumeSimulationMutationVariables>(
    ResumeSimulationDocument,
    baseOptions,
  );
}
export type ResumeSimulationMutationHookResult = ReturnType<typeof useResumeSimulationMutation>;
export type ResumeSimulationMutationResult = Apollo.MutationResult<ResumeSimulationMutation>;
export type ResumeSimulationMutationOptions = Apollo.BaseMutationOptions<
  ResumeSimulationMutation,
  ResumeSimulationMutationVariables
>;
export const StopSimulationDocument = gql`
  mutation stopSimulation($jobId: String!) {
    stopSimulation(jobId: $jobId)
  }
`;
export type StopSimulationMutationFn = Apollo.MutationFunction<
  StopSimulationMutation,
  StopSimulationMutationVariables
>;

/**
 * __useStopSimulationMutation__
 *
 * To run a mutation, you first call `useStopSimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSimulationMutation, { data, loading, error }] = useStopSimulationMutation({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useStopSimulationMutation(
  baseOptions?: Apollo.MutationHookOptions<StopSimulationMutation, StopSimulationMutationVariables>,
) {
  return Apollo.useMutation<StopSimulationMutation, StopSimulationMutationVariables>(
    StopSimulationDocument,
    baseOptions,
  );
}
export type StopSimulationMutationHookResult = ReturnType<typeof useStopSimulationMutation>;
export type StopSimulationMutationResult = Apollo.MutationResult<StopSimulationMutation>;
export type StopSimulationMutationOptions = Apollo.BaseMutationOptions<
  StopSimulationMutation,
  StopSimulationMutationVariables
>;
export const AvailableRolesDocument = gql`
  query availableRoles {
    availableUserRoles {
      userRoles {
        roleName
        value
      }
    }
  }
`;

/**
 * __useAvailableRolesQuery__
 *
 * To run a query within a React component, call `useAvailableRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<AvailableRolesQuery, AvailableRolesQueryVariables>,
) {
  return Apollo.useQuery<AvailableRolesQuery, AvailableRolesQueryVariables>(
    AvailableRolesDocument,
    baseOptions,
  );
}
export function useAvailableRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailableRolesQuery, AvailableRolesQueryVariables>,
) {
  return Apollo.useLazyQuery<AvailableRolesQuery, AvailableRolesQueryVariables>(
    AvailableRolesDocument,
    baseOptions,
  );
}
export type AvailableRolesQueryHookResult = ReturnType<typeof useAvailableRolesQuery>;
export type AvailableRolesLazyQueryHookResult = ReturnType<typeof useAvailableRolesLazyQuery>;
export type AvailableRolesQueryResult = Apollo.QueryResult<
  AvailableRolesQuery,
  AvailableRolesQueryVariables
>;
export const ListAdminStatsDocument = gql`
  query listAdminStats(
    $startTime: DateTime
    $endTime: DateTime
    $emailSearch: String
    $userRoles: [String]
  ) {
    listAdminStats(
      startTime: $startTime
      endTime: $endTime
      emailSearch: $emailSearch
      userRoles: $userRoles
    ) {
      userActivity {
        newRegistration {
          username
          timestamp
        }
        loginDetail {
          timestamp
          durationMs
          username
        }
      }
    }
  }
`;

/**
 * __useListAdminStatsQuery__
 *
 * To run a query within a React component, call `useListAdminStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminStatsQuery({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      emailSearch: // value for 'emailSearch'
 *      userRoles: // value for 'userRoles'
 *   },
 * });
 */
export function useListAdminStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAdminStatsQuery, ListAdminStatsQueryVariables>,
) {
  return Apollo.useQuery<ListAdminStatsQuery, ListAdminStatsQueryVariables>(
    ListAdminStatsDocument,
    baseOptions,
  );
}
export function useListAdminStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAdminStatsQuery, ListAdminStatsQueryVariables>,
) {
  return Apollo.useLazyQuery<ListAdminStatsQuery, ListAdminStatsQueryVariables>(
    ListAdminStatsDocument,
    baseOptions,
  );
}
export type ListAdminStatsQueryHookResult = ReturnType<typeof useListAdminStatsQuery>;
export type ListAdminStatsLazyQueryHookResult = ReturnType<typeof useListAdminStatsLazyQuery>;
export type ListAdminStatsQueryResult = Apollo.QueryResult<
  ListAdminStatsQuery,
  ListAdminStatsQueryVariables
>;
export const ListAdminStatsWithRolesDocument = gql`
  query listAdminStatsWithRoles {
    listAdminStats {
      userDetails {
        id
        username
        joined: joinedDate
        numberOfProjects
        numberOfSimulations
        activeTimeInSeconds
        numberOfConfiguredAgents
        numberOfLibraries
        userRole
      }
    }
    availableUserRoles {
      userRoles {
        roleName
        value
      }
    }
  }
`;

/**
 * __useListAdminStatsWithRolesQuery__
 *
 * To run a query within a React component, call `useListAdminStatsWithRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminStatsWithRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminStatsWithRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminStatsWithRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAdminStatsWithRolesQuery,
    ListAdminStatsWithRolesQueryVariables
  >,
) {
  return Apollo.useQuery<ListAdminStatsWithRolesQuery, ListAdminStatsWithRolesQueryVariables>(
    ListAdminStatsWithRolesDocument,
    baseOptions,
  );
}
export function useListAdminStatsWithRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAdminStatsWithRolesQuery,
    ListAdminStatsWithRolesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListAdminStatsWithRolesQuery, ListAdminStatsWithRolesQueryVariables>(
    ListAdminStatsWithRolesDocument,
    baseOptions,
  );
}
export type ListAdminStatsWithRolesQueryHookResult = ReturnType<
  typeof useListAdminStatsWithRolesQuery
>;
export type ListAdminStatsWithRolesLazyQueryHookResult = ReturnType<
  typeof useListAdminStatsWithRolesLazyQuery
>;
export type ListAdminStatsWithRolesQueryResult = Apollo.QueryResult<
  ListAdminStatsWithRolesQuery,
  ListAdminStatsWithRolesQueryVariables
>;
export const ListAdminUserDetailsDocument = gql`
  query listAdminUserDetails(
    $search: String
    $skip: Int
    $first: Int
    $filterRole: UserRoleFilterField
    $orderBy: UserStatsOrderField
  ) {
    listAdminUserDetails(
      search: $search
      skip: $skip
      first: $first
      filterRole: $filterRole
      orderBy: $orderBy
    ) {
      userList {
        id
        username
        name
        email
        profilePicture
        userRole
        joinedDate
        numberOfProjects
        chosenRole
        numberOfSimulations
        numberOfConfiguredAgents
        numberOfLibraries
        numberOfCanaryNetworks
        numberOfCollaborations
        requestStatus
      }
      totalNumberOfUsers
    }
  }
`;

/**
 * __useListAdminUserDetailsQuery__
 *
 * To run a query within a React component, call `useListAdminUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminUserDetailsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filterRole: // value for 'filterRole'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListAdminUserDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAdminUserDetailsQuery,
    ListAdminUserDetailsQueryVariables
  >,
) {
  return Apollo.useQuery<ListAdminUserDetailsQuery, ListAdminUserDetailsQueryVariables>(
    ListAdminUserDetailsDocument,
    baseOptions,
  );
}
export function useListAdminUserDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAdminUserDetailsQuery,
    ListAdminUserDetailsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListAdminUserDetailsQuery, ListAdminUserDetailsQueryVariables>(
    ListAdminUserDetailsDocument,
    baseOptions,
  );
}
export type ListAdminUserDetailsQueryHookResult = ReturnType<typeof useListAdminUserDetailsQuery>;
export type ListAdminUserDetailsLazyQueryHookResult = ReturnType<
  typeof useListAdminUserDetailsLazyQuery
>;
export type ListAdminUserDetailsQueryResult = Apollo.QueryResult<
  ListAdminUserDetailsQuery,
  ListAdminUserDetailsQueryVariables
>;
export const ReadUserRoleDocument = gql`
  query readUserRole($email: String!) {
    readUserRole(email: $email) {
      roleName
      value
    }
  }
`;

/**
 * __useReadUserRoleQuery__
 *
 * To run a query within a React component, call `useReadUserRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserRoleQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useReadUserRoleQuery(
  baseOptions: Apollo.QueryHookOptions<ReadUserRoleQuery, ReadUserRoleQueryVariables>,
) {
  return Apollo.useQuery<ReadUserRoleQuery, ReadUserRoleQueryVariables>(
    ReadUserRoleDocument,
    baseOptions,
  );
}
export function useReadUserRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReadUserRoleQuery, ReadUserRoleQueryVariables>,
) {
  return Apollo.useLazyQuery<ReadUserRoleQuery, ReadUserRoleQueryVariables>(
    ReadUserRoleDocument,
    baseOptions,
  );
}
export type ReadUserRoleQueryHookResult = ReturnType<typeof useReadUserRoleQuery>;
export type ReadUserRoleLazyQueryHookResult = ReturnType<typeof useReadUserRoleLazyQuery>;
export type ReadUserRoleQueryResult = Apollo.QueryResult<
  ReadUserRoleQuery,
  ReadUserRoleQueryVariables
>;
export const PasswordResetDocument = gql`
  query passwordReset($email: String!) {
    passwordResetEmail(email: $email)
  }
`;

/**
 * __usePasswordResetQuery__
 *
 * To run a query within a React component, call `usePasswordResetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePasswordResetQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordResetQuery(
  baseOptions: Apollo.QueryHookOptions<PasswordResetQuery, PasswordResetQueryVariables>,
) {
  return Apollo.useQuery<PasswordResetQuery, PasswordResetQueryVariables>(
    PasswordResetDocument,
    baseOptions,
  );
}
export function usePasswordResetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PasswordResetQuery, PasswordResetQueryVariables>,
) {
  return Apollo.useLazyQuery<PasswordResetQuery, PasswordResetQueryVariables>(
    PasswordResetDocument,
    baseOptions,
  );
}
export type PasswordResetQueryHookResult = ReturnType<typeof usePasswordResetQuery>;
export type PasswordResetLazyQueryHookResult = ReturnType<typeof usePasswordResetLazyQuery>;
export type PasswordResetQueryResult = Apollo.QueryResult<
  PasswordResetQuery,
  PasswordResetQueryVariables
>;
export const AssetReportedDataDocument = gql`
  query assetReportedData($configUuid: String!) {
    assetReportedData(configUuid: $configUuid)
  }
`;

/**
 * __useAssetReportedDataQuery__
 *
 * To run a query within a React component, call `useAssetReportedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetReportedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetReportedDataQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useAssetReportedDataQuery(
  baseOptions: Apollo.QueryHookOptions<AssetReportedDataQuery, AssetReportedDataQueryVariables>,
) {
  return Apollo.useQuery<AssetReportedDataQuery, AssetReportedDataQueryVariables>(
    AssetReportedDataDocument,
    baseOptions,
  );
}
export function useAssetReportedDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssetReportedDataQuery,
    AssetReportedDataQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AssetReportedDataQuery, AssetReportedDataQueryVariables>(
    AssetReportedDataDocument,
    baseOptions,
  );
}
export type AssetReportedDataQueryHookResult = ReturnType<typeof useAssetReportedDataQuery>;
export type AssetReportedDataLazyQueryHookResult = ReturnType<typeof useAssetReportedDataLazyQuery>;
export type AssetReportedDataQueryResult = Apollo.QueryResult<
  AssetReportedDataQuery,
  AssetReportedDataQueryVariables
>;
export const ListAdminRegistryUsersDocument = gql`
  query listAdminRegistryUsers($configUuid: String!) {
    listAdminRegistryUsers(configUuid: $configUuid) {
      userDetails {
        id
        username
        name
        email
        profilePicture
        joinedDate
        numberOfProjects
        chosenRole
        userRole
        numberOfSimulations
        numberOfConfiguredAgents
        numberOfLibraries
        requestStatus
        canaryInvitations {
          configUuid
          status
        }
      }
      assetsConfigured {
        assetUuid
        registrationStatus
      }
    }
  }
`;

/**
 * __useListAdminRegistryUsersQuery__
 *
 * To run a query within a React component, call `useListAdminRegistryUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminRegistryUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminRegistryUsersQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useListAdminRegistryUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListAdminRegistryUsersQuery,
    ListAdminRegistryUsersQueryVariables
  >,
) {
  return Apollo.useQuery<ListAdminRegistryUsersQuery, ListAdminRegistryUsersQueryVariables>(
    ListAdminRegistryUsersDocument,
    baseOptions,
  );
}
export function useListAdminRegistryUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAdminRegistryUsersQuery,
    ListAdminRegistryUsersQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListAdminRegistryUsersQuery, ListAdminRegistryUsersQueryVariables>(
    ListAdminRegistryUsersDocument,
    baseOptions,
  );
}
export type ListAdminRegistryUsersQueryHookResult = ReturnType<
  typeof useListAdminRegistryUsersQuery
>;
export type ListAdminRegistryUsersLazyQueryHookResult = ReturnType<
  typeof useListAdminRegistryUsersLazyQuery
>;
export type ListAdminRegistryUsersQueryResult = Apollo.QueryResult<
  ListAdminRegistryUsersQuery,
  ListAdminRegistryUsersQueryVariables
>;
export const ListAggregatorRegistryUsersDocument = gql`
  query listAggregatorRegistryUsers($configUuid: String!) {
    listAggregatorRegistryUsers(configUuid: $configUuid) {
      userDetails {
        id
        username
        name
        email
        profilePicture
        joinedDate
        numberOfProjects
        chosenRole
        userRole
        numberOfSimulations
        numberOfConfiguredAgents
        numberOfLibraries
        requestStatus
        canaryInvitations {
          configUuid
          status
        }
      }
      assetsConfigured {
        assetUuid
        registrationStatus
      }
    }
  }
`;

/**
 * __useListAggregatorRegistryUsersQuery__
 *
 * To run a query within a React component, call `useListAggregatorRegistryUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAggregatorRegistryUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAggregatorRegistryUsersQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useListAggregatorRegistryUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListAggregatorRegistryUsersQuery,
    ListAggregatorRegistryUsersQueryVariables
  >,
) {
  return Apollo.useQuery<
    ListAggregatorRegistryUsersQuery,
    ListAggregatorRegistryUsersQueryVariables
  >(ListAggregatorRegistryUsersDocument, baseOptions);
}
export function useListAggregatorRegistryUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAggregatorRegistryUsersQuery,
    ListAggregatorRegistryUsersQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListAggregatorRegistryUsersQuery,
    ListAggregatorRegistryUsersQueryVariables
  >(ListAggregatorRegistryUsersDocument, baseOptions);
}
export type ListAggregatorRegistryUsersQueryHookResult = ReturnType<
  typeof useListAggregatorRegistryUsersQuery
>;
export type ListAggregatorRegistryUsersLazyQueryHookResult = ReturnType<
  typeof useListAggregatorRegistryUsersLazyQuery
>;
export type ListAggregatorRegistryUsersQueryResult = Apollo.QueryResult<
  ListAggregatorRegistryUsersQuery,
  ListAggregatorRegistryUsersQueryVariables
>;
export const ListAvailableExternalApplicationDocument = gql`
  query listAvailableExternalApplication($configUuid: String!) {
    listAvailableExternalApplication(configUuid: $configUuid) {
      username
      configUuid
      areaUuid
      status
    }
  }
`;

/**
 * __useListAvailableExternalApplicationQuery__
 *
 * To run a query within a React component, call `useListAvailableExternalApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableExternalApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableExternalApplicationQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useListAvailableExternalApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListAvailableExternalApplicationQuery,
    ListAvailableExternalApplicationQueryVariables
  >,
) {
  return Apollo.useQuery<
    ListAvailableExternalApplicationQuery,
    ListAvailableExternalApplicationQueryVariables
  >(ListAvailableExternalApplicationDocument, baseOptions);
}
export function useListAvailableExternalApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAvailableExternalApplicationQuery,
    ListAvailableExternalApplicationQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListAvailableExternalApplicationQuery,
    ListAvailableExternalApplicationQueryVariables
  >(ListAvailableExternalApplicationDocument, baseOptions);
}
export type ListAvailableExternalApplicationQueryHookResult = ReturnType<
  typeof useListAvailableExternalApplicationQuery
>;
export type ListAvailableExternalApplicationLazyQueryHookResult = ReturnType<
  typeof useListAvailableExternalApplicationLazyQuery
>;
export type ListAvailableExternalApplicationQueryResult = Apollo.QueryResult<
  ListAvailableExternalApplicationQuery,
  ListAvailableExternalApplicationQueryVariables
>;
export const ListCanaryNetworksDocument = gql`
  query listCanaryNetworks($search: String, $skip: Int, $first: Int) {
    listCanaryNetworks(search: $search, skip: $skip, first: $first) {
      totalCount
      configurations {
        name
        uuid
        user
        timestamp
        private
        configType: type
        resultsStatus
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

/**
 * __useListCanaryNetworksQuery__
 *
 * To run a query within a React component, call `useListCanaryNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCanaryNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCanaryNetworksQuery({
 *   variables: {
 *      search: // value for 'search'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useListCanaryNetworksQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCanaryNetworksQuery, ListCanaryNetworksQueryVariables>,
) {
  return Apollo.useQuery<ListCanaryNetworksQuery, ListCanaryNetworksQueryVariables>(
    ListCanaryNetworksDocument,
    baseOptions,
  );
}
export function useListCanaryNetworksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCanaryNetworksQuery,
    ListCanaryNetworksQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListCanaryNetworksQuery, ListCanaryNetworksQueryVariables>(
    ListCanaryNetworksDocument,
    baseOptions,
  );
}
export type ListCanaryNetworksQueryHookResult = ReturnType<typeof useListCanaryNetworksQuery>;
export type ListCanaryNetworksLazyQueryHookResult = ReturnType<
  typeof useListCanaryNetworksLazyQuery
>;
export type ListCanaryNetworksQueryResult = Apollo.QueryResult<
  ListCanaryNetworksQuery,
  ListCanaryNetworksQueryVariables
>;
export const ListCanaryNetworksStatusesDocument = gql`
  query listCanaryNetworksStatuses($search: String, $skip: Int, $first: Int) {
    listCanaryNetworks(search: $search, skip: $skip, first: $first) {
      configurations {
        uuid
        resultsStatus
        project {
          uuid
        }
      }
    }
  }
`;

/**
 * __useListCanaryNetworksStatusesQuery__
 *
 * To run a query within a React component, call `useListCanaryNetworksStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCanaryNetworksStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCanaryNetworksStatusesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useListCanaryNetworksStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListCanaryNetworksStatusesQuery,
    ListCanaryNetworksStatusesQueryVariables
  >,
) {
  return Apollo.useQuery<ListCanaryNetworksStatusesQuery, ListCanaryNetworksStatusesQueryVariables>(
    ListCanaryNetworksStatusesDocument,
    baseOptions,
  );
}
export function useListCanaryNetworksStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCanaryNetworksStatusesQuery,
    ListCanaryNetworksStatusesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListCanaryNetworksStatusesQuery,
    ListCanaryNetworksStatusesQueryVariables
  >(ListCanaryNetworksStatusesDocument, baseOptions);
}
export type ListCanaryNetworksStatusesQueryHookResult = ReturnType<
  typeof useListCanaryNetworksStatusesQuery
>;
export type ListCanaryNetworksStatusesLazyQueryHookResult = ReturnType<
  typeof useListCanaryNetworksStatusesLazyQuery
>;
export type ListCanaryNetworksStatusesQueryResult = Apollo.QueryResult<
  ListCanaryNetworksStatusesQuery,
  ListCanaryNetworksStatusesQueryVariables
>;
export const ListGridOperatorRegistryMarketsDocument = gql`
  query listGridOperatorRegistryMarkets($configUuid: String!) {
    listGridOperatorRegistryMarkets(configUuid: $configUuid) {
      name
      uuid
    }
  }
`;

/**
 * __useListGridOperatorRegistryMarketsQuery__
 *
 * To run a query within a React component, call `useListGridOperatorRegistryMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGridOperatorRegistryMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGridOperatorRegistryMarketsQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useListGridOperatorRegistryMarketsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListGridOperatorRegistryMarketsQuery,
    ListGridOperatorRegistryMarketsQueryVariables
  >,
) {
  return Apollo.useQuery<
    ListGridOperatorRegistryMarketsQuery,
    ListGridOperatorRegistryMarketsQueryVariables
  >(ListGridOperatorRegistryMarketsDocument, baseOptions);
}
export function useListGridOperatorRegistryMarketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGridOperatorRegistryMarketsQuery,
    ListGridOperatorRegistryMarketsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListGridOperatorRegistryMarketsQuery,
    ListGridOperatorRegistryMarketsQueryVariables
  >(ListGridOperatorRegistryMarketsDocument, baseOptions);
}
export type ListGridOperatorRegistryMarketsQueryHookResult = ReturnType<
  typeof useListGridOperatorRegistryMarketsQuery
>;
export type ListGridOperatorRegistryMarketsLazyQueryHookResult = ReturnType<
  typeof useListGridOperatorRegistryMarketsLazyQuery
>;
export type ListGridOperatorRegistryMarketsQueryResult = Apollo.QueryResult<
  ListGridOperatorRegistryMarketsQuery,
  ListGridOperatorRegistryMarketsQueryVariables
>;
export const ListGsyUserRegistryAssetsDocument = gql`
  query listGsyUserRegistryAssets($configUuid: String!) {
    listGsyUserRegistryAssets(configUuid: $configUuid) {
      assetInformation {
        name
        uuid
        type
      }
      aggregatorInformation {
        name
        uuid
      }
    }
  }
`;

/**
 * __useListGsyUserRegistryAssetsQuery__
 *
 * To run a query within a React component, call `useListGsyUserRegistryAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGsyUserRegistryAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGsyUserRegistryAssetsQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useListGsyUserRegistryAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListGsyUserRegistryAssetsQuery,
    ListGsyUserRegistryAssetsQueryVariables
  >,
) {
  return Apollo.useQuery<ListGsyUserRegistryAssetsQuery, ListGsyUserRegistryAssetsQueryVariables>(
    ListGsyUserRegistryAssetsDocument,
    baseOptions,
  );
}
export function useListGsyUserRegistryAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGsyUserRegistryAssetsQuery,
    ListGsyUserRegistryAssetsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListGsyUserRegistryAssetsQuery,
    ListGsyUserRegistryAssetsQueryVariables
  >(ListGsyUserRegistryAssetsDocument, baseOptions);
}
export type ListGsyUserRegistryAssetsQueryHookResult = ReturnType<
  typeof useListGsyUserRegistryAssetsQuery
>;
export type ListGsyUserRegistryAssetsLazyQueryHookResult = ReturnType<
  typeof useListGsyUserRegistryAssetsLazyQuery
>;
export type ListGsyUserRegistryAssetsQueryResult = Apollo.QueryResult<
  ListGsyUserRegistryAssetsQuery,
  ListGsyUserRegistryAssetsQueryVariables
>;
export const ListMeasurementVerificationRequestsDocument = gql`
  query listMeasurementVerificationRequests($configUuid: String) {
    listMeasurementVerificationRequests(configUuid: $configUuid) {
      configUuid
      areaUuid
      status
      userName
    }
  }
`;

/**
 * __useListMeasurementVerificationRequestsQuery__
 *
 * To run a query within a React component, call `useListMeasurementVerificationRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMeasurementVerificationRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMeasurementVerificationRequestsQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useListMeasurementVerificationRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListMeasurementVerificationRequestsQuery,
    ListMeasurementVerificationRequestsQueryVariables
  >,
) {
  return Apollo.useQuery<
    ListMeasurementVerificationRequestsQuery,
    ListMeasurementVerificationRequestsQueryVariables
  >(ListMeasurementVerificationRequestsDocument, baseOptions);
}
export function useListMeasurementVerificationRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListMeasurementVerificationRequestsQuery,
    ListMeasurementVerificationRequestsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListMeasurementVerificationRequestsQuery,
    ListMeasurementVerificationRequestsQueryVariables
  >(ListMeasurementVerificationRequestsDocument, baseOptions);
}
export type ListMeasurementVerificationRequestsQueryHookResult = ReturnType<
  typeof useListMeasurementVerificationRequestsQuery
>;
export type ListMeasurementVerificationRequestsLazyQueryHookResult = ReturnType<
  typeof useListMeasurementVerificationRequestsLazyQuery
>;
export type ListMeasurementVerificationRequestsQueryResult = Apollo.QueryResult<
  ListMeasurementVerificationRequestsQuery,
  ListMeasurementVerificationRequestsQueryVariables
>;
export const ListUserToAdminRequestsDocument = gql`
  query listUserToAdminRequests($username: String, $status: UserRequestsStatus) {
    listUserToAdminRequests(username: $username, status: $status) {
      id
      username
      requestType
      status
      configUuid
      profilePicture
      profileThumbnail
    }
  }
`;

/**
 * __useListUserToAdminRequestsQuery__
 *
 * To run a query within a React component, call `useListUserToAdminRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserToAdminRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserToAdminRequestsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListUserToAdminRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserToAdminRequestsQuery,
    ListUserToAdminRequestsQueryVariables
  >,
) {
  return Apollo.useQuery<ListUserToAdminRequestsQuery, ListUserToAdminRequestsQueryVariables>(
    ListUserToAdminRequestsDocument,
    baseOptions,
  );
}
export function useListUserToAdminRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserToAdminRequestsQuery,
    ListUserToAdminRequestsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListUserToAdminRequestsQuery, ListUserToAdminRequestsQueryVariables>(
    ListUserToAdminRequestsDocument,
    baseOptions,
  );
}
export type ListUserToAdminRequestsQueryHookResult = ReturnType<
  typeof useListUserToAdminRequestsQuery
>;
export type ListUserToAdminRequestsLazyQueryHookResult = ReturnType<
  typeof useListUserToAdminRequestsLazyQuery
>;
export type ListUserToAdminRequestsQueryResult = Apollo.QueryResult<
  ListUserToAdminRequestsQuery,
  ListUserToAdminRequestsQueryVariables
>;
export const MoveCollaborationToCanaryNetworkDocument = gql`
  mutation moveCollaborationToCanaryNetwork(
    $uuid: String!
    $name: String!
    $description: String
    $private: Boolean
  ) {
    moveCollaborationToCanaryNetwork(
      uuid: $uuid
      name: $name
      description: $description
      private: $private
    ) {
      uuid
      project {
        uuid
      }
    }
  }
`;
export type MoveCollaborationToCanaryNetworkMutationFn = Apollo.MutationFunction<
  MoveCollaborationToCanaryNetworkMutation,
  MoveCollaborationToCanaryNetworkMutationVariables
>;

/**
 * __useMoveCollaborationToCanaryNetworkMutation__
 *
 * To run a mutation, you first call `useMoveCollaborationToCanaryNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveCollaborationToCanaryNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveCollaborationToCanaryNetworkMutation, { data, loading, error }] = useMoveCollaborationToCanaryNetworkMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      private: // value for 'private'
 *   },
 * });
 */
export function useMoveCollaborationToCanaryNetworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveCollaborationToCanaryNetworkMutation,
    MoveCollaborationToCanaryNetworkMutationVariables
  >,
) {
  return Apollo.useMutation<
    MoveCollaborationToCanaryNetworkMutation,
    MoveCollaborationToCanaryNetworkMutationVariables
  >(MoveCollaborationToCanaryNetworkDocument, baseOptions);
}
export type MoveCollaborationToCanaryNetworkMutationHookResult = ReturnType<
  typeof useMoveCollaborationToCanaryNetworkMutation
>;
export type MoveCollaborationToCanaryNetworkMutationResult = Apollo.MutationResult<MoveCollaborationToCanaryNetworkMutation>;
export type MoveCollaborationToCanaryNetworkMutationOptions = Apollo.BaseMutationOptions<
  MoveCollaborationToCanaryNetworkMutation,
  MoveCollaborationToCanaryNetworkMutationVariables
>;
export const ListCollaborationsDocument = gql`
  query listCollaborations($search: String, $skip: Int, $first: Int) {
    listCollaborations(search: $search, skip: $skip, first: $first) {
      totalCount
      configurations {
        name
        uuid
        user
        timestamp
        private
        project {
          name
          uuid
          user
          description
          private
        }
        configType: type
        resultsStatus
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

/**
 * __useListCollaborationsQuery__
 *
 * To run a query within a React component, call `useListCollaborationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollaborationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollaborationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useListCollaborationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCollaborationsQuery, ListCollaborationsQueryVariables>,
) {
  return Apollo.useQuery<ListCollaborationsQuery, ListCollaborationsQueryVariables>(
    ListCollaborationsDocument,
    baseOptions,
  );
}
export function useListCollaborationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCollaborationsQuery,
    ListCollaborationsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListCollaborationsQuery, ListCollaborationsQueryVariables>(
    ListCollaborationsDocument,
    baseOptions,
  );
}
export type ListCollaborationsQueryHookResult = ReturnType<typeof useListCollaborationsQuery>;
export type ListCollaborationsLazyQueryHookResult = ReturnType<
  typeof useListCollaborationsLazyQuery
>;
export type ListCollaborationsQueryResult = Apollo.QueryResult<
  ListCollaborationsQuery,
  ListCollaborationsQueryVariables
>;
export const ListCollaborationsStatusesDocument = gql`
  query listCollaborationsStatuses($search: String, $skip: Int, $first: Int) {
    listCollaborations(search: $search, skip: $skip, first: $first) {
      configurations {
        uuid
        resultsStatus
        project {
          uuid
        }
      }
    }
  }
`;

/**
 * __useListCollaborationsStatusesQuery__
 *
 * To run a query within a React component, call `useListCollaborationsStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollaborationsStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollaborationsStatusesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useListCollaborationsStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListCollaborationsStatusesQuery,
    ListCollaborationsStatusesQueryVariables
  >,
) {
  return Apollo.useQuery<ListCollaborationsStatusesQuery, ListCollaborationsStatusesQueryVariables>(
    ListCollaborationsStatusesDocument,
    baseOptions,
  );
}
export function useListCollaborationsStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCollaborationsStatusesQuery,
    ListCollaborationsStatusesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListCollaborationsStatusesQuery,
    ListCollaborationsStatusesQueryVariables
  >(ListCollaborationsStatusesDocument, baseOptions);
}
export type ListCollaborationsStatusesQueryHookResult = ReturnType<
  typeof useListCollaborationsStatusesQuery
>;
export type ListCollaborationsStatusesLazyQueryHookResult = ReturnType<
  typeof useListCollaborationsStatusesLazyQuery
>;
export type ListCollaborationsStatusesQueryResult = Apollo.QueryResult<
  ListCollaborationsStatusesQuery,
  ListCollaborationsStatusesQueryVariables
>;
export const MyApplicationsDocument = gql`
  query myApplications($uuid: String!) {
    listApplicationStatus(configUuid: $uuid) {
      areaUuid
      status
    }
  }
`;

/**
 * __useMyApplicationsQuery__
 *
 * To run a query within a React component, call `useMyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyApplicationsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMyApplicationsQuery(
  baseOptions: Apollo.QueryHookOptions<MyApplicationsQuery, MyApplicationsQueryVariables>,
) {
  return Apollo.useQuery<MyApplicationsQuery, MyApplicationsQueryVariables>(
    MyApplicationsDocument,
    baseOptions,
  );
}
export function useMyApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyApplicationsQuery, MyApplicationsQueryVariables>,
) {
  return Apollo.useLazyQuery<MyApplicationsQuery, MyApplicationsQueryVariables>(
    MyApplicationsDocument,
    baseOptions,
  );
}
export type MyApplicationsQueryHookResult = ReturnType<typeof useMyApplicationsQuery>;
export type MyApplicationsLazyQueryHookResult = ReturnType<typeof useMyApplicationsLazyQuery>;
export type MyApplicationsQueryResult = Apollo.QueryResult<
  MyApplicationsQuery,
  MyApplicationsQueryVariables
>;
export const ReadCollaborationConfigurationDocument = gql`
  query readCollaborationConfiguration($uuid: String!) {
    readConfiguration(uuid: $uuid) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;

/**
 * __useReadCollaborationConfigurationQuery__
 *
 * To run a query within a React component, call `useReadCollaborationConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadCollaborationConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadCollaborationConfigurationQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadCollaborationConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadCollaborationConfigurationQuery,
    ReadCollaborationConfigurationQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReadCollaborationConfigurationQuery,
    ReadCollaborationConfigurationQueryVariables
  >(ReadCollaborationConfigurationDocument, baseOptions);
}
export function useReadCollaborationConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadCollaborationConfigurationQuery,
    ReadCollaborationConfigurationQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadCollaborationConfigurationQuery,
    ReadCollaborationConfigurationQueryVariables
  >(ReadCollaborationConfigurationDocument, baseOptions);
}
export type ReadCollaborationConfigurationQueryHookResult = ReturnType<
  typeof useReadCollaborationConfigurationQuery
>;
export type ReadCollaborationConfigurationLazyQueryHookResult = ReturnType<
  typeof useReadCollaborationConfigurationLazyQuery
>;
export type ReadCollaborationConfigurationQueryResult = Apollo.QueryResult<
  ReadCollaborationConfigurationQuery,
  ReadCollaborationConfigurationQueryVariables
>;
export const ListCommunityInfoDocument = gql`
  query listCommunityInfo {
    listCommunityInfo {
      projects {
        name
        uuid
        user
        timestamp
        private
        latitude
        longitude
        locationVisible
        configurations {
          name
          uuid
          configType: type
          globalCumulativeNetEnergyFlow
          latitude
          longitude
        }
      }
    }
  }
`;

/**
 * __useListCommunityInfoQuery__
 *
 * To run a query within a React component, call `useListCommunityInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCommunityInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCommunityInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCommunityInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>,
) {
  return Apollo.useQuery<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>(
    ListCommunityInfoDocument,
    baseOptions,
  );
}
export function useListCommunityInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCommunityInfoQuery,
    ListCommunityInfoQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListCommunityInfoQuery, ListCommunityInfoQueryVariables>(
    ListCommunityInfoDocument,
    baseOptions,
  );
}
export type ListCommunityInfoQueryHookResult = ReturnType<typeof useListCommunityInfoQuery>;
export type ListCommunityInfoLazyQueryHookResult = ReturnType<typeof useListCommunityInfoLazyQuery>;
export type ListCommunityInfoQueryResult = Apollo.QueryResult<
  ListCommunityInfoQuery,
  ListCommunityInfoQueryVariables
>;
export const ListPersonalCommunityInfoDocument = gql`
  query listPersonalCommunityInfo {
    listPersonalCommunityInfo {
      projects {
        name
        uuid
        user
        timestamp
        private
        latitude
        longitude
        locationVisible
        configurations {
          name
          uuid
          configType: type
          globalCumulativeNetEnergyFlow
          latitude
          longitude
        }
      }
    }
  }
`;

/**
 * __useListPersonalCommunityInfoQuery__
 *
 * To run a query within a React component, call `useListPersonalCommunityInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPersonalCommunityInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPersonalCommunityInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPersonalCommunityInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListPersonalCommunityInfoQuery,
    ListPersonalCommunityInfoQueryVariables
  >,
) {
  return Apollo.useQuery<ListPersonalCommunityInfoQuery, ListPersonalCommunityInfoQueryVariables>(
    ListPersonalCommunityInfoDocument,
    baseOptions,
  );
}
export function useListPersonalCommunityInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListPersonalCommunityInfoQuery,
    ListPersonalCommunityInfoQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListPersonalCommunityInfoQuery,
    ListPersonalCommunityInfoQueryVariables
  >(ListPersonalCommunityInfoDocument, baseOptions);
}
export type ListPersonalCommunityInfoQueryHookResult = ReturnType<
  typeof useListPersonalCommunityInfoQuery
>;
export type ListPersonalCommunityInfoLazyQueryHookResult = ReturnType<
  typeof useListPersonalCommunityInfoLazyQuery
>;
export type ListPersonalCommunityInfoQueryResult = Apollo.QueryResult<
  ListPersonalCommunityInfoQuery,
  ListPersonalCommunityInfoQueryVariables
>;
export const AreaSummaryDocument = gql`
  query areaSummary($uuid: String!) {
    areaSummary(configUuid: $uuid)
  }
`;

/**
 * __useAreaSummaryQuery__
 *
 * To run a query within a React component, call `useAreaSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaSummaryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useAreaSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<AreaSummaryQuery, AreaSummaryQueryVariables>,
) {
  return Apollo.useQuery<AreaSummaryQuery, AreaSummaryQueryVariables>(
    AreaSummaryDocument,
    baseOptions,
  );
}
export function useAreaSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AreaSummaryQuery, AreaSummaryQueryVariables>,
) {
  return Apollo.useLazyQuery<AreaSummaryQuery, AreaSummaryQueryVariables>(
    AreaSummaryDocument,
    baseOptions,
  );
}
export type AreaSummaryQueryHookResult = ReturnType<typeof useAreaSummaryQuery>;
export type AreaSummaryLazyQueryHookResult = ReturnType<typeof useAreaSummaryLazyQuery>;
export type AreaSummaryQueryResult = Apollo.QueryResult<
  AreaSummaryQuery,
  AreaSummaryQueryVariables
>;
export const ListConfigurationsDocument = gql`
  query listConfigurations($search: String, $skip: Int, $first: Int, $projectUuid: String) {
    listConfigurations(search: $search, skip: $skip, first: $first, projectUUID: $projectUuid) {
      totalCount
      configurations {
        uuid
        name
        user
        description
        readOnly
        simulationResults
        resultsStatus
        timestamp
        scenarioData {
          deviceCount
        }
        areaSummary {
          configuredAreas
          configuredDevices
        }
        project {
          uuid
        }
        configType: type
        private
      }
    }
  }
`;

/**
 * __useListConfigurationsQuery__
 *
 * To run a query within a React component, call `useListConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConfigurationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      projectUuid: // value for 'projectUuid'
 *   },
 * });
 */
export function useListConfigurationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListConfigurationsQuery, ListConfigurationsQueryVariables>,
) {
  return Apollo.useQuery<ListConfigurationsQuery, ListConfigurationsQueryVariables>(
    ListConfigurationsDocument,
    baseOptions,
  );
}
export function useListConfigurationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListConfigurationsQuery,
    ListConfigurationsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListConfigurationsQuery, ListConfigurationsQueryVariables>(
    ListConfigurationsDocument,
    baseOptions,
  );
}
export type ListConfigurationsQueryHookResult = ReturnType<typeof useListConfigurationsQuery>;
export type ListConfigurationsLazyQueryHookResult = ReturnType<
  typeof useListConfigurationsLazyQuery
>;
export type ListConfigurationsQueryResult = Apollo.QueryResult<
  ListConfigurationsQuery,
  ListConfigurationsQueryVariables
>;
export const LookForCommunityUuidDocument = gql`
  query lookForCommunityUuid($uuid: String!) {
    readConfiguration(uuid: $uuid) {
      name
      uuid
      simulationResults
      resultsStatus
      scenarioData {
        latest {
          children {
            ... on AreaOutput {
              name
              uuid
            }
          }
        }
      }
    }
  }
`;

/**
 * __useLookForCommunityUuidQuery__
 *
 * To run a query within a React component, call `useLookForCommunityUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookForCommunityUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookForCommunityUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useLookForCommunityUuidQuery(
  baseOptions: Apollo.QueryHookOptions<
    LookForCommunityUuidQuery,
    LookForCommunityUuidQueryVariables
  >,
) {
  return Apollo.useQuery<LookForCommunityUuidQuery, LookForCommunityUuidQueryVariables>(
    LookForCommunityUuidDocument,
    baseOptions,
  );
}
export function useLookForCommunityUuidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LookForCommunityUuidQuery,
    LookForCommunityUuidQueryVariables
  >,
) {
  return Apollo.useLazyQuery<LookForCommunityUuidQuery, LookForCommunityUuidQueryVariables>(
    LookForCommunityUuidDocument,
    baseOptions,
  );
}
export type LookForCommunityUuidQueryHookResult = ReturnType<typeof useLookForCommunityUuidQuery>;
export type LookForCommunityUuidLazyQueryHookResult = ReturnType<
  typeof useLookForCommunityUuidLazyQuery
>;
export type LookForCommunityUuidQueryResult = Apollo.QueryResult<
  LookForCommunityUuidQuery,
  LookForCommunityUuidQueryVariables
>;
export const LookForSimulationResultsDocument = gql`
  query lookForSimulationResults($configurationUuid: String!) {
    readConfiguration(uuid: $configurationUuid) {
      uuid
      simulationResults
      resultsStatus
    }
  }
`;

/**
 * __useLookForSimulationResultsQuery__
 *
 * To run a query within a React component, call `useLookForSimulationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookForSimulationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookForSimulationResultsQuery({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *   },
 * });
 */
export function useLookForSimulationResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LookForSimulationResultsQuery,
    LookForSimulationResultsQueryVariables
  >,
) {
  return Apollo.useQuery<LookForSimulationResultsQuery, LookForSimulationResultsQueryVariables>(
    LookForSimulationResultsDocument,
    baseOptions,
  );
}
export function useLookForSimulationResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LookForSimulationResultsQuery,
    LookForSimulationResultsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<LookForSimulationResultsQuery, LookForSimulationResultsQueryVariables>(
    LookForSimulationResultsDocument,
    baseOptions,
  );
}
export type LookForSimulationResultsQueryHookResult = ReturnType<
  typeof useLookForSimulationResultsQuery
>;
export type LookForSimulationResultsLazyQueryHookResult = ReturnType<
  typeof useLookForSimulationResultsLazyQuery
>;
export type LookForSimulationResultsQueryResult = Apollo.QueryResult<
  LookForSimulationResultsQuery,
  LookForSimulationResultsQueryVariables
>;
export const ReadConfigRepresentationWithoutEventsDocument = gql`
  query readConfigRepresentationWithoutEvents($uuid: String!) {
    readConfiguration(uuid: $uuid) {
      scenarioData {
        representation {
          serialized
        }
      }
    }
  }
`;

/**
 * __useReadConfigRepresentationWithoutEventsQuery__
 *
 * To run a query within a React component, call `useReadConfigRepresentationWithoutEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadConfigRepresentationWithoutEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadConfigRepresentationWithoutEventsQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadConfigRepresentationWithoutEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadConfigRepresentationWithoutEventsQuery,
    ReadConfigRepresentationWithoutEventsQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReadConfigRepresentationWithoutEventsQuery,
    ReadConfigRepresentationWithoutEventsQueryVariables
  >(ReadConfigRepresentationWithoutEventsDocument, baseOptions);
}
export function useReadConfigRepresentationWithoutEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadConfigRepresentationWithoutEventsQuery,
    ReadConfigRepresentationWithoutEventsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadConfigRepresentationWithoutEventsQuery,
    ReadConfigRepresentationWithoutEventsQueryVariables
  >(ReadConfigRepresentationWithoutEventsDocument, baseOptions);
}
export type ReadConfigRepresentationWithoutEventsQueryHookResult = ReturnType<
  typeof useReadConfigRepresentationWithoutEventsQuery
>;
export type ReadConfigRepresentationWithoutEventsLazyQueryHookResult = ReturnType<
  typeof useReadConfigRepresentationWithoutEventsLazyQuery
>;
export type ReadConfigRepresentationWithoutEventsQueryResult = Apollo.QueryResult<
  ReadConfigRepresentationWithoutEventsQuery,
  ReadConfigRepresentationWithoutEventsQueryVariables
>;
export const ReadConfigurationDocument = gql`
  query readConfiguration($uuid: String!) {
    readConfiguration(uuid: $uuid) {
      ...ConfigurationFields
    }
  }
  ${ConfigurationFieldsFragmentDoc}
`;

/**
 * __useReadConfigurationQuery__
 *
 * To run a query within a React component, call `useReadConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadConfigurationQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<ReadConfigurationQuery, ReadConfigurationQueryVariables>,
) {
  return Apollo.useQuery<ReadConfigurationQuery, ReadConfigurationQueryVariables>(
    ReadConfigurationDocument,
    baseOptions,
  );
}
export function useReadConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadConfigurationQuery,
    ReadConfigurationQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ReadConfigurationQuery, ReadConfigurationQueryVariables>(
    ReadConfigurationDocument,
    baseOptions,
  );
}
export type ReadConfigurationQueryHookResult = ReturnType<typeof useReadConfigurationQuery>;
export type ReadConfigurationLazyQueryHookResult = ReturnType<typeof useReadConfigurationLazyQuery>;
export type ReadConfigurationQueryResult = Apollo.QueryResult<
  ReadConfigurationQuery,
  ReadConfigurationQueryVariables
>;
export const ReadConfigurationBaseInfoDocument = gql`
  query readConfigurationBaseInfo($uuid: String!) {
    readConfiguration(uuid: $uuid) {
      uuid
      user
      configType: type
      private
    }
  }
`;

/**
 * __useReadConfigurationBaseInfoQuery__
 *
 * To run a query within a React component, call `useReadConfigurationBaseInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadConfigurationBaseInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadConfigurationBaseInfoQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadConfigurationBaseInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadConfigurationBaseInfoQuery,
    ReadConfigurationBaseInfoQueryVariables
  >,
) {
  return Apollo.useQuery<ReadConfigurationBaseInfoQuery, ReadConfigurationBaseInfoQueryVariables>(
    ReadConfigurationBaseInfoDocument,
    baseOptions,
  );
}
export function useReadConfigurationBaseInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadConfigurationBaseInfoQuery,
    ReadConfigurationBaseInfoQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadConfigurationBaseInfoQuery,
    ReadConfigurationBaseInfoQueryVariables
  >(ReadConfigurationBaseInfoDocument, baseOptions);
}
export type ReadConfigurationBaseInfoQueryHookResult = ReturnType<
  typeof useReadConfigurationBaseInfoQuery
>;
export type ReadConfigurationBaseInfoLazyQueryHookResult = ReturnType<
  typeof useReadConfigurationBaseInfoLazyQuery
>;
export type ReadConfigurationBaseInfoQueryResult = Apollo.QueryResult<
  ReadConfigurationBaseInfoQuery,
  ReadConfigurationBaseInfoQueryVariables
>;
export const ReadConfigurationSummaryDocument = gql`
  query readConfigurationSummary($uuid: String!) {
    readConfiguration(uuid: $uuid) {
      areaSummary {
        configuredAreas
        configuredDevices
      }
    }
  }
`;

/**
 * __useReadConfigurationSummaryQuery__
 *
 * To run a query within a React component, call `useReadConfigurationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadConfigurationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadConfigurationSummaryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadConfigurationSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadConfigurationSummaryQuery,
    ReadConfigurationSummaryQueryVariables
  >,
) {
  return Apollo.useQuery<ReadConfigurationSummaryQuery, ReadConfigurationSummaryQueryVariables>(
    ReadConfigurationSummaryDocument,
    baseOptions,
  );
}
export function useReadConfigurationSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadConfigurationSummaryQuery,
    ReadConfigurationSummaryQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ReadConfigurationSummaryQuery, ReadConfigurationSummaryQueryVariables>(
    ReadConfigurationSummaryDocument,
    baseOptions,
  );
}
export type ReadConfigurationSummaryQueryHookResult = ReturnType<
  typeof useReadConfigurationSummaryQuery
>;
export type ReadConfigurationSummaryLazyQueryHookResult = ReturnType<
  typeof useReadConfigurationSummaryLazyQuery
>;
export type ReadConfigurationSummaryQueryResult = Apollo.QueryResult<
  ReadConfigurationSummaryQuery,
  ReadConfigurationSummaryQueryVariables
>;
export const ReadDataToDetermineUsedNodesNamesDocument = gql`
  query readDataToDetermineUsedNodesNames(
    $configurationUuid: String!
    $skipReadConfiguration: Boolean = false
    $skipReadLibrary: Boolean = false
  ) {
    readConfiguration(uuid: $configurationUuid) @skip(if: $skipReadConfiguration) {
      scenarioData {
        representation {
          serialized
        }
        latest {
          serialized
        }
      }
    }
    readLibrary(uuid: $configurationUuid) @skip(if: $skipReadLibrary) {
      scenarioData {
        representation {
          ... on PV {
            serialized
          }
          ... on Storage {
            serialized
          }
          ... on Load {
            serialized
          }
          ... on FiniteDieselGenerator {
            serialized
          }
          ... on MarketMaker {
            serialized
          }
          ... on InfiniteBus {
            serialized
          }
          ... on AreaOutput {
            serialized
          }
        }
      }
    }
    listLiveEvents(configUuid: $configurationUuid) {
      diff
    }
  }
`;

/**
 * __useReadDataToDetermineUsedNodesNamesQuery__
 *
 * To run a query within a React component, call `useReadDataToDetermineUsedNodesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDataToDetermineUsedNodesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDataToDetermineUsedNodesNamesQuery({
 *   variables: {
 *      configurationUuid: // value for 'configurationUuid'
 *      skipReadConfiguration: // value for 'skipReadConfiguration'
 *      skipReadLibrary: // value for 'skipReadLibrary'
 *   },
 * });
 */
export function useReadDataToDetermineUsedNodesNamesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadDataToDetermineUsedNodesNamesQuery,
    ReadDataToDetermineUsedNodesNamesQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReadDataToDetermineUsedNodesNamesQuery,
    ReadDataToDetermineUsedNodesNamesQueryVariables
  >(ReadDataToDetermineUsedNodesNamesDocument, baseOptions);
}
export function useReadDataToDetermineUsedNodesNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadDataToDetermineUsedNodesNamesQuery,
    ReadDataToDetermineUsedNodesNamesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadDataToDetermineUsedNodesNamesQuery,
    ReadDataToDetermineUsedNodesNamesQueryVariables
  >(ReadDataToDetermineUsedNodesNamesDocument, baseOptions);
}
export type ReadDataToDetermineUsedNodesNamesQueryHookResult = ReturnType<
  typeof useReadDataToDetermineUsedNodesNamesQuery
>;
export type ReadDataToDetermineUsedNodesNamesLazyQueryHookResult = ReturnType<
  typeof useReadDataToDetermineUsedNodesNamesLazyQuery
>;
export type ReadDataToDetermineUsedNodesNamesQueryResult = Apollo.QueryResult<
  ReadDataToDetermineUsedNodesNamesQuery,
  ReadDataToDetermineUsedNodesNamesQueryVariables
>;
export const GetUserGlobalLocationVisibilityDocument = gql`
  query getUserGlobalLocationVisibility {
    getUserGlobalLocationVisibility
  }
`;

/**
 * __useGetUserGlobalLocationVisibilityQuery__
 *
 * To run a query within a React component, call `useGetUserGlobalLocationVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGlobalLocationVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGlobalLocationVisibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserGlobalLocationVisibilityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserGlobalLocationVisibilityQuery,
    GetUserGlobalLocationVisibilityQueryVariables
  >,
) {
  return Apollo.useQuery<
    GetUserGlobalLocationVisibilityQuery,
    GetUserGlobalLocationVisibilityQueryVariables
  >(GetUserGlobalLocationVisibilityDocument, baseOptions);
}
export function useGetUserGlobalLocationVisibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserGlobalLocationVisibilityQuery,
    GetUserGlobalLocationVisibilityQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    GetUserGlobalLocationVisibilityQuery,
    GetUserGlobalLocationVisibilityQueryVariables
  >(GetUserGlobalLocationVisibilityDocument, baseOptions);
}
export type GetUserGlobalLocationVisibilityQueryHookResult = ReturnType<
  typeof useGetUserGlobalLocationVisibilityQuery
>;
export type GetUserGlobalLocationVisibilityLazyQueryHookResult = ReturnType<
  typeof useGetUserGlobalLocationVisibilityLazyQuery
>;
export type GetUserGlobalLocationVisibilityQueryResult = Apollo.QueryResult<
  GetUserGlobalLocationVisibilityQuery,
  GetUserGlobalLocationVisibilityQueryVariables
>;
export const ListGlobalSimulationLocationDocument = gql`
  query listGlobalSimulationLocation(
    $minLat: Float!
    $minLong: Float!
    $maxLat: Float!
    $maxLong: Float!
  ) {
    listGlobalSimulationLocation(
      minLat: $minLat
      maxLat: $maxLat
      minLong: $minLong
      maxLong: $maxLong
    ) {
      boundaryId
      totalMarkets
      totalDevices
      simulationsInProgress
    }
  }
`;

/**
 * __useListGlobalSimulationLocationQuery__
 *
 * To run a query within a React component, call `useListGlobalSimulationLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGlobalSimulationLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGlobalSimulationLocationQuery({
 *   variables: {
 *      minLat: // value for 'minLat'
 *      minLong: // value for 'minLong'
 *      maxLat: // value for 'maxLat'
 *      maxLong: // value for 'maxLong'
 *   },
 * });
 */
export function useListGlobalSimulationLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ListGlobalSimulationLocationQuery,
    ListGlobalSimulationLocationQueryVariables
  >,
) {
  return Apollo.useQuery<
    ListGlobalSimulationLocationQuery,
    ListGlobalSimulationLocationQueryVariables
  >(ListGlobalSimulationLocationDocument, baseOptions);
}
export function useListGlobalSimulationLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListGlobalSimulationLocationQuery,
    ListGlobalSimulationLocationQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListGlobalSimulationLocationQuery,
    ListGlobalSimulationLocationQueryVariables
  >(ListGlobalSimulationLocationDocument, baseOptions);
}
export type ListGlobalSimulationLocationQueryHookResult = ReturnType<
  typeof useListGlobalSimulationLocationQuery
>;
export type ListGlobalSimulationLocationLazyQueryHookResult = ReturnType<
  typeof useListGlobalSimulationLocationLazyQuery
>;
export type ListGlobalSimulationLocationQueryResult = Apollo.QueryResult<
  ListGlobalSimulationLocationQuery,
  ListGlobalSimulationLocationQueryVariables
>;
export const ListUserGlobalSimulationVisibilityDocument = gql`
  query listUserGlobalSimulationVisibility {
    listUserGlobalSimulationVisibility {
      configUuid
      visibility
      boundaryId
      totalMarkets
      totalDevices
      simulationsInProgress
      configType: type
      projectUuid
    }
  }
`;

/**
 * __useListUserGlobalSimulationVisibilityQuery__
 *
 * To run a query within a React component, call `useListUserGlobalSimulationVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserGlobalSimulationVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserGlobalSimulationVisibilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserGlobalSimulationVisibilityQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUserGlobalSimulationVisibilityQuery,
    ListUserGlobalSimulationVisibilityQueryVariables
  >,
) {
  return Apollo.useQuery<
    ListUserGlobalSimulationVisibilityQuery,
    ListUserGlobalSimulationVisibilityQueryVariables
  >(ListUserGlobalSimulationVisibilityDocument, baseOptions);
}
export function useListUserGlobalSimulationVisibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUserGlobalSimulationVisibilityQuery,
    ListUserGlobalSimulationVisibilityQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListUserGlobalSimulationVisibilityQuery,
    ListUserGlobalSimulationVisibilityQueryVariables
  >(ListUserGlobalSimulationVisibilityDocument, baseOptions);
}
export type ListUserGlobalSimulationVisibilityQueryHookResult = ReturnType<
  typeof useListUserGlobalSimulationVisibilityQuery
>;
export type ListUserGlobalSimulationVisibilityLazyQueryHookResult = ReturnType<
  typeof useListUserGlobalSimulationVisibilityLazyQuery
>;
export type ListUserGlobalSimulationVisibilityQueryResult = Apollo.QueryResult<
  ListUserGlobalSimulationVisibilityQuery,
  ListUserGlobalSimulationVisibilityQueryVariables
>;
export const ReadGlobalSimulationLocationDocument = gql`
  query readGlobalSimulationLocation($configUuid: String!) {
    readGlobalSimulationLocation(configUuid: $configUuid) {
      longitude
      latitude
      boundaryId
      visibility
    }
  }
`;

/**
 * __useReadGlobalSimulationLocationQuery__
 *
 * To run a query within a React component, call `useReadGlobalSimulationLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadGlobalSimulationLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadGlobalSimulationLocationQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useReadGlobalSimulationLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadGlobalSimulationLocationQuery,
    ReadGlobalSimulationLocationQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReadGlobalSimulationLocationQuery,
    ReadGlobalSimulationLocationQueryVariables
  >(ReadGlobalSimulationLocationDocument, baseOptions);
}
export function useReadGlobalSimulationLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadGlobalSimulationLocationQuery,
    ReadGlobalSimulationLocationQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadGlobalSimulationLocationQuery,
    ReadGlobalSimulationLocationQueryVariables
  >(ReadGlobalSimulationLocationDocument, baseOptions);
}
export type ReadGlobalSimulationLocationQueryHookResult = ReturnType<
  typeof useReadGlobalSimulationLocationQuery
>;
export type ReadGlobalSimulationLocationLazyQueryHookResult = ReturnType<
  typeof useReadGlobalSimulationLocationLazyQuery
>;
export type ReadGlobalSimulationLocationQueryResult = Apollo.QueryResult<
  ReadGlobalSimulationLocationQuery,
  ReadGlobalSimulationLocationQueryVariables
>;
export const ListLibrariesDocument = gql`
  query listLibraries(
    $skip: Int
    $first: Int
    $search: String
    $tags: [String]
    $libraryTypes: [LibraryTypesGraphql]
  ) {
    listLibraries(
      skip: $skip
      first: $first
      search: $search
      tags: $tags
      libraryTypes: $libraryTypes
    ) {
      totalCount
      libraries {
        name
        uuid
        description
        user
        private
        readOnly
        tags
        libraryType
        scenarioData {
          deviceCountNumber
          areaCountNumber
          representation {
            ... on PV {
              serialized
              type
              uuid
            }
            ... on Storage {
              serialized
              type
              uuid
            }
            ... on Load {
              serialized
              type
              uuid
            }
            ... on FiniteDieselGenerator {
              serialized
              type
              uuid
            }
            ... on MarketMaker {
              serialized
              type
              uuid
            }
            ... on InfiniteBus {
              serialized
              type
              uuid
            }
            ... on AreaOutput {
              serialized
              type
              uuid
            }
            ... on SmartMeter {
              serialized
              type
              uuid
            }
            ... on WindTurbine {
              serialized
              type
              uuid
            }
            ... on HeatPump {
              serialized
              type
              uuid
            }
            ... on ScmStorage {
              serialized
              type
              uuid
            }
            ... on ScmHeatPump {
              serialized
              type
              uuid
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListLibrariesQuery__
 *
 * To run a query within a React component, call `useListLibrariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLibrariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLibrariesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      tags: // value for 'tags'
 *      libraryTypes: // value for 'libraryTypes'
 *   },
 * });
 */
export function useListLibrariesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListLibrariesQuery, ListLibrariesQueryVariables>,
) {
  return Apollo.useQuery<ListLibrariesQuery, ListLibrariesQueryVariables>(
    ListLibrariesDocument,
    baseOptions,
  );
}
export function useListLibrariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListLibrariesQuery, ListLibrariesQueryVariables>,
) {
  return Apollo.useLazyQuery<ListLibrariesQuery, ListLibrariesQueryVariables>(
    ListLibrariesDocument,
    baseOptions,
  );
}
export type ListLibrariesQueryHookResult = ReturnType<typeof useListLibrariesQuery>;
export type ListLibrariesLazyQueryHookResult = ReturnType<typeof useListLibrariesLazyQuery>;
export type ListLibrariesQueryResult = Apollo.QueryResult<
  ListLibrariesQuery,
  ListLibrariesQueryVariables
>;
export const ListLibrariesWithDataDocument = gql`
  query listLibrariesWithData($search: String, $tags: [String]) {
    listLibraries(tags: $tags, search: $search) {
      totalCount
      libraries {
        name
        uuid
        private
        scenarioData {
          deviceCount
          deviceCountNumber
          areaCountNumber
          representation {
            ... on PV {
              serialized
            }
            ... on Storage {
              serialized
            }
            ... on Load {
              serialized
            }
            ... on MarketMaker {
              serialized
              energyRateProfileUuid
            }
            ... on InfiniteBus {
              serialized
              energyRateProfileUuid
              buyingRateProfileUuid
            }
            ... on FiniteDieselGenerator {
              serialized
            }
            ... on AreaOutput {
              serialized
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListLibrariesWithDataQuery__
 *
 * To run a query within a React component, call `useListLibrariesWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLibrariesWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLibrariesWithDataQuery({
 *   variables: {
 *      search: // value for 'search'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useListLibrariesWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListLibrariesWithDataQuery,
    ListLibrariesWithDataQueryVariables
  >,
) {
  return Apollo.useQuery<ListLibrariesWithDataQuery, ListLibrariesWithDataQueryVariables>(
    ListLibrariesWithDataDocument,
    baseOptions,
  );
}
export function useListLibrariesWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListLibrariesWithDataQuery,
    ListLibrariesWithDataQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListLibrariesWithDataQuery, ListLibrariesWithDataQueryVariables>(
    ListLibrariesWithDataDocument,
    baseOptions,
  );
}
export type ListLibrariesWithDataQueryHookResult = ReturnType<typeof useListLibrariesWithDataQuery>;
export type ListLibrariesWithDataLazyQueryHookResult = ReturnType<
  typeof useListLibrariesWithDataLazyQuery
>;
export type ListLibrariesWithDataQueryResult = Apollo.QueryResult<
  ListLibrariesWithDataQuery,
  ListLibrariesWithDataQueryVariables
>;
export const ListLibrariesWithoutScenarioDocument = gql`
  query listLibrariesWithoutScenario(
    $skip: Int
    $first: Int
    $search: String
    $tags: [String]
    $libraryTypes: [LibraryTypesGraphql]
  ) {
    listLibraries(
      skip: $skip
      first: $first
      search: $search
      tags: $tags
      libraryTypes: $libraryTypes
    ) {
      totalCount
      libraries {
        name
        uuid
        description
        user
        private
        readOnly
        tags
        libraryType
        scenarioData {
          deviceCountNumber
          areaCountNumber
        }
      }
    }
  }
`;

/**
 * __useListLibrariesWithoutScenarioQuery__
 *
 * To run a query within a React component, call `useListLibrariesWithoutScenarioQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLibrariesWithoutScenarioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLibrariesWithoutScenarioQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *      tags: // value for 'tags'
 *      libraryTypes: // value for 'libraryTypes'
 *   },
 * });
 */
export function useListLibrariesWithoutScenarioQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListLibrariesWithoutScenarioQuery,
    ListLibrariesWithoutScenarioQueryVariables
  >,
) {
  return Apollo.useQuery<
    ListLibrariesWithoutScenarioQuery,
    ListLibrariesWithoutScenarioQueryVariables
  >(ListLibrariesWithoutScenarioDocument, baseOptions);
}
export function useListLibrariesWithoutScenarioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListLibrariesWithoutScenarioQuery,
    ListLibrariesWithoutScenarioQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListLibrariesWithoutScenarioQuery,
    ListLibrariesWithoutScenarioQueryVariables
  >(ListLibrariesWithoutScenarioDocument, baseOptions);
}
export type ListLibrariesWithoutScenarioQueryHookResult = ReturnType<
  typeof useListLibrariesWithoutScenarioQuery
>;
export type ListLibrariesWithoutScenarioLazyQueryHookResult = ReturnType<
  typeof useListLibrariesWithoutScenarioLazyQuery
>;
export type ListLibrariesWithoutScenarioQueryResult = Apollo.QueryResult<
  ListLibrariesWithoutScenarioQuery,
  ListLibrariesWithoutScenarioQueryVariables
>;
export const ReadLibraryDocument = gql`
  query readLibrary($uuid: String!) {
    readLibrary(uuid: $uuid) {
      name
      uuid
      description
      user
      private
      readOnly
      tags
      libraryType
      scenarioData {
        deviceCountNumber
        areaCountNumber
        representation {
          ... on PV {
            serialized
            type
            uuid
          }
          ... on Storage {
            serialized
            type
            uuid
          }
          ... on Load {
            serialized
            type
            uuid
          }
          ... on FiniteDieselGenerator {
            serialized
            type
            uuid
          }
          ... on MarketMaker {
            serialized
            type
            uuid
          }
          ... on InfiniteBus {
            serialized
            type
            uuid
          }
          ... on AreaOutput {
            serialized
            type
            uuid
          }
          ... on SmartMeter {
            serialized
            type
            uuid
          }
        }
      }
    }
  }
`;

/**
 * __useReadLibraryQuery__
 *
 * To run a query within a React component, call `useReadLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLibraryQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadLibraryQuery(
  baseOptions: Apollo.QueryHookOptions<ReadLibraryQuery, ReadLibraryQueryVariables>,
) {
  return Apollo.useQuery<ReadLibraryQuery, ReadLibraryQueryVariables>(
    ReadLibraryDocument,
    baseOptions,
  );
}
export function useReadLibraryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReadLibraryQuery, ReadLibraryQueryVariables>,
) {
  return Apollo.useLazyQuery<ReadLibraryQuery, ReadLibraryQueryVariables>(
    ReadLibraryDocument,
    baseOptions,
  );
}
export type ReadLibraryQueryHookResult = ReturnType<typeof useReadLibraryQuery>;
export type ReadLibraryLazyQueryHookResult = ReturnType<typeof useReadLibraryLazyQuery>;
export type ReadLibraryQueryResult = Apollo.QueryResult<
  ReadLibraryQuery,
  ReadLibraryQueryVariables
>;
export const ListLiveEventsDocument = gql`
  query listLiveEvents($configUUID: String!) {
    listLiveEvents(configUuid: $configUUID) {
      eventType
      parameters
      timeStamp
      diff
    }
  }
`;

/**
 * __useListLiveEventsQuery__
 *
 * To run a query within a React component, call `useListLiveEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLiveEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLiveEventsQuery({
 *   variables: {
 *      configUUID: // value for 'configUUID'
 *   },
 * });
 */
export function useListLiveEventsQuery(
  baseOptions: Apollo.QueryHookOptions<ListLiveEventsQuery, ListLiveEventsQueryVariables>,
) {
  return Apollo.useQuery<ListLiveEventsQuery, ListLiveEventsQueryVariables>(
    ListLiveEventsDocument,
    baseOptions,
  );
}
export function useListLiveEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListLiveEventsQuery, ListLiveEventsQueryVariables>,
) {
  return Apollo.useLazyQuery<ListLiveEventsQuery, ListLiveEventsQueryVariables>(
    ListLiveEventsDocument,
    baseOptions,
  );
}
export type ListLiveEventsQueryHookResult = ReturnType<typeof useListLiveEventsQuery>;
export type ListLiveEventsLazyQueryHookResult = ReturnType<typeof useListLiveEventsLazyQuery>;
export type ListLiveEventsQueryResult = Apollo.QueryResult<
  ListLiveEventsQuery,
  ListLiveEventsQueryVariables
>;
export const LiveEventsRepresentationDocument = gql`
  query liveEventsRepresentation($configUuid: String!, $time: DateTime!) {
    liveEventsRepresentation(configUuid: $configUuid, time: $time) {
      timeStamp
      representation {
        serialized
      }
    }
  }
`;

/**
 * __useLiveEventsRepresentationQuery__
 *
 * To run a query within a React component, call `useLiveEventsRepresentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLiveEventsRepresentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLiveEventsRepresentationQuery({
 *   variables: {
 *      configUuid: // value for 'configUuid'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useLiveEventsRepresentationQuery(
  baseOptions: Apollo.QueryHookOptions<
    LiveEventsRepresentationQuery,
    LiveEventsRepresentationQueryVariables
  >,
) {
  return Apollo.useQuery<LiveEventsRepresentationQuery, LiveEventsRepresentationQueryVariables>(
    LiveEventsRepresentationDocument,
    baseOptions,
  );
}
export function useLiveEventsRepresentationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LiveEventsRepresentationQuery,
    LiveEventsRepresentationQueryVariables
  >,
) {
  return Apollo.useLazyQuery<LiveEventsRepresentationQuery, LiveEventsRepresentationQueryVariables>(
    LiveEventsRepresentationDocument,
    baseOptions,
  );
}
export type LiveEventsRepresentationQueryHookResult = ReturnType<
  typeof useLiveEventsRepresentationQuery
>;
export type LiveEventsRepresentationLazyQueryHookResult = ReturnType<
  typeof useLiveEventsRepresentationLazyQuery
>;
export type LiveEventsRepresentationQueryResult = Apollo.QueryResult<
  LiveEventsRepresentationQuery,
  LiveEventsRepresentationQueryVariables
>;
export const ListCommunityNotificationsDocument = gql`
  query listCommunityNotifications($search: String, $configUuid: String) {
    listCommunityNotifications(search: $search, configUuid: $configUuid) {
      totalCount
      unreadCount
      notifications {
        id
        title
        message
        status
        sender
        createdAt
        uploadedFiles {
          name
          url
          uuid
        }
      }
    }
  }
`;

/**
 * __useListCommunityNotificationsQuery__
 *
 * To run a query within a React component, call `useListCommunityNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCommunityNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCommunityNotificationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      configUuid: // value for 'configUuid'
 *   },
 * });
 */
export function useListCommunityNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListCommunityNotificationsQuery,
    ListCommunityNotificationsQueryVariables
  >,
) {
  return Apollo.useQuery<ListCommunityNotificationsQuery, ListCommunityNotificationsQueryVariables>(
    ListCommunityNotificationsDocument,
    baseOptions,
  );
}
export function useListCommunityNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCommunityNotificationsQuery,
    ListCommunityNotificationsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ListCommunityNotificationsQuery,
    ListCommunityNotificationsQueryVariables
  >(ListCommunityNotificationsDocument, baseOptions);
}
export type ListCommunityNotificationsQueryHookResult = ReturnType<
  typeof useListCommunityNotificationsQuery
>;
export type ListCommunityNotificationsLazyQueryHookResult = ReturnType<
  typeof useListCommunityNotificationsLazyQuery
>;
export type ListCommunityNotificationsQueryResult = Apollo.QueryResult<
  ListCommunityNotificationsQuery,
  ListCommunityNotificationsQueryVariables
>;
export const ListProjectStatusesDocument = gql`
  query listProjectStatuses($search: String, $nrConfigs: Int) {
    listProjects(search: $search, nrConfigs: $nrConfigs) {
      uuid
      configurations {
        uuid
        name
        resultsStatus
      }
    }
  }
`;

/**
 * __useListProjectStatusesQuery__
 *
 * To run a query within a React component, call `useListProjectStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectStatusesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      nrConfigs: // value for 'nrConfigs'
 *   },
 * });
 */
export function useListProjectStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListProjectStatusesQuery,
    ListProjectStatusesQueryVariables
  >,
) {
  return Apollo.useQuery<ListProjectStatusesQuery, ListProjectStatusesQueryVariables>(
    ListProjectStatusesDocument,
    baseOptions,
  );
}
export function useListProjectStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListProjectStatusesQuery,
    ListProjectStatusesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ListProjectStatusesQuery, ListProjectStatusesQueryVariables>(
    ListProjectStatusesDocument,
    baseOptions,
  );
}
export type ListProjectStatusesQueryHookResult = ReturnType<typeof useListProjectStatusesQuery>;
export type ListProjectStatusesLazyQueryHookResult = ReturnType<
  typeof useListProjectStatusesLazyQuery
>;
export type ListProjectStatusesQueryResult = Apollo.QueryResult<
  ListProjectStatusesQuery,
  ListProjectStatusesQueryVariables
>;
export const ListProjectsDocument = gql`
  query listProjects($search: String, $nrConfigs: Int) {
    listProjects(search: $search, nrConfigs: $nrConfigs) {
      name
      uuid
      description
      user
      private
      configurations {
        uuid
        name
        resultsStatus
      }
    }
  }
`;

/**
 * __useListProjectsQuery__
 *
 * To run a query within a React component, call `useListProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProjectsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      nrConfigs: // value for 'nrConfigs'
 *   },
 * });
 */
export function useListProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>,
) {
  return Apollo.useQuery<ListProjectsQuery, ListProjectsQueryVariables>(
    ListProjectsDocument,
    baseOptions,
  );
}
export function useListProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListProjectsQuery, ListProjectsQueryVariables>,
) {
  return Apollo.useLazyQuery<ListProjectsQuery, ListProjectsQueryVariables>(
    ListProjectsDocument,
    baseOptions,
  );
}
export type ListProjectsQueryHookResult = ReturnType<typeof useListProjectsQuery>;
export type ListProjectsLazyQueryHookResult = ReturnType<typeof useListProjectsLazyQuery>;
export type ListProjectsQueryResult = Apollo.QueryResult<
  ListProjectsQuery,
  ListProjectsQueryVariables
>;
export const ReadProjectDocument = gql`
  query readProject($uuid: String!) {
    readProject(uuid: $uuid) {
      name
      uuid
      user
      description
      private
      configurations {
        uuid
      }
    }
  }
`;

/**
 * __useReadProjectQuery__
 *
 * To run a query within a React component, call `useReadProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProjectQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadProjectQuery(
  baseOptions: Apollo.QueryHookOptions<ReadProjectQuery, ReadProjectQueryVariables>,
) {
  return Apollo.useQuery<ReadProjectQuery, ReadProjectQueryVariables>(
    ReadProjectDocument,
    baseOptions,
  );
}
export function useReadProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReadProjectQuery, ReadProjectQueryVariables>,
) {
  return Apollo.useLazyQuery<ReadProjectQuery, ReadProjectQueryVariables>(
    ReadProjectDocument,
    baseOptions,
  );
}
export type ReadProjectQueryHookResult = ReturnType<typeof useReadProjectQuery>;
export type ReadProjectLazyQueryHookResult = ReturnType<typeof useReadProjectLazyQuery>;
export type ReadProjectQueryResult = Apollo.QueryResult<
  ReadProjectQuery,
  ReadProjectQueryVariables
>;
export const ReadProjectLocationVisibilityDocument = gql`
  query readProjectLocationVisibility($projectUuid: String!) {
    readProject(uuid: $projectUuid) {
      locationVisible
      private
    }
  }
`;

/**
 * __useReadProjectLocationVisibilityQuery__
 *
 * To run a query within a React component, call `useReadProjectLocationVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProjectLocationVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProjectLocationVisibilityQuery({
 *   variables: {
 *      projectUuid: // value for 'projectUuid'
 *   },
 * });
 */
export function useReadProjectLocationVisibilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadProjectLocationVisibilityQuery,
    ReadProjectLocationVisibilityQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReadProjectLocationVisibilityQuery,
    ReadProjectLocationVisibilityQueryVariables
  >(ReadProjectLocationVisibilityDocument, baseOptions);
}
export function useReadProjectLocationVisibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadProjectLocationVisibilityQuery,
    ReadProjectLocationVisibilityQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadProjectLocationVisibilityQuery,
    ReadProjectLocationVisibilityQueryVariables
  >(ReadProjectLocationVisibilityDocument, baseOptions);
}
export type ReadProjectLocationVisibilityQueryHookResult = ReturnType<
  typeof useReadProjectLocationVisibilityQuery
>;
export type ReadProjectLocationVisibilityLazyQueryHookResult = ReturnType<
  typeof useReadProjectLocationVisibilityLazyQuery
>;
export type ReadProjectLocationVisibilityQueryResult = Apollo.QueryResult<
  ReadProjectLocationVisibilityQuery,
  ReadProjectLocationVisibilityQueryVariables
>;
export const ReadProjectTitleDocument = gql`
  query readProjectTitle($uuid: String!) {
    readProject(uuid: $uuid) {
      name
    }
  }
`;

/**
 * __useReadProjectTitleQuery__
 *
 * To run a query within a React component, call `useReadProjectTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadProjectTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadProjectTitleQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadProjectTitleQuery(
  baseOptions: Apollo.QueryHookOptions<ReadProjectTitleQuery, ReadProjectTitleQueryVariables>,
) {
  return Apollo.useQuery<ReadProjectTitleQuery, ReadProjectTitleQueryVariables>(
    ReadProjectTitleDocument,
    baseOptions,
  );
}
export function useReadProjectTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReadProjectTitleQuery, ReadProjectTitleQueryVariables>,
) {
  return Apollo.useLazyQuery<ReadProjectTitleQuery, ReadProjectTitleQueryVariables>(
    ReadProjectTitleDocument,
    baseOptions,
  );
}
export type ReadProjectTitleQueryHookResult = ReturnType<typeof useReadProjectTitleQuery>;
export type ReadProjectTitleLazyQueryHookResult = ReturnType<typeof useReadProjectTitleLazyQuery>;
export type ReadProjectTitleQueryResult = Apollo.QueryResult<
  ReadProjectTitleQuery,
  ReadProjectTitleQueryVariables
>;
export const CommunitySummaryResultsDocument = gql`
  query communitySummaryResults($jobId: String!, $communityUuid: String!) {
    simulationResults(jobId: $jobId, uuid: $communityUuid, timeResolutionHrs: 2) {
      bills
      cumulativeNetEnergyFlow
    }
  }
`;

/**
 * __useCommunitySummaryResultsQuery__
 *
 * To run a query within a React component, call `useCommunitySummaryResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunitySummaryResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunitySummaryResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      communityUuid: // value for 'communityUuid'
 *   },
 * });
 */
export function useCommunitySummaryResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CommunitySummaryResultsQuery,
    CommunitySummaryResultsQueryVariables
  >,
) {
  return Apollo.useQuery<CommunitySummaryResultsQuery, CommunitySummaryResultsQueryVariables>(
    CommunitySummaryResultsDocument,
    baseOptions,
  );
}
export function useCommunitySummaryResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommunitySummaryResultsQuery,
    CommunitySummaryResultsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<CommunitySummaryResultsQuery, CommunitySummaryResultsQueryVariables>(
    CommunitySummaryResultsDocument,
    baseOptions,
  );
}
export type CommunitySummaryResultsQueryHookResult = ReturnType<
  typeof useCommunitySummaryResultsQuery
>;
export type CommunitySummaryResultsLazyQueryHookResult = ReturnType<
  typeof useCommunitySummaryResultsLazyQuery
>;
export type CommunitySummaryResultsQueryResult = Apollo.QueryResult<
  CommunitySummaryResultsQuery,
  CommunitySummaryResultsQueryVariables
>;
export const GetOperationalDocument = gql`
  query getOperational {
    getScmMemberOperationalUuid
  }
`;

/**
 * __useGetOperationalQuery__
 *
 * To run a query within a React component, call `useGetOperationalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOperationalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOperationalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOperationalQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOperationalQuery, GetOperationalQueryVariables>,
) {
  return Apollo.useQuery<GetOperationalQuery, GetOperationalQueryVariables>(
    GetOperationalDocument,
    baseOptions,
  );
}
export function useGetOperationalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOperationalQuery, GetOperationalQueryVariables>,
) {
  return Apollo.useLazyQuery<GetOperationalQuery, GetOperationalQueryVariables>(
    GetOperationalDocument,
    baseOptions,
  );
}
export type GetOperationalQueryHookResult = ReturnType<typeof useGetOperationalQuery>;
export type GetOperationalLazyQueryHookResult = ReturnType<typeof useGetOperationalLazyQuery>;
export type GetOperationalQueryResult = Apollo.QueryResult<
  GetOperationalQuery,
  GetOperationalQueryVariables
>;
export const HouseSummaryResultsDocument = gql`
  query houseSummaryResults($jobId: String!, $assetUuid: String!, $timeResolutionHrs: Int) {
    simulationResults(jobId: $jobId, uuid: $assetUuid, timeResolutionHrs: $timeResolutionHrs) {
      currentMarket
      areaThroughput
      marketSummary {
        averageEnergyRate
        timestamp
      }
      status
    }
  }
`;

/**
 * __useHouseSummaryResultsQuery__
 *
 * To run a query within a React component, call `useHouseSummaryResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseSummaryResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseSummaryResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      assetUuid: // value for 'assetUuid'
 *      timeResolutionHrs: // value for 'timeResolutionHrs'
 *   },
 * });
 */
export function useHouseSummaryResultsQuery(
  baseOptions: Apollo.QueryHookOptions<HouseSummaryResultsQuery, HouseSummaryResultsQueryVariables>,
) {
  return Apollo.useQuery<HouseSummaryResultsQuery, HouseSummaryResultsQueryVariables>(
    HouseSummaryResultsDocument,
    baseOptions,
  );
}
export function useHouseSummaryResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HouseSummaryResultsQuery,
    HouseSummaryResultsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<HouseSummaryResultsQuery, HouseSummaryResultsQueryVariables>(
    HouseSummaryResultsDocument,
    baseOptions,
  );
}
export type HouseSummaryResultsQueryHookResult = ReturnType<typeof useHouseSummaryResultsQuery>;
export type HouseSummaryResultsLazyQueryHookResult = ReturnType<
  typeof useHouseSummaryResultsLazyQuery
>;
export type HouseSummaryResultsQueryResult = Apollo.QueryResult<
  HouseSummaryResultsQuery,
  HouseSummaryResultsQueryVariables
>;
export const ListScmDocument = gql`
  query listSCM($configType: ConfigType) {
    listScmCommunities(configurationType: $configType) {
      totalCount
      configurations {
        name
        uuid
        user
        timestamp
        project {
          uuid
        }
        configType: type
        resultsStatus
        coordinates {
          latitude
          longitude
        }
      }
    }
  }
`;

/**
 * __useListScmQuery__
 *
 * To run a query within a React component, call `useListScmQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScmQuery({
 *   variables: {
 *      configType: // value for 'configType'
 *   },
 * });
 */
export function useListScmQuery(
  baseOptions?: Apollo.QueryHookOptions<ListScmQuery, ListScmQueryVariables>,
) {
  return Apollo.useQuery<ListScmQuery, ListScmQueryVariables>(ListScmDocument, baseOptions);
}
export function useListScmLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListScmQuery, ListScmQueryVariables>,
) {
  return Apollo.useLazyQuery<ListScmQuery, ListScmQueryVariables>(ListScmDocument, baseOptions);
}
export type ListScmQueryHookResult = ReturnType<typeof useListScmQuery>;
export type ListScmLazyQueryHookResult = ReturnType<typeof useListScmLazyQuery>;
export type ListScmQueryResult = Apollo.QueryResult<ListScmQuery, ListScmQueryVariables>;
export const ReadScoreboardResultsDocument = gql`
  query readScoreboardResults($jobId: String!) {
    simulationStatus(jobId: $jobId)
    scoreboardResults(jobId: $jobId)
  }
`;

/**
 * __useReadScoreboardResultsQuery__
 *
 * To run a query within a React component, call `useReadScoreboardResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadScoreboardResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadScoreboardResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useReadScoreboardResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadScoreboardResultsQuery,
    ReadScoreboardResultsQueryVariables
  >,
) {
  return Apollo.useQuery<ReadScoreboardResultsQuery, ReadScoreboardResultsQueryVariables>(
    ReadScoreboardResultsDocument,
    baseOptions,
  );
}
export function useReadScoreboardResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadScoreboardResultsQuery,
    ReadScoreboardResultsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ReadScoreboardResultsQuery, ReadScoreboardResultsQueryVariables>(
    ReadScoreboardResultsDocument,
    baseOptions,
  );
}
export type ReadScoreboardResultsQueryHookResult = ReturnType<typeof useReadScoreboardResultsQuery>;
export type ReadScoreboardResultsLazyQueryHookResult = ReturnType<
  typeof useReadScoreboardResultsLazyQuery
>;
export type ReadScoreboardResultsQueryResult = Apollo.QueryResult<
  ReadScoreboardResultsQuery,
  ReadScoreboardResultsQueryVariables
>;
export const ReadSimulationProgressDocument = gql`
  query readSimulationProgress($uuid: String!) {
    readConfiguration(uuid: $uuid) {
      resultsStatus
      resultsProgressInfo {
        percentageCompleted
      }
    }
  }
`;

/**
 * __useReadSimulationProgressQuery__
 *
 * To run a query within a React component, call `useReadSimulationProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSimulationProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSimulationProgressQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadSimulationProgressQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadSimulationProgressQuery,
    ReadSimulationProgressQueryVariables
  >,
) {
  return Apollo.useQuery<ReadSimulationProgressQuery, ReadSimulationProgressQueryVariables>(
    ReadSimulationProgressDocument,
    baseOptions,
  );
}
export function useReadSimulationProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadSimulationProgressQuery,
    ReadSimulationProgressQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ReadSimulationProgressQuery, ReadSimulationProgressQueryVariables>(
    ReadSimulationProgressDocument,
    baseOptions,
  );
}
export type ReadSimulationProgressQueryHookResult = ReturnType<
  typeof useReadSimulationProgressQuery
>;
export type ReadSimulationProgressLazyQueryHookResult = ReturnType<
  typeof useReadSimulationProgressLazyQuery
>;
export type ReadSimulationProgressQueryResult = Apollo.QueryResult<
  ReadSimulationProgressQuery,
  ReadSimulationProgressQueryVariables
>;
export const ReadSimulationResultsDocument = gql`
  query readSimulationResults(
    $jobId: String!
    $uuid: String!
    $startTime: DateTime
    $endTime: DateTime
  ) {
    simulationResults(jobId: $jobId, uuid: $uuid, startTime: $startTime, endTime: $endTime) {
      areaUuid
      cumulativeBills
      cumulativeGridTrades
      currentMarket
      cumulativeNetEnergyFlow
      deviceStatistics
      tradeProfileAreaThroughput
      kpi
      marketSummary {
        externalTradedVolume
        averageEnergyRate
        timestamp
        tradedVolume
      }
      bills
      priceEnergyDay
      latestSavingsKpi
      savingsKpiProfile(resolution: DAILY)
      assetsInfo
      progressInfo {
        percentageCompleted
        etaSeconds
        elapsedTimeSeconds
      }
      status
    }
  }
`;

/**
 * __useReadSimulationResultsQuery__
 *
 * To run a query within a React component, call `useReadSimulationResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSimulationResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSimulationResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      uuid: // value for 'uuid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useReadSimulationResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadSimulationResultsQuery,
    ReadSimulationResultsQueryVariables
  >,
) {
  return Apollo.useQuery<ReadSimulationResultsQuery, ReadSimulationResultsQueryVariables>(
    ReadSimulationResultsDocument,
    baseOptions,
  );
}
export function useReadSimulationResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadSimulationResultsQuery,
    ReadSimulationResultsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ReadSimulationResultsQuery, ReadSimulationResultsQueryVariables>(
    ReadSimulationResultsDocument,
    baseOptions,
  );
}
export type ReadSimulationResultsQueryHookResult = ReturnType<typeof useReadSimulationResultsQuery>;
export type ReadSimulationResultsLazyQueryHookResult = ReturnType<
  typeof useReadSimulationResultsLazyQuery
>;
export type ReadSimulationResultsQueryResult = Apollo.QueryResult<
  ReadSimulationResultsQuery,
  ReadSimulationResultsQueryVariables
>;
export const ReadSimulationResultsBidTradeDocument = gql`
  query readSimulationResultsBidTrade(
    $jobId: String!
    $assetUuid: String!
    $startTime: DateTime
    $endTime: DateTime
  ) {
    simulationResults(jobId: $jobId, uuid: $assetUuid, startTime: $startTime, endTime: $endTime) {
      bidsOffersTrades
    }
  }
`;

/**
 * __useReadSimulationResultsBidTradeQuery__
 *
 * To run a query within a React component, call `useReadSimulationResultsBidTradeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSimulationResultsBidTradeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSimulationResultsBidTradeQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      assetUuid: // value for 'assetUuid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useReadSimulationResultsBidTradeQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadSimulationResultsBidTradeQuery,
    ReadSimulationResultsBidTradeQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReadSimulationResultsBidTradeQuery,
    ReadSimulationResultsBidTradeQueryVariables
  >(ReadSimulationResultsBidTradeDocument, baseOptions);
}
export function useReadSimulationResultsBidTradeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadSimulationResultsBidTradeQuery,
    ReadSimulationResultsBidTradeQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadSimulationResultsBidTradeQuery,
    ReadSimulationResultsBidTradeQueryVariables
  >(ReadSimulationResultsBidTradeDocument, baseOptions);
}
export type ReadSimulationResultsBidTradeQueryHookResult = ReturnType<
  typeof useReadSimulationResultsBidTradeQuery
>;
export type ReadSimulationResultsBidTradeLazyQueryHookResult = ReturnType<
  typeof useReadSimulationResultsBidTradeLazyQuery
>;
export type ReadSimulationResultsBidTradeQueryResult = Apollo.QueryResult<
  ReadSimulationResultsBidTradeQuery,
  ReadSimulationResultsBidTradeQueryVariables
>;
export const ReadSimulationResultsStatusDocument = gql`
  query readSimulationResultsStatus($jobId: String!, $uuid: String!) {
    simulationResults(jobId: $jobId, uuid: $uuid) {
      progressInfo {
        percentageCompleted
        etaSeconds
        elapsedTimeSeconds
      }
      status
    }
  }
`;

/**
 * __useReadSimulationResultsStatusQuery__
 *
 * To run a query within a React component, call `useReadSimulationResultsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSimulationResultsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSimulationResultsStatusQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReadSimulationResultsStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadSimulationResultsStatusQuery,
    ReadSimulationResultsStatusQueryVariables
  >,
) {
  return Apollo.useQuery<
    ReadSimulationResultsStatusQuery,
    ReadSimulationResultsStatusQueryVariables
  >(ReadSimulationResultsStatusDocument, baseOptions);
}
export function useReadSimulationResultsStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadSimulationResultsStatusQuery,
    ReadSimulationResultsStatusQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ReadSimulationResultsStatusQuery,
    ReadSimulationResultsStatusQueryVariables
  >(ReadSimulationResultsStatusDocument, baseOptions);
}
export type ReadSimulationResultsStatusQueryHookResult = ReturnType<
  typeof useReadSimulationResultsStatusQuery
>;
export type ReadSimulationResultsStatusLazyQueryHookResult = ReturnType<
  typeof useReadSimulationResultsStatusLazyQuery
>;
export type ReadSimulationResultsStatusQueryResult = Apollo.QueryResult<
  ReadSimulationResultsStatusQuery,
  ReadSimulationResultsStatusQueryVariables
>;
export const ReadSimulationStatusDocument = gql`
  query readSimulationStatus($jobId: String!) {
    simulationStatus(jobId: $jobId)
  }
`;

/**
 * __useReadSimulationStatusQuery__
 *
 * To run a query within a React component, call `useReadSimulationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSimulationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSimulationStatusQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useReadSimulationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReadSimulationStatusQuery,
    ReadSimulationStatusQueryVariables
  >,
) {
  return Apollo.useQuery<ReadSimulationStatusQuery, ReadSimulationStatusQueryVariables>(
    ReadSimulationStatusDocument,
    baseOptions,
  );
}
export function useReadSimulationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadSimulationStatusQuery,
    ReadSimulationStatusQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ReadSimulationStatusQuery, ReadSimulationStatusQueryVariables>(
    ReadSimulationStatusDocument,
    baseOptions,
  );
}
export type ReadSimulationStatusQueryHookResult = ReturnType<typeof useReadSimulationStatusQuery>;
export type ReadSimulationStatusLazyQueryHookResult = ReturnType<
  typeof useReadSimulationStatusLazyQuery
>;
export type ReadSimulationStatusQueryResult = Apollo.QueryResult<
  ReadSimulationStatusQuery,
  ReadSimulationStatusQueryVariables
>;
export const ScmAccumulatedBillsDifferencesDocument = gql`
  query scmAccumulatedBillsDifferences(
    $jobId: String!
    $uuid: String!
    $startTime: DateTime!
    $endTime: DateTime!
  ) {
    scmAccumulatedBillsDifferences(
      jobId: $jobId
      areaUuid: $uuid
      startTime: $startTime
      endTime: $endTime
    )
  }
`;

/**
 * __useScmAccumulatedBillsDifferencesQuery__
 *
 * To run a query within a React component, call `useScmAccumulatedBillsDifferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScmAccumulatedBillsDifferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScmAccumulatedBillsDifferencesQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      uuid: // value for 'uuid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useScmAccumulatedBillsDifferencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScmAccumulatedBillsDifferencesQuery,
    ScmAccumulatedBillsDifferencesQueryVariables
  >,
) {
  return Apollo.useQuery<
    ScmAccumulatedBillsDifferencesQuery,
    ScmAccumulatedBillsDifferencesQueryVariables
  >(ScmAccumulatedBillsDifferencesDocument, baseOptions);
}
export function useScmAccumulatedBillsDifferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScmAccumulatedBillsDifferencesQuery,
    ScmAccumulatedBillsDifferencesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ScmAccumulatedBillsDifferencesQuery,
    ScmAccumulatedBillsDifferencesQueryVariables
  >(ScmAccumulatedBillsDifferencesDocument, baseOptions);
}
export type ScmAccumulatedBillsDifferencesQueryHookResult = ReturnType<
  typeof useScmAccumulatedBillsDifferencesQuery
>;
export type ScmAccumulatedBillsDifferencesLazyQueryHookResult = ReturnType<
  typeof useScmAccumulatedBillsDifferencesLazyQuery
>;
export type ScmAccumulatedBillsDifferencesQueryResult = Apollo.QueryResult<
  ScmAccumulatedBillsDifferencesQuery,
  ScmAccumulatedBillsDifferencesQueryVariables
>;
export const ScmAccumulatedBillsDifferencesCommunityDocument = gql`
  query scmAccumulatedBillsDifferencesCommunity(
    $jobId: String!
    $startTime: DateTime!
    $endTime: DateTime!
  ) {
    scmAccumulatedBillsDifferencesCommunity(jobId: $jobId, startTime: $startTime, endTime: $endTime)
  }
`;

/**
 * __useScmAccumulatedBillsDifferencesCommunityQuery__
 *
 * To run a query within a React component, call `useScmAccumulatedBillsDifferencesCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useScmAccumulatedBillsDifferencesCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScmAccumulatedBillsDifferencesCommunityQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useScmAccumulatedBillsDifferencesCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScmAccumulatedBillsDifferencesCommunityQuery,
    ScmAccumulatedBillsDifferencesCommunityQueryVariables
  >,
) {
  return Apollo.useQuery<
    ScmAccumulatedBillsDifferencesCommunityQuery,
    ScmAccumulatedBillsDifferencesCommunityQueryVariables
  >(ScmAccumulatedBillsDifferencesCommunityDocument, baseOptions);
}
export function useScmAccumulatedBillsDifferencesCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScmAccumulatedBillsDifferencesCommunityQuery,
    ScmAccumulatedBillsDifferencesCommunityQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ScmAccumulatedBillsDifferencesCommunityQuery,
    ScmAccumulatedBillsDifferencesCommunityQueryVariables
  >(ScmAccumulatedBillsDifferencesCommunityDocument, baseOptions);
}
export type ScmAccumulatedBillsDifferencesCommunityQueryHookResult = ReturnType<
  typeof useScmAccumulatedBillsDifferencesCommunityQuery
>;
export type ScmAccumulatedBillsDifferencesCommunityLazyQueryHookResult = ReturnType<
  typeof useScmAccumulatedBillsDifferencesCommunityLazyQuery
>;
export type ScmAccumulatedBillsDifferencesCommunityQueryResult = Apollo.QueryResult<
  ScmAccumulatedBillsDifferencesCommunityQuery,
  ScmAccumulatedBillsDifferencesCommunityQueryVariables
>;
export const ScmAccumulatedKpiDifferencesDocument = gql`
  query scmAccumulatedKpiDifferences(
    $jobId: String!
    $uuid: String!
    $startTime: DateTime!
    $endTime: DateTime!
  ) {
    scmAccumulatedKpiDifferences(
      jobId: $jobId
      areaUuid: $uuid
      startTime: $startTime
      endTime: $endTime
    )
  }
`;

/**
 * __useScmAccumulatedKpiDifferencesQuery__
 *
 * To run a query within a React component, call `useScmAccumulatedKpiDifferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScmAccumulatedKpiDifferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScmAccumulatedKpiDifferencesQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      uuid: // value for 'uuid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useScmAccumulatedKpiDifferencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScmAccumulatedKpiDifferencesQuery,
    ScmAccumulatedKpiDifferencesQueryVariables
  >,
) {
  return Apollo.useQuery<
    ScmAccumulatedKpiDifferencesQuery,
    ScmAccumulatedKpiDifferencesQueryVariables
  >(ScmAccumulatedKpiDifferencesDocument, baseOptions);
}
export function useScmAccumulatedKpiDifferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScmAccumulatedKpiDifferencesQuery,
    ScmAccumulatedKpiDifferencesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<
    ScmAccumulatedKpiDifferencesQuery,
    ScmAccumulatedKpiDifferencesQueryVariables
  >(ScmAccumulatedKpiDifferencesDocument, baseOptions);
}
export type ScmAccumulatedKpiDifferencesQueryHookResult = ReturnType<
  typeof useScmAccumulatedKpiDifferencesQuery
>;
export type ScmAccumulatedKpiDifferencesLazyQueryHookResult = ReturnType<
  typeof useScmAccumulatedKpiDifferencesLazyQuery
>;
export type ScmAccumulatedKpiDifferencesQueryResult = Apollo.QueryResult<
  ScmAccumulatedKpiDifferencesQuery,
  ScmAccumulatedKpiDifferencesQueryVariables
>;
export const ScmAccumulatedResultsDocument = gql`
  query scmAccumulatedResults($jobId: String!, $uuid: String!, $timestamp: DateTime!) {
    scmAccumulatedResults(jobId: $jobId, areaUuid: $uuid, timestamp: $timestamp) {
      cumulativeGridTrades
      cumulativeNetEnergyFlow
      bills
      cumulativeBills
      kpi
    }
  }
`;

/**
 * __useScmAccumulatedResultsQuery__
 *
 * To run a query within a React component, call `useScmAccumulatedResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScmAccumulatedResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScmAccumulatedResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      uuid: // value for 'uuid'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useScmAccumulatedResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScmAccumulatedResultsQuery,
    ScmAccumulatedResultsQueryVariables
  >,
) {
  return Apollo.useQuery<ScmAccumulatedResultsQuery, ScmAccumulatedResultsQueryVariables>(
    ScmAccumulatedResultsDocument,
    baseOptions,
  );
}
export function useScmAccumulatedResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScmAccumulatedResultsQuery,
    ScmAccumulatedResultsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ScmAccumulatedResultsQuery, ScmAccumulatedResultsQueryVariables>(
    ScmAccumulatedResultsDocument,
    baseOptions,
  );
}
export type ScmAccumulatedResultsQueryHookResult = ReturnType<typeof useScmAccumulatedResultsQuery>;
export type ScmAccumulatedResultsLazyQueryHookResult = ReturnType<
  typeof useScmAccumulatedResultsLazyQuery
>;
export type ScmAccumulatedResultsQueryResult = Apollo.QueryResult<
  ScmAccumulatedResultsQuery,
  ScmAccumulatedResultsQueryVariables
>;
export const ScmProfileResultsDocument = gql`
  query scmProfileResults(
    $jobId: String!
    $uuid: String!
    $startTime: DateTime!
    $endTime: DateTime!
  ) {
    scmProfileResults(jobId: $jobId, areaUuid: $uuid, startTime: $startTime, endTime: $endTime) {
      billsEnergy
      kpi
    }
  }
`;

/**
 * __useScmProfileResultsQuery__
 *
 * To run a query within a React component, call `useScmProfileResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScmProfileResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScmProfileResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      uuid: // value for 'uuid'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useScmProfileResultsQuery(
  baseOptions: Apollo.QueryHookOptions<ScmProfileResultsQuery, ScmProfileResultsQueryVariables>,
) {
  return Apollo.useQuery<ScmProfileResultsQuery, ScmProfileResultsQueryVariables>(
    ScmProfileResultsDocument,
    baseOptions,
  );
}
export function useScmProfileResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScmProfileResultsQuery,
    ScmProfileResultsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<ScmProfileResultsQuery, ScmProfileResultsQueryVariables>(
    ScmProfileResultsDocument,
    baseOptions,
  );
}
export type ScmProfileResultsQueryHookResult = ReturnType<typeof useScmProfileResultsQuery>;
export type ScmProfileResultsLazyQueryHookResult = ReturnType<typeof useScmProfileResultsLazyQuery>;
export type ScmProfileResultsQueryResult = Apollo.QueryResult<
  ScmProfileResultsQuery,
  ScmProfileResultsQueryVariables
>;
export const SimulationGlobalResultsDocument = gql`
  query simulationGlobalResults($jobId: String!) {
    simulationGlobalResults(jobId: $jobId) {
      launchDatetime
      status
    }
  }
`;

/**
 * __useSimulationGlobalResultsQuery__
 *
 * To run a query within a React component, call `useSimulationGlobalResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulationGlobalResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulationGlobalResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useSimulationGlobalResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SimulationGlobalResultsQuery,
    SimulationGlobalResultsQueryVariables
  >,
) {
  return Apollo.useQuery<SimulationGlobalResultsQuery, SimulationGlobalResultsQueryVariables>(
    SimulationGlobalResultsDocument,
    baseOptions,
  );
}
export function useSimulationGlobalResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimulationGlobalResultsQuery,
    SimulationGlobalResultsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<SimulationGlobalResultsQuery, SimulationGlobalResultsQueryVariables>(
    SimulationGlobalResultsDocument,
    baseOptions,
  );
}
export type SimulationGlobalResultsQueryHookResult = ReturnType<
  typeof useSimulationGlobalResultsQuery
>;
export type SimulationGlobalResultsLazyQueryHookResult = ReturnType<
  typeof useSimulationGlobalResultsLazyQuery
>;
export type SimulationGlobalResultsQueryResult = Apollo.QueryResult<
  SimulationGlobalResultsQuery,
  SimulationGlobalResultsQueryVariables
>;
export const AvailableUserRolesDocument = gql`
  query availableUserRoles {
    availableUserRoles {
      userRoles {
        roleName
        value
      }
    }
  }
`;

/**
 * __useAvailableUserRolesQuery__
 *
 * To run a query within a React component, call `useAvailableUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableUserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableUserRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<AvailableUserRolesQuery, AvailableUserRolesQueryVariables>,
) {
  return Apollo.useQuery<AvailableUserRolesQuery, AvailableUserRolesQueryVariables>(
    AvailableUserRolesDocument,
    baseOptions,
  );
}
export function useAvailableUserRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableUserRolesQuery,
    AvailableUserRolesQueryVariables
  >,
) {
  return Apollo.useLazyQuery<AvailableUserRolesQuery, AvailableUserRolesQueryVariables>(
    AvailableUserRolesDocument,
    baseOptions,
  );
}
export type AvailableUserRolesQueryHookResult = ReturnType<typeof useAvailableUserRolesQuery>;
export type AvailableUserRolesLazyQueryHookResult = ReturnType<
  typeof useAvailableUserRolesLazyQuery
>;
export type AvailableUserRolesQueryResult = Apollo.QueryResult<
  AvailableUserRolesQuery,
  AvailableUserRolesQueryVariables
>;
export const RegisteredUserDocument = gql`
  query registeredUser {
    registeredUsers {
      username
      superuser
    }
  }
`;

/**
 * __useRegisteredUserQuery__
 *
 * To run a query within a React component, call `useRegisteredUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegisteredUserQuery(
  baseOptions?: Apollo.QueryHookOptions<RegisteredUserQuery, RegisteredUserQueryVariables>,
) {
  return Apollo.useQuery<RegisteredUserQuery, RegisteredUserQueryVariables>(
    RegisteredUserDocument,
    baseOptions,
  );
}
export function useRegisteredUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegisteredUserQuery, RegisteredUserQueryVariables>,
) {
  return Apollo.useLazyQuery<RegisteredUserQuery, RegisteredUserQueryVariables>(
    RegisteredUserDocument,
    baseOptions,
  );
}
export type RegisteredUserQueryHookResult = ReturnType<typeof useRegisteredUserQuery>;
export type RegisteredUserLazyQueryHookResult = ReturnType<typeof useRegisteredUserLazyQuery>;
export type RegisteredUserQueryResult = Apollo.QueryResult<
  RegisteredUserQuery,
  RegisteredUserQueryVariables
>;
