import { Currencies } from 'src/graphql';
import { TConfigurationState } from 'src/redux/configuration/configuration.slice';

export type TChartSavingsProps = {
  mode?: 'Community' | 'Home';
  className?: string;
  style?: React.CSSProperties;
  currency: Currencies;
  kpi?: NonNullable<TConfigurationState['simulationResults']>['kpi'];
  homeBillValue?: number;
  homeBillInfo?: string;
  homeSavings?: number;
  homeSavingsInfo?: string;
  communitySavings?: number;
  communitySelfSufficiency?: number;
  totalBenefit?: number;
  title?: string;
  showToggle?: boolean;
  horizontal?: boolean;
};

export enum EViewType {
  SelfSufficiency = 'Self-Sufficiency',
  SelfConsumption = 'Self-Consumption',
  Savings = 'Savings',
  CommunitySelfSufficiency = 'Community Self-Sufficiency',
  CommunitySavings = 'Community Savings',
  EnergyBill = 'EnergyBill',
  TotalBenefit = 'TotalBenefit',
}

export enum ESavingType {
  Earning = 'Earning',
  Neutral = 'Neutral',
  Saving = 'Saving',
  Losses = 'Losses',
}

export type TKeyResultInfoDescriptions = {
  title: string;
  description: string;
};
