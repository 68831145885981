import { EModalAssetsManagerView, ModalAssetsManager } from 'src/components/ModalAssetsManager';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { delay, throttle } from 'lodash';
import {
  initialApplicationState,
  setModalAssetManagerActiveView,
  setModalHouseSummaryFormVariant,
  setModalHouseSummaryView,
} from 'src/redux/application/application.slice';
import {
  selectActiveConfigurationUuid,
  selectAssetsTreeRelations,
  selectAssetsUnderUuid,
  selectAssetsValues,
  selectCommunityAsset,
  selectConfigType,
  selectConfigurationCenter,
  selectGridMarketAsset,
  selectRootAssetUuid,
} from 'src/redux/configuration/configuration.selectors';
import {
  selectIsEmbed,
  selectModalHouseSummaryFormVariant,
  selectModalHouseSummaryView,
  selectOnboardingStep,
} from 'src/redux/application/application.selectors';

import { BaseIcon } from 'src/components/BaseIcon/BaseIcon';
import { BaseTooltipTrigger } from 'src/components/BaseTooltip';
import { EUserRoles } from 'src/typings/base-types';
import { ModalCommunitySummary } from 'src/components/ModalCommunitySummary';
import { Popup } from 'src/components/WorldMap/components/Popup';
import { TGridModalPosState } from 'src/components/WorldMapSelectedCommunityAssets';
import { TWorldMapSelectedCommunityAssetsProps } from './WorldMapSelectedCommunityAssets.types';
import WorldMapContext from 'src/contexts/WorldMapContext';
import classNames from 'classnames';
import { convertLngLat } from 'src/utils/worldMap/helpers';
import g from 'src/components/ModalAssetsManager/ModalAssetsManager.module.scss';
import s from './WorldMapSelectedCommunityAssets.module.scss';
import { selectActiveModals } from 'src/redux/modals/modals.selectors';
import { selectCanaryNetworkUsers } from 'src/redux/canaryNetwork/canaryNetwork.selectors';
import { selectSCMFlow } from 'src/redux/scm/scm.selectors';
import { setSelectedAssetUuid } from 'src/redux/configuration/configuration.slice';
import { useAppDispatch } from 'src/redux/store';
import { useIsUserACommunityMember } from 'src/hooks/useIsUserACommunityMember';
import { useSelector } from 'react-redux';

export const WorldMapSelectedCommunityAssets: React.FC<TWorldMapSelectedCommunityAssetsProps> = React.memo(
  ({
    selectedAssetUuid,
    rawSelectedAssetUuid,
    disableInteraction,
    onAddNewCustomHouse,
    onSettingsDataSave,
    onAssetValuesSave,
    onAssetRemove,
    onCommunityRemove,
    onAssetDuplicate,
    onAddAssetUnderUuid,
    onGridMarketClick,
    onAssetValuesSavePromise,
    addSaveKey,
  }) => {
    const dispatch = useAppDispatch();
    const isEmbed = useSelector(selectIsEmbed);
    const assetsValues = useSelector(selectAssetsValues);
    const gridMarketAsset = useSelector(selectGridMarketAsset);
    const communityAsset = useSelector(selectCommunityAsset);
    const configType = useSelector(selectConfigType);
    const assetsUnderSelectedUuid = useSelector(selectAssetsUnderUuid(communityAsset?.uuid));
    const assetsTreeRelations = useSelector(selectAssetsTreeRelations);
    const configurationCenter = useSelector(selectConfigurationCenter);
    const activeConfigurationUuid = useSelector(selectActiveConfigurationUuid);
    const cnUsers = useSelector(selectCanaryNetworkUsers);
    const rootAssetUuid = useSelector(selectRootAssetUuid);
    const isSCMFlow = useSelector(selectSCMFlow);
    const { mapService } = useContext(WorldMapContext);
    const { isUserACommunityMember } = useIsUserACommunityMember();
    const [summaryModalPos, setSummaryModalPos] = useState({
      lng: configurationCenter?.lng,
      lat: configurationCenter?.lat,
    });
    const [gridModalPos, setGridModalPos] = useState<TGridModalPosState>({
      lng: configurationCenter?.lng,
      lat: configurationCenter?.lat,
    });
    const modalHouseSummaryView = useSelector(selectModalHouseSummaryView);
    const modalHouseSummaryFormVariant = useSelector(selectModalHouseSummaryFormVariant);
    const step = useSelector(selectOnboardingStep);
    const activeModals = useSelector(selectActiveModals);

    const showCommunityOnTheMap =
      communityAsset &&
      (assetsTreeRelations[communityAsset.uuid].length > 1 || activeConfigurationUuid);
    const showCommunityModal = Boolean(
      selectedAssetUuid && selectedAssetUuid === communityAsset?.uuid,
    );
    const showMarketModal = Boolean(
      selectedAssetUuid &&
        (selectedAssetUuid === gridMarketAsset?.uuid || selectedAssetUuid === rootAssetUuid),
    );

    const isCanaryNetwork = configType === 'CANARY_NETWORK';

    function handleGridMarketClick() {
      const uuid = gridMarketAsset?.uuid;

      if (!uuid) return;

      onGridMarketClick({
        lngLat: { lng: 0, lat: 0 }, // This location doesn't matter, it's passed to satisfy TypeScript
        target: {
          overedObject: {
            _asset: {
              uuid,
            },
          },
        },
      });
      //dispatch(setModalAssetManagerActiveView(EModalAssetsManagerView.GridMarketAndAgent));
      dispatch(setSelectedAssetUuid(rootAssetUuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const calculateCommunitySummaryModalPos = useCallback(
      throttle(() => {
        const communityRing = mapService?.threeboxController.getCommunityRingObject();

        if (communityRing && configurationCenter) {
          const summaryAdjustedPos = communityRing.calculateAdjustedPosition(
            [configurationCenter.lng, configurationCenter.lat],
            { x: -2.1, y: 0, z: 0 },
          );
          const gridAdjustedPos = communityRing.calculateAdjustedPosition(
            [configurationCenter.lng, configurationCenter.lat],
            { x: -2.15, y: -18, z: 0 },
          );

          setSummaryModalPos({
            lat: summaryAdjustedPos[1],
            lng: summaryAdjustedPos[0],
          });

          setGridModalPos({
            lat: gridAdjustedPos[1],
            lng: gridAdjustedPos[0],
          });
        }
      }, 100),
      [configurationCenter, mapService?.threeboxController, summaryModalPos],
    );

    useEffect(() => {
      delay(() => {
        const communityRing = mapService?.threeboxController.getCommunityRingObject();
        if (!communityRing) {
          setGridModalPos(undefined);
        }
      }, 100);
    }, [mapService?.threeboxController, selectedAssetUuid, rawSelectedAssetUuid]);

    useEffect(() => {
      mapService?.map.on('load', calculateCommunitySummaryModalPos);
      mapService?.map.on('move', calculateCommunitySummaryModalPos);
      return () => {
        mapService?.map.off('load', calculateCommunitySummaryModalPos);
        mapService?.map.off('move', calculateCommunitySummaryModalPos);
      };
    }, [calculateCommunitySummaryModalPos, mapService]);
    const childAssets: {
      uuid: string;
      lat: number;
      lng: number;
    }[] = [];

    assetsUnderSelectedUuid.map((asset) => {
      const parent = assetsTreeRelations[asset.uuid];
      const { geoTagLocation } = assetsValues[asset.uuid];
      let lngLat;
      if (geoTagLocation) {
        lngLat = convertLngLat(geoTagLocation);
      } else {
        lngLat = {
          lat: gridModalPos?.lat,
          lng: gridModalPos?.lng,
        };
      }
      if (parent && parent.length > 0) {
        parent.map((item) => {
          childAssets.push({
            uuid: item,
            lat: lngLat.lat,
            lng: lngLat.lng,
          });
        });
      }
    });

    return (
      <>
        {showCommunityOnTheMap && configurationCenter && communityAsset && (
          <Popup
            lat={summaryModalPos.lat}
            lng={summaryModalPos.lng}
            className={communityAsset.type === 'Area' ? s.rootMarker : s.marker}
            higherZIndex={showCommunityModal}>
            <div>
              {(activeModals.includes('modal-onboarding') && (step == 1 || step == 2)) ||
              isSCMFlow ? null : (
                <button
                  type="button"
                  className={s.createCommunityMarket}
                  onClick={() => dispatch(setSelectedAssetUuid(communityAsset.uuid))}>
                  <BaseIcon icon="community" size={14} />
                </button>
              )}
              {showCommunityModal && (
                <ModalCommunitySummary
                  hostAssetUuid={communityAsset.uuid}
                  onAddAssetUnderUuid={onAddAssetUnderUuid}
                  onAssetDuplicate={onAssetDuplicate}
                  onAssetRemove={onAssetRemove}
                  onAssetValuesSave={onAssetValuesSave}
                  onCommunityRemove={onCommunityRemove}
                  onSettingsDataSave={onSettingsDataSave}
                  onAddNewCustomHouse={onAddNewCustomHouse}
                  addSaveKey={addSaveKey}
                />
              )}
            </div>
          </Popup>
        )}

        {gridMarketAsset && gridModalPos && !isEmbed && !isSCMFlow && (
          <Popup lat={gridModalPos.lat} lng={gridModalPos.lng} higherZIndex={showMarketModal}>
            <BaseTooltipTrigger
              triggerClassName={''}
              tooltipClassName={s.helperTooltip}
              position="top"
              tooltipChildren={'Grid Market Settings'}
              wordWrap={true}
              offset={2}>
              <button type="button" className={s.gridMarketBullet} onClick={handleGridMarketClick}>
                <BaseIcon icon="grid-market" size={11} />
              </button>
            </BaseTooltipTrigger>

            {showMarketModal && (
              <ModalAssetsManager
                summaryIcon="market"
                hostAssetUuid={rootAssetUuid}
                view={EModalAssetsManagerView.GridMarketAndAgent}
                viewOnDestroy={EModalAssetsManagerView.Summary}
                onViewChange={(v) => dispatch(setModalAssetManagerActiveView(v))}
                disallowReturnToSummary={() => dispatch(setSelectedAssetUuid(undefined))}
                onAssetValuesSave={onAssetValuesSave}
                onAssetValuesSavePromise={onAssetValuesSavePromise}
                addSaveKey={addSaveKey}
              />
            )}
          </Popup>
        )}

        {!isSCMFlow &&
          childAssets.map((childAsset) => {
            const showThisAssetModal = selectedAssetUuid === childAsset.uuid;

            return (
              <Popup
                key={childAsset.uuid}
                lat={childAsset.lat}
                lng={childAsset.lng}
                className={s.marker}
                higherZIndex={showThisAssetModal}>
                {showThisAssetModal && (
                  <ModalAssetsManager
                    summaryIcon="market"
                    hostAssetUuid={childAsset.uuid}
                    view={EModalAssetsManagerView.Form}
                    onViewChange={(v) => dispatch(setModalHouseSummaryView(v))}
                    disallowReturnToSummary={() => dispatch(setSelectedAssetUuid(undefined))}
                    viewOnDestroy={initialApplicationState.modalHouseSummaryView}
                    formVariant={modalHouseSummaryFormVariant}
                    onFormVariantChange={(v) => dispatch(setModalHouseSummaryFormVariant(v))}
                    onAssetDuplicate={onAssetDuplicate}
                    onAssetRemove={onAssetRemove}
                    onAssetValuesSave={onAssetValuesSave}
                    onAssetValuesSavePromise={onAssetValuesSavePromise}
                    onAddNewCustomHouse={onAddNewCustomHouse}
                    addSaveKey={addSaveKey}
                  />
                )}
              </Popup>
            );
          })}
        {assetsUnderSelectedUuid.map((asset) => {
          const { geoTagLocation, name } = assetsValues[asset.uuid];

          if (!geoTagLocation) return null;
          const lngLat = convertLngLat(geoTagLocation);
          const showThisAssetModal = selectedAssetUuid === asset.uuid;
          const isSingleDevice = asset.type !== 'Area';
          const assetName = asset.uuid ? assetsValues[asset.uuid].name : undefined;
          const cnAssetOwner = isCanaryNetwork
            ? cnUsers.find(
                (item) =>
                  item.userRole !== EUserRoles.Aggregator &&
                  item.assetsConfigured.find(
                    (a) => a.uuid === asset.uuid && a.registrationStatus === 'Approved',
                  ),
              )
            : undefined;

          return (
            <Popup
              key={asset.uuid}
              lat={lngLat.lat}
              lng={lngLat.lng}
              className={s.marker}
              higherZIndex={showThisAssetModal}>
              {!showThisAssetModal && isCanaryNetwork && (
                <button
                  type="button"
                  className={s.houseOwnerPopup}
                  disabled={disableInteraction}
                  onClick={() => dispatch(setSelectedAssetUuid(asset.uuid))}>
                  {cnAssetOwner ? (
                    cnAssetOwner.profilePicture ? (
                      <div
                        className={classNames(s.avatar, s.hasAvatar)}
                        style={{ backgroundImage: `url(${cnAssetOwner?.profilePicture})` }}></div>
                    ) : (
                      <div
                        className={s.avatar}
                        style={{
                          backgroundColor: '#a0a0ac',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        {cnAssetOwner?.username?.charAt(0).toUpperCase()}
                      </div>
                    )
                  ) : (
                    <div className={s.avatar}></div>
                  )}

                  <span>{name}</span>
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0 0L4.37253 6.12154C5.17017 7.23824 6.82983 7.23824 7.62747 6.12155L12 0L0 0Z"
                      fill="#211E21"
                      fillOpacity="0.7"
                    />
                  </svg>
                </button>
              )}

              {showThisAssetModal && !isSCMFlow && (
                <ModalAssetsManager
                  summaryIcon="house"
                  hostAssetUuid={asset.uuid}
                  view={
                    !isSingleDevice && isSCMFlow
                      ? modalHouseSummaryView
                      : EModalAssetsManagerView.Summary
                  }
                  onViewChange={(v) => dispatch(setModalHouseSummaryView(v))}
                  viewOnDestroy={initialApplicationState.modalHouseSummaryView}
                  formVariant={modalHouseSummaryFormVariant}
                  onFormVariantChange={(v) => dispatch(setModalHouseSummaryFormVariant(v))}
                  disallowReturnToSummary={
                    isSingleDevice ? () => dispatch(setSelectedAssetUuid(undefined)) : undefined
                  }
                  onAddAssetUnderUuid={!isSingleDevice ? onAddAssetUnderUuid : undefined}
                  onAssetDuplicate={onAssetDuplicate}
                  onAssetRemove={onAssetRemove}
                  onAssetValuesSave={onAssetValuesSave}
                  onAssetValuesSavePromise={onAssetValuesSavePromise}
                  onAddNewCustomHouse={onAddNewCustomHouse}
                  addSaveKey={addSaveKey}
                />
              )}

              {isSCMFlow && showThisAssetModal && isUserACommunityMember && (
                <div className={classNames(g.modal, g.modalTooltip)}>
                  <div className={classNames(g.modalInner, 'gradient-dark')}>
                    <div className={classNames(g.paddingAround, g.scmUserTitle)}>
                      <div className={g.summaryUserCircle} />
                      <h3 className={g.title}>{`${assetName}'s Home`}</h3>
                    </div>
                  </div>
                </div>
              )}
            </Popup>
          );
        })}
      </>
    );
  },
);
