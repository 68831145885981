import {
  ChartDeviceProfile,
  TChartDeviceProfileDeviceTypeProperty,
} from 'src/components/_charts/ChartDeviceProfile';
import { ChartSelf, EChartSelfType } from 'src/components/_charts/ChartSelf';
import React, { useCallback, useMemo } from 'react';
import {
  selectAssetValuesForUuid,
  selectAssetsUnderUuid,
  selectAssetsValues,
  selectCommunityAsset,
  selectResultsEndTime,
  selectResultsStartTime,
  selectSettingsData,
} from 'src/redux/configuration/configuration.selectors';

import { AssetCount } from 'src/components/AssetCount';
import { ChartAsset } from 'src/components/ChartAsset';
import { ChartDayProfile } from 'src/components/_charts/ChartDayProfile';
import { ChartEnergyTradeProfile } from 'src/components/_charts/ChartEnergyTradeProfile';
import { ChartPrices } from 'src/components/_charts/ChartPrices';
import { ChartSavings } from 'src/components/ChartSavings';
import { KeyResults } from 'src/components/KeyResults';
import { KeyResultsSCM } from 'src/components/KeyResultsSCM';
import { TSimulationResultsPresentationProps } from './SimulationResultsPresentation.types';
import { TableEnergyBillsAndNetTraded } from 'src/components/_charts/TableEnergyBillsAndNetTraded/TableEnergyBillsAndNetTraded';
import { TradesBidsAndOffers } from 'src/components/TradesBidsAndOffers';
import { UTCMoment } from 'src/utils/UTCMoment';
import { WeeklyResultsIndicator } from 'src/components/MapSidebar/components/MapSidebarResults/components/WeeklyResultsIndicator';
import classnames from 'classnames';
import s from './SimulationResultsPresentation.module.scss';
import { selectSidebarExpanded } from 'src/redux/application/application.selectors';
import useAllTimestamps from 'src/hooks/useAllTimestamps';
import { useParsedEnergyTradeProfile } from 'src/components/_charts/ChartEnergyTradeProfile/useParsedEnergyTradeProfile';
import { useParsedPriceEnergyDay } from 'src/components/_charts/ChartPrices/useParsedPriceEnergyDay';
import { useSelector } from 'react-redux';

export const SimulationResultsPresentation: React.FC<TSimulationResultsPresentationProps> = ({
  settingsData,
  simulationResults,
  jobId,
  showPresentationAreas,
  assetType,
}) => {
  const {
    assetsInfo,
    assetUuid,
    cumulativeGridTrades,
    currentMarket,
    tradeProfileAreaThroughput,
    kpi,
    marketSummary,
    bills,
    priceEnergyDay,
    deviceStatistics,
    latestSavingsKpi,
    savingsKpiProfile,
  } = simulationResults || {};

  const assetsValues = useSelector(selectAssetsValues);
  const sidebarExpanded = useSelector(selectSidebarExpanded);
  const assetValues = useSelector(selectAssetValuesForUuid(assetUuid || ''));
  const childrenAssets = useSelector(selectAssetsUnderUuid(assetUuid || ''));
  const communityAsset = useSelector(selectCommunityAsset);
  const resultStartTime = useSelector(selectResultsStartTime);
  const resultsEndTime = useSelector(selectResultsEndTime);
  const assetName = assetsValues[assetUuid || '']?.name || '';
  const isCommunity = communityAsset?.uuid === assetUuid || assetType === 'InfiniteBus';
  const settings = useSelector(selectSettingsData);

  const parsedEnergyTradeProfile = useParsedEnergyTradeProfile({
    data: tradeProfileAreaThroughput,
    assetName,
    assetUuid,
  });

  const parsedPriceEnergyDay = useParsedPriceEnergyDay({
    data: priceEnergyDay,
  });

  const startUnix = useMemo(() => {
    return UTCMoment.fromBackend(resultStartTime).startOf('day').unix();
  }, [resultStartTime]);

  const endUnix = useMemo(() => {
    return UTCMoment.fromBackend(resultsEndTime).subtract(1, 'days').endOf('day').unix();
  }, [resultsEndTime]);

  const allTimestamps = useAllTimestamps({
    startTimestamp: startUnix * 1000,
    endTimestamp: endUnix && endUnix * 1000,
    interval: settings.slotLengthMinutes || 15,
  });

  const getKey: (item: string, index: number) => string = useCallback(
    (item: string, index: number) => `${item}-${index}-${assetUuid}`,
    [assetUuid],
  );

  const KpiResultsCombined = useMemo(() => {
    const output = latestSavingsKpi ? { ...latestSavingsKpi } : undefined;

    function currencyFormatter(value) {
      return Math.round(value + Number.EPSILON) / 100;
    }

    function savingFormatter(value) {
      return Math.round(Math.trunc(value) + Number.EPSILON) / 100;
    }

    if (kpi && latestSavingsKpi && output) {
      const keys = Object.keys(latestSavingsKpi || {});
      // d3a_cost has been replaced by gsy_e_cost and `gsy_e_cost` key does not exist in latestSavingsKpi PH-933
      keys.push('gsy_e_cost');
      keys.forEach((key) => {
        output[key] = kpi[key]
          ? key === 'saving_absolute'
            ? savingFormatter(kpi[key])
            : currencyFormatter(Number(kpi[key]))
          : 0;
      });
    }
    return output;
  }, [latestSavingsKpi, kpi]);

  /*const showNoResultsWrapper = useMemo(() => {
    if (
      simulationStatus === 'failed' ||
      typeof simulationStatus === 'undefined' ||
      typeof kpi === 'undefined'
      //typeof savingsKpiProfile === 'undefined'
    ) {
      return true;
    }
    return false;
  }, [simulationStatus, kpi]);*/

  const getFirstXandLastXFromData = (data) => {
    const keys = Object.keys(data);
    const firstandlast = keys.map((key) => {
      const firstX = data[key][0].x;
      const lastX = data[key][data[key].length - 1].x;
      return `${firstX}-${lastX}`;
    });
    return firstandlast.join('');
  };

  return (
    <>
      <WeeklyResultsIndicator
        settingsStartTime={settingsData.startDate}
        settingsEndTime={settingsData.endDate}
      />
      {/*showNoResultsWrapper && (
        <NoResultsSCM
          noMatching={simulationStatus === 'running' || simulationStatus === 'initializing'}
          switchToGridMarketView={switchToGridMarketView}
          switchToMembersView={switchToMembersView}
        />
      )*/}
      {showPresentationAreas.map((item, index) => {
        switch (item) {
          case 'ChartAsset':
            return (
              <ChartAsset
                key={getKey(item, index)}
                deviceType={assetType}
                currency={settingsData.currency}
                bills={bills}
                stateOfCharge={deviceStatistics?.['soc_history_%']}
                capacityKw={assetsValues[assetUuid || ''].batteryCapacityKwh || 0}
              />
            );
          case 'ChartAssetAgent':
            return (
              <ChartAsset
                key={getKey(item, index)}
                deviceType={'InfiniteBus'}
                currency={settingsData.currency}
                bills={bills}
                stateOfCharge={deviceStatistics?.['soc_history_%']}
                capacityKw={assetsValues[assetUuid || ''].batteryCapacityKwh || 0}
              />
            );
          case 'KeyResultsSCM':
            return (
              <KeyResultsSCM
                mode={isCommunity ? 'Community' : 'Home'}
                key={getKey(item, index)}
                currency={settingsData.currency}
                kpi={kpi}
              />
            );
          case 'KeyResults':
            return (
              <KeyResults
                key={getKey(item, index)}
                latestSavingsKpi={latestSavingsKpi}
                savingsKpiProfile={savingsKpiProfile}
                currency={settingsData.currency}
                kpi={kpi}
              />
            );
          case 'AssetCount':
            return <AssetCount key={getKey(item, index)} assetsInfo={assetsInfo} />;
          case 'ChartSavings':
            return (
              <ChartSavings
                key={getKey(item, index)}
                latestSavingsKpi={KpiResultsCombined}
                savingsKpiProfile={savingsKpiProfile}
                assetValues={assetValues}
                childrenCount={childrenAssets.length}
                currency={settingsData.currency}
              />
            );
          case 'TableEnergyBillsAndNetTraded':
            return (
              <TableEnergyBillsAndNetTraded
                key={getKey(item, index)}
                bills={bills}
                isCommunity={isCommunity}
              />
            );
          case 'ChartDeviceProfile':
            return (
              <ChartDeviceProfile
                key={getKey(item, index)}
                settingsData={settingsData}
                startUnix={startUnix}
                endUnix={endUnix}
                deviceStatics={simulationResults?.deviceStatistics}
                deviceType={assetType as TChartDeviceProfileDeviceTypeProperty}
                forTimestamps={allTimestamps}
              />
            );
          case 'SS-SC':
            return (
              <div
                key={getKey(item, index)}
                className={classnames(s.selfChartsWrapper, {
                  [s.rowDisplay]: sidebarExpanded,
                })}>
                <ChartSelf kpi={kpi} type={EChartSelfType.Sufficiency} />
                <ChartSelf kpi={kpi} type={EChartSelfType.Consumption} />
              </div>
            );
          case 'ChartEnergyTradeProfile':
            return (
              <ChartEnergyTradeProfile
                {...parsedEnergyTradeProfile}
                key={`${getKey(item, index)}${getFirstXandLastXFromData(
                  parsedEnergyTradeProfile.boughtEnergy,
                )}`}
                assetUuid={assetUuid}
                startUnix={startUnix}
                endUnix={endUnix}
                hasData={Boolean(tradeProfileAreaThroughput)}
              />
            );
          case 'ChartDayProfile':
            return (
              <ChartDayProfile
                key={getKey(item, index)}
                marketSummary={marketSummary}
                netEnergyData={parsedEnergyTradeProfile.netEnergyData}
                gridFeeData={parsedPriceEnergyDay?.gridFee}
                startUnix={startUnix}
                endUnix={endUnix}
              />
            );
          case 'ChartPrices':
            return (
              <ChartPrices
                key={getKey(item, index)}
                parsedPriceEnergyDay={parsedPriceEnergyDay}
                startUnix={startUnix}
                endUnix={endUnix}
              />
            );
          case 'TradesBidsAndOffers':
            return (
              <TradesBidsAndOffers
                key={getKey(item, index)}
                cumulativeGridTrades={cumulativeGridTrades}
                assetUuid={assetUuid}
                jobId={jobId}
                currentMarket={currentMarket}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};
